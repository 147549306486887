import axiosClient from '../utils/axios'
import { authHeaders } from '../utils/localStorage'

export const GET_EMPLOYEES_REQUEST = 'GET_EMPLOYEES_REQUEST'
export function getEmployeesRequest() {
  return {
    type: GET_EMPLOYEES_REQUEST,
  }
}

export const GET_EMPLOYEES_SUCCESS = 'GET_EMPLOYEES_SUCCESS'
export function getEmployeesSuccess(employees) {
  return {
    type: GET_EMPLOYEES_SUCCESS,
    payload: employees,
  }
}

export const GET_EMPLOYEES_FAILURE = 'GET_EMPLOYEES_FAILURE'
export function getEmployeesFailure(error) {
  return {
    type: GET_EMPLOYEES_FAILURE,
    payload: error,
  }
}

export function getEmployees() {
  return (dispatch) => {
    dispatch(getEmployeesRequest())
    return axiosClient
      .get(`${process.env.REACT_APP_API_URL}/v1/employees`, { headers: authHeaders() })
      .then((response) => {
        dispatch(getEmployeesSuccess(response.data))
        return Promise.resolve(response.data)
      })
      .catch((error) => {
        dispatch(getEmployeesFailure(error))
        return Promise.reject(error)
      })
  }
}

export const GET_EMPLOYEE_REQUEST = 'GET_EMPLOYEE_REQUEST'
export function getEmployeeRequest() {
  return {
    type: GET_EMPLOYEE_REQUEST,
  }
}

export const GET_EMPLOYEE_SUCCESS = 'GET_EMPLOYEE_SUCCESS'
export function getEmployeeSuccess(employees) {
  return {
    type: GET_EMPLOYEE_SUCCESS,
    payload: employees,
  }
}

export const GET_EMPLOYEE_FAILURE = 'GET_EMPLOYEE_FAILURE'
export function getEmployeeFailure(error) {
  return {
    type: GET_EMPLOYEE_FAILURE,
    payload: error,
  }
}

export function getEmployee(id) {
  return (dispatch) => {
    dispatch(getEmployeeRequest())
    return axiosClient
      .get(`${process.env.REACT_APP_API_URL}/v1/employees/${id}/web_show`, { headers: authHeaders() })
      .then((response) => {
        dispatch(getEmployeeSuccess(response.data))
        return Promise.resolve(response.data)
      })
      .catch((error) => {
        dispatch(getEmployeeFailure(error))
        return Promise.reject(error)
      })
  }
}