import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import { bindActionCreators } from 'redux'
import moment from 'moment'

class InsurancePolicyCard extends Component {
  render() {
    const { insurancePolicy } = this.props

    return (
      <div className="insurance_policy_card__container" >
        <Row>
          <Col xs={12}>
            <div className="insurance_policy_card__main_container" >
              <p className="insurance_policy_card__info"><span className="insurance_policy_card__info_label">Subscriber Name:</span> { insurancePolicy.subscriber_name }</p>
              <p className="insurance_policy_card__info"><span className="insurance_policy_card__info_label">Subscriber Birthdate:</span> { moment(insurancePolicy.subscriber_date_of_birth).format("M/DD/YYYY") }</p>
              <p className="insurance_policy_card__info"><span className="insurance_policy_card__info_label">Provider Name:</span> { insurancePolicy.provider_name }</p>
              <p className="insurance_policy_card__info"><span className="insurance_policy_card__info_label">Policy No:</span> { insurancePolicy.id_number }</p>
              <p className="insurance_policy_card__info"><span className="insurance_policy_card__info_label">SSN:</span> { insurancePolicy.ssn }</p>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default connect(
  state => ({
  }),
  dispatch => bindActionCreators({
  }, dispatch),
)(InsurancePolicyCard)
