import axiosClient from '../utils/axios'
import { authHeaders } from '../utils/localStorage'

export const SET_CURRENT_CONVERSATION_PAGE = 'SET_CURRENT_CONVERSATION_PAGE'
export function setCurrentConversationPage(page) {
  return {
    type: SET_CURRENT_CONVERSATION_PAGE,
    payload: page,
  }
}

export const SET_CURRENT_CONVERSATION = 'SET_CURRENT_CONVERSATION'
export function setCurrentConversation(conversation) {
  return {
    type: SET_CURRENT_CONVERSATION,
    payload: conversation,
  }
}

export const CLEAR_CURRENT_CONVERSATION = 'CLEAR_CURRENT_CONVERSATION'
export function clearCurrentConversation() {
  return {
    type: CLEAR_CURRENT_CONVERSATION,
  }
}

export const CLEAR_CONVERSATIONS = 'CLEAR_CONVERSATIONS'
export function clearConversations() {
  return {
    type: CLEAR_CONVERSATIONS,
  }
}

export const GET_CONVERSATIONS_REQUEST = 'GET_CONVERSATIONS_REQUEST'
export function getConversationsRequest() {
  return {
    type: GET_CONVERSATIONS_REQUEST,
  }
}

export const GET_CONVERSATIONS_SUCCESS = 'GET_CONVERSATIONS_SUCCESS'
export function getConversationsSuccess(conversations) {
  return {
    type: GET_CONVERSATIONS_SUCCESS,
    payload: conversations,
  }
}

export const GET_CONVERSATIONS_FAILURE = 'GET_CONVERSATIONS_FAILURE'
export function getConversationsFailure(error) {
  return {
    type: GET_CONVERSATIONS_FAILURE,
    payload: error,
  }
}

export function getConversations(employeeId, pageNumber) {
  return (dispatch) => {
    dispatch(getConversationsRequest())
    return axiosClient
      .get(`${process.env.REACT_APP_API_URL}/v1/employees/${employeeId}/conversations/admin_web_index`, { params: { page: pageNumber }, headers: authHeaders() })
      .then((response) => {
        dispatch(getConversationsSuccess(response.data))
      })
      .catch((error) => {
        dispatch(getConversationsFailure(error))
      })
  }
}

export const GET_CONVERSATIONS_TOTAL_PAGES_REQUEST = 'GET_CONVERSATIONS_TOTAL_PAGES_REQUEST'
export function getConversationsTotalPagesRequest() {
  return {
    type: GET_CONVERSATIONS_TOTAL_PAGES_REQUEST,
  }
}

export const GET_CONVERSATIONS_TOTAL_PAGES_SUCCESS = 'GET_CONVERSATIONS_TOTAL_PAGES_SUCCESS'
export function getConversationsTotalPagesSuccess(conversations) {
  return {
    type: GET_CONVERSATIONS_TOTAL_PAGES_SUCCESS,
    payload: conversations,
  }
}

export const GET_CONVERSATIONS_TOTAL_PAGES_FAILURE = 'GET_CONVERSATIONS_TOTAL_PAGES_FAILURE'
export function getConversationsTotalPagesFailure(error) {
  return {
    type: GET_CONVERSATIONS_TOTAL_PAGES_FAILURE,
    payload: error,
  }
}

export const CLEAR_CONVERSATIONS_TOTAL_PAGES = 'CLEAR_CONVERSATIONS_TOTAL_PAGES'
export function clearConversationsTotalPages() {
  return {
    type: CLEAR_CONVERSATIONS_TOTAL_PAGES,
  }
}

export function getConversationsTotalPages(employeeId, pageNumber) {
  return (dispatch) => {
    dispatch(getConversationsTotalPagesRequest())
    return axiosClient
      .get(`${process.env.REACT_APP_API_URL}/v1/employees/${employeeId}/conversations/admin_web_total_pages`, { params: { page: pageNumber }, headers: authHeaders() })
      .then((response) => {
        dispatch(getConversationsTotalPagesSuccess(response.data))
      })
      .catch((error) => {
        dispatch(getConversationsTotalPagesFailure(error))
      })
  }
}

export const GET_CONVERSATIONS_FOR_USER_NAME_REQUEST = 'GET_CONVERSATIONS_FOR_USER_NAME_REQUEST'
export function getConversationsForUserNameRequest() {
  return {
    type: GET_CONVERSATIONS_FOR_USER_NAME_REQUEST,
  }
}

export const GET_CONVERSATIONS_FOR_USER_NAME_SUCCESS = 'GET_CONVERSATIONS_FOR_USER_NAME_SUCCESS'
export function getConversationsForUserNameSuccess(conversations) {
  return {
    type: GET_CONVERSATIONS_FOR_USER_NAME_SUCCESS,
    payload: conversations,
  }
}

export const GET_CONVERSATIONS_FOR_USER_NAME_FAILURE = 'GET_CONVERSATIONS_FOR_USER_NAME_FAILURE'
export function getConversationsForUserNameFailure(error) {
  return {
    type: GET_CONVERSATIONS_FOR_USER_NAME_FAILURE,
    payload: error,
  }
}

export function getConversationsForUserName(employeeId, lastName, pageNumber) {
  return (dispatch) => {
    dispatch(getConversationsForUserNameRequest())
    return axiosClient
      .get(`${process.env.REACT_APP_API_URL}/v1/employees/${employeeId}/conversations/search_index`, { params: { last_name: lastName, page: pageNumber }, headers: authHeaders() })
      .then((response) => {
        dispatch(getConversationsForUserNameSuccess(response.data))
      })
      .catch((error) => {
        dispatch(getConversationsForUserNameFailure(error))
      })
  }
}

export const GET_CONVERSATIONS_FOR_USER_NAME_TOTAL_PAGES_REQUEST = 'GET_CONVERSATIONS_FOR_USER_NAME_TOTAL_PAGES_REQUEST'
export function getConversationsForUserNameTotalPagesRequest() {
  return {
    type: GET_CONVERSATIONS_FOR_USER_NAME_TOTAL_PAGES_REQUEST,
  }
}

export const GET_CONVERSATIONS_FOR_USER_NAME_TOTAL_PAGES_SUCCESS = 'GET_CONVERSATIONS_FOR_USER_NAME_TOTAL_PAGES_SUCCESS'
export function getConversationsForUserNameTotalPagesSuccess(conversations) {
  return {
    type: GET_CONVERSATIONS_FOR_USER_NAME_TOTAL_PAGES_SUCCESS,
    payload: conversations,
  }
}

export const GET_CONVERSATIONS_FOR_USER_NAME_TOTAL_PAGES_FAILURE = 'GET_CONVERSATIONS_FOR_USER_NAME_TOTAL_PAGES_FAILURE'
export function getConversationsForUserNameTotalPagesFailure(error) {
  return {
    type: GET_CONVERSATIONS_FOR_USER_NAME_TOTAL_PAGES_FAILURE,
    payload: error,
  }
}

export function getConversationsForUserNameTotalPages(employeeId, lastName, pageNumber) {
  return (dispatch) => {
    dispatch(getConversationsForUserNameTotalPagesRequest())
    return axiosClient
      .get(`${process.env.REACT_APP_API_URL}/v1/employees/${employeeId}/conversations/search_index/total_pages`, { params: { last_name: lastName, page: pageNumber }, headers: authHeaders() })
      .then((response) => {
        dispatch(getConversationsForUserNameTotalPagesSuccess(response.data))
      })
      .catch((error) => {
        dispatch(getConversationsForUserNameTotalPagesFailure(error))
      })
  }
}

export const GET_CONVERSATIONS_FOR_USER_REQUEST = 'GET_CONVERSATIONS_FOR_USER_REQUEST'
export function getConversationsForUserRequest() {
  return {
    type: GET_CONVERSATIONS_FOR_USER_REQUEST,
  }
}

export const GET_CONVERSATIONS_FOR_USER_SUCCESS = 'GET_CONVERSATIONS_FOR_USER_SUCCESS'
export function getConversationsForUserSuccess(conversations) {
  return {
    type: GET_CONVERSATIONS_FOR_USER_SUCCESS,
    payload: conversations,
  }
}

export const GET_CONVERSATIONS_FOR_USER_FAILURE = 'GET_CONVERSATIONS_FOR_USER_FAILURE'
export function getConversationsForUserFailure(error) {
  return {
    type: GET_CONVERSATIONS_FOR_USER_FAILURE,
    payload: error,
  }
}

export function getConversationsForUser(employeeId, userId, pageNumber) {
  return (dispatch) => {
    dispatch(getConversationsForUserRequest())
    return axiosClient
      .get(`${process.env.REACT_APP_API_URL}/v1/employees/${employeeId}/users/${userId}/conversations`, { params: { page: pageNumber }, headers: authHeaders() })
      .then((response) => {
        dispatch(getConversationsForUserSuccess(response.data))
      })
      .catch((error) => {
        dispatch(getConversationsForUserFailure(error))
      })
  }
}

export const GET_CONVERSATIONS_FOR_USER_TOTAL_PAGES_REQUEST = 'GET_CONVERSATIONS_FOR_USER_TOTAL_PAGES_REQUEST'
export function getConversationsForUserTotalPagesRequest() {
  return {
    type: GET_CONVERSATIONS_FOR_USER_TOTAL_PAGES_REQUEST,
  }
}

export const GET_CONVERSATIONS_FOR_USER_TOTAL_PAGES_SUCCESS = 'GET_CONVERSATIONS_FOR_USER_TOTAL_PAGES_SUCCESS'
export function getConversationsForUserTotalPagesSuccess(conversations) {
  return {
    type: GET_CONVERSATIONS_FOR_USER_TOTAL_PAGES_SUCCESS,
    payload: conversations,
  }
}

export const GET_CONVERSATIONS_FOR_USER_TOTAL_PAGES_FAILURE = 'GET_CONVERSATIONS_FOR_USER_TOTAL_PAGES_FAILURE'
export function getConversationsForUserTotalPagesFailure(error) {
  return {
    type: GET_CONVERSATIONS_FOR_USER_TOTAL_PAGES_FAILURE,
    payload: error,
  }
}

export function getConversationsForUserTotalPages(employeeId, userId, pageNumber) {
  return (dispatch) => {
    dispatch(getConversationsForUserTotalPagesRequest())
    return axiosClient
      .get(`${process.env.REACT_APP_API_URL}/v1/employees/${employeeId}/users/${userId}/conversations/total_pages`, { params: { page: pageNumber }, headers: authHeaders() })
      .then((response) => {
        dispatch(getConversationsForUserTotalPagesSuccess(response.data))
      })
      .catch((error) => {
        dispatch(getConversationsForUserTotalPagesFailure(error))
      })
  }
}

export const CREATE_CONVERSATION_REQUEST = 'CREATE_CONVERSATION_REQUEST'
export function createConversationRequest() {
  return {
    type: CREATE_CONVERSATION_REQUEST,
  }
}

export const CREATE_CONVERSATION_SUCCESS = 'CREATE_CONVERSATION_SUCCESS'
export function createConversationSuccess(appointment) {
  return {
    type: CREATE_CONVERSATION_SUCCESS,
    payload: appointment,
  }
}

export const CREATE_CONVERSATION_FAILURE = 'CREATE_CONVERSATION_FAILURE'
export function createConversationFailure(error) {
  return {
    type: CREATE_CONVERSATION_FAILURE,
    payload: error,
  }
}

export function createConversation(employeeId, formattedConversation) {
  return (dispatch) => {
    dispatch(createConversationRequest())
    return axiosClient
      .post(`${process.env.REACT_APP_API_URL}/v1/employees/${employeeId}/conversations`, { conversation: formattedConversation }, { headers: authHeaders() })
      .then((response) => {
        dispatch(createConversationSuccess(response.data))
        return Promise.resolve(response.data)
      })
      .catch((error) => {
        dispatch(createConversationFailure(error))
        return Promise.reject(error)
      })
  }
}