import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Container } from 'react-bootstrap'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Cell, Tooltip, Legend, LabelList } from 'recharts'
import _filter from 'lodash/filter'
import _map from 'lodash/map'

import { getEmployeeProductionStats } from '../actions/stats'

import loadingAnimation from '../images/loading-animation.gif'

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}


const CustomizedLabel = () => ({
  render () {
    const {x, y, fill, value} = this.props;
    if (x > 40) {
    	return <text 
	           x={x} 
	           y={y} 
	           fontSize='16' 
	           fontFamily='sans-serif'
	           fill={fill}
	           textAnchor="end">{value}%
           </text>
    }
   	
  }
})


class PracticeProductionHorizontalBarChart extends Component {
  constructor(props) {
  	super(props)
  }
  render() {
    const { employees, practiceProductionStats } = this.props

	const labelFormatter = (value) => {
		if (value !== 0) {
	    	return '$' + numberWithCommas(value)
		} else {
			return ''
		}
	}

    return (
      <Container fluid>
        <Row>
          <Col xs={{ span: 12, offset: 0 }}  className="horizontal_bar_chart_production__main_container">
          	<ResponsiveContainer>
	  	        <BarChart 
	  	            data={practiceProductionStats}
	  	            layout="vertical" barCategoryGap={12}
	  	            margin={{ top: 0, right: 121, left: 0, bottom: 0 }}>
	  	       <XAxis type="number" />
	  	       <YAxis type="category" width={120} padding={{ left: 10 }} margin={{ top: 0, right: 0, left: 0, bottom: 0 }} dataKey="name"/>
	  	        
	  	        <Bar dataKey="production" stackId="a" fill="#DB9E12" >
	  	        	<LabelList dataKey="production" position="inside" formatter={labelFormatter} />
	  	        </Bar>
		        <Tooltip cursor={{fill: 'transparent'}} formatter={(label) => "$" + numberWithCommas(label)}/>
		        <Legend layout="horizontal" verticalAlign="top" align="center" />
	  	      </BarChart>
  	     	</ResponsiveContainer>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default withRouter(connect(
  state => ({
    currentEmployee: state.auth.currentEmployee,
    employees: state.employees.employees,
  }),
  dispatch => bindActionCreators({
  	getEmployeeProductionStats,
  }, dispatch),
)(PracticeProductionHorizontalBarChart))
