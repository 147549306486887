import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import { bindActionCreators } from 'redux'
import { Formik, Field, Form } from 'formik'
import _map from 'lodash/map'
import _find from 'lodash/find'

import { getNoInsuranceUsers } from '../actions/users'
import { getEmployees } from '../actions/employees'
import { getPractices } from '../actions/practices'
import { setCurrentRepairIssuePage, getRepairIssues, clearStatusFilter, setStatusFilter } from '../actions/repairIssues'

import RepairIssueCard from './RepairIssueCard'

import refreshIcon from '../images/refresh-icon.png'
import leftArrow from '../images/left-arrow.png'
import rightArrow from '../images/right-arrow.png'
import loadingAnimation from '../images/loading-animation.gif'


const statusOptions = [
  "all",
  "open",
  "in-progress",
  "completed"
]

class RepairIssuesList extends Component {
  constructor(props) {
    super(props)

    this.updateStatusFilter = this.updateStatusFilter.bind(this)
  }
  componentDidMount() {
    const { currentEmployee, setCurrentRepairIssuePage, currentPage, getRepairIssues, getPractices, clearStatusFilter, getEmployees } = this.props

    clearStatusFilter()
    setCurrentRepairIssuePage(1)

    if (currentEmployee) {
      getEmployees().then(() => {
        getPractices().then(() => {
          getRepairIssues(currentEmployee.id, currentPage)
        })
      })
    }
  }
  updateStatusFilter(values) {
    const { clearStatusFilter, currentPage, setStatusFilter, currentEmployee, getPractices, getRepairIssues } = this.props 

    clearStatusFilter()
    setStatusFilter(values.status)
    console.log(values)
    if (currentEmployee) {
      getPractices().then(() => {
        getRepairIssues(currentEmployee.id, currentPage, values.status)
      })
    }
  }
  loadPage(pageNumber, e) {
    const { currentEmployee, setCurrentRepairIssuePage, currentPage, getRepairIssues, getPractices, clearStatusFilter } = this.props
    e.preventDefault()

    clearStatusFilter()
    if (currentEmployee) {
      getPractices().then(() => {
        setCurrentRepairIssuePage(pageNumber)
        getRepairIssues(currentEmployee.id, pageNumber)
      })
    }
  }
  render() {
    const { loading, currentEmployees, statusFilter, repairIssues, currentPage, totalPages, practices } = this.props

    return (
      <div>
        <Row className="repair_issues_list__header">
          <Col xs={{ span: 12, offset: 0 }} sm={{ span: 12, offset: 0 }} md={{ span: 6, offset: 3 }} lg={{ span: 7, offset: 2 }} className="repair_issues_list__title_container">
            <h2 className="repair_issues_list__title">Repair Issues</h2>
          </Col>
          <Col xs={{ span: 12, offset: 0 }} sm={{ span: 12, offset: 0 }} md={{ span: 3, offset: 0 }} lg={{ span: 3, offset: 0 }} className="repair_issues_list__filter_container">
            <h2 className="repair_issues_list__filter_title">Status</h2>
            <Formik
              initialValues={{ status: "All" }}
              onSubmit={this.updateStatusFilter}
            >
              {({ handleSubmit, setFieldValue, values, isSubmitting, isValid, dirty }) => (
                   <Form className="repair_issues_list__status_form">
                    <Field className="repair_issues_list__status_form_select" as="select" name="status">
                    {
                      _map(statusOptions, (option, index) => {
                        return (
                          <option key={index} value={option}>{option.replace('-', ' ').replace(/(?:^|\s)\S/g, a => a.toUpperCase())}</option>
                        )
                      })
                    }
                    </Field>
                    <button className="repair_issue_card__form_practice_update_button" type="submit">
                      <img className="repair_issue_card__form_practice_update_button_icon" src={refreshIcon} />
                    </button>
                  </Form>
                )}
             </Formik>
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 12, offset: 0 }} sm={{ span: 12, offset: 0 }} md={{ span: 9, offset: 3 }} lg={{ span: 10, offset: 2 }} className="repair_issues_list__main_container">
            {
              loading ?
              <img className="repair_issues_list__loading_animation" src={loadingAnimation} />
              :
              ''
            }
            {
              _map(repairIssues, (repairIssue, index) => {
              	let matchingPractice = _find(practices, (practice) => { return repairIssue.practice_id === practice.id })
                var employee = _find(currentEmployees, (employee) => { return repairIssue.employee_id === employee.id })
                return (
                  <RepairIssueCard employee={employee ? employee : null} repairIssue={repairIssue} practice={matchingPractice} key={index} />
                )
              })
            }
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 12, offset: 0 }} sm={{ span: 12, offset: 0 }} md={{ span: 9, offset: 3 }} lg={{ span: 10, offset: 2 }} >
            <div className="repair_issues_list__pagination_container">
              <div  className="repair_issues_list__pagination_left_arrow_container">
                { (currentPage === 1) && (totalPages !== 1) ?
                  <img className="repair_issues_list__pagination_left_arrow" src={leftArrow} /> 
                 : ''
                }
              </div>
              <div className="repair_issues_list__pagination__text_container">
                <p className="repair_issues_list__pagination_container_text">
                  Page&nbsp;
                  <a href="" onClick={(e) => this.loadPage(currentPage, e)}>
                    { currentPage }
                  </a>
                    &nbsp;of&nbsp;
                  <a href="" onClick={(e) => this.loadPage(currentPage, e)}>
                    { totalPages }
                  </a>
                </p>
              </div>
              <div className="repair_issues_list__pagination_right_arrow_container">
                { (currentPage < totalPages) ?
                  <img className="repair_issues_list__pagination_right_arrow" src={rightArrow} /> 
                 : '' }
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default connect(
  state => ({
    loading: state.repairIssues.loading,
    currentEmployee: state.auth.currentEmployee,
    currentEmployees: state.employees.employees,
    currentPage: state.repairIssues.currentPage,
    totalPages: state.repairIssues.totalPages,
    repairIssues: state.repairIssues.repairIssues,
    statusFilter: state.repairIssues.statusFilter,
    practices: state.practices.practices,
  }),
  dispatch => bindActionCreators({
    getPractices,
    getEmployees,
    getRepairIssues,
    setCurrentRepairIssuePage,
    clearStatusFilter,
    setStatusFilter,
  }, dispatch),
)(RepairIssuesList)
