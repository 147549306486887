import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Container } from 'react-bootstrap'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import { setSearchText } from '../actions/views'
import { clearCurrentEmployee, logoutEmployee } from '../actions/auth'
import { setUserSearchText, getSearchUsers, clearSearchUsers } from '../actions/users'

import { getConversations, getConversationsTotalPages, getConversationsForUserName, getConversationsForUserNameTotalPages, clearConversations } from '../actions/conversations'
import { setCurrentMessagesPage } from '../actions/messages'

import NavMenu from './NavMenu'
import Inbox from './Inbox'
import PatientProfile from './PatientProfile'
import Chat from './Chat'
import NoSsnUsersList from './NoSsnUsersList'
import NoInsuranceUsersList from './NoInsuranceUsersList'
import EmployeeSearchUserCard from './EmployeeSearchUserCard'
import SmileCycleCheckout from './SmileCycleCheckout'
import Production from './Production'
import ScheduleGenerator from './ScheduleGenerator'
import RepairIssuesList from './RepairIssuesList'

const INBOX_TAB = "inbox"
const PATIENT_PROFILE_TAB = "patientProfile"
const CONVERSATION = "conversation"
const NO_SSN_USERS_LIST = "noSsnUsersList"
const NO_INSURANCE_USERS_LIST = "noInsuranceUsersList"
const SMILE_CYCLE_CHECKOUT = "smileCycleCheckout"
const PRODUCTION = "production"
const SCHEDULE_GENERATOR = "scheduleGenerator"
const REPAIR_ISSUES_LIST = "repairIssuesList"

class Home extends Component {
  constructor(props) {
    super(props)

    this.updateSearch = this.updateSearch.bind(this)
  }
  updateSearch(e) {
    const { 
      currentView, 
      currentPage, 
      setSearchText, 
      setUserSearchText, 
      getSearchUsers, 
      clearSearchUsers, 
      currentEmployee, 
      getConversationsForUserName, 
      getConversationsForUserNameTotalPages, 
      getConversations,
      getConversationsTotalPages,
      clearConversations,
      clearCurrentEmployee,
      logoutEmployee,
    } = this.props

    const searchText = e.target.value
    setSearchText(searchText)
    setUserSearchText(searchText)

    if (currentEmployee) {

      if (currentView === INBOX_TAB) {
        if (e.target.value !== '') {
          getConversationsForUserName(currentEmployee.id, searchText)
        } else {
          // clearConversations()
          // setCurrentMessagesPage(1)
          // getConversations(currentEmployee.id, currentPage)
          // getConversationsTotalPages(currentEmployee.id, currentPage)
        }
      }


      if (currentView === PATIENT_PROFILE_TAB) {
        if (e.target.value !== '') {
          getSearchUsers(currentEmployee.id, searchText)
        } else {
          clearSearchUsers()
        }
      }

    } else {
      logoutEmployee()
      clearCurrentEmployee()
      this.props.history.push("/login")
    }
  }
  render() {
    const { currentView, searchUsers, currentSearchText } = this.props

    var currentTab = null
    if (currentView === INBOX_TAB) {
      currentTab = (
        <Inbox key="abc123" />
      )
    }

    if (currentView === PATIENT_PROFILE_TAB) {
      currentTab = (
        <PatientProfile key="abc234" />
      )
    }   

    if (currentView === CONVERSATION) {
      currentTab = (
        <Chat key="abc345" />
      )
    }    

    if (currentView === NO_SSN_USERS_LIST) {
      currentTab = (
        <NoSsnUsersList key="abc456" />
      )
    }

    if (currentView === NO_INSURANCE_USERS_LIST) {
      currentTab = (
        <NoInsuranceUsersList key="abc567" />
      )
    }

    if (currentView === SMILE_CYCLE_CHECKOUT) {
      currentTab = (
        <SmileCycleCheckout key="abc678" />
      )
    }

    if (currentView === PRODUCTION) {
      currentTab = (
        <Production key="abc789" />
      )
    }

    if (currentView === SCHEDULE_GENERATOR) {
      currentTab = (
        <ScheduleGenerator key="abc101112" />
      )
    }    

    if (currentView === REPAIR_ISSUES_LIST) {
      currentTab = (
        <RepairIssuesList key="abc111213" />
      )
    }

    var searchUserCards = null
    if (searchUsers.length > 0) {
      searchUserCards = searchUsers.map((user, index) => {
          return (
            <EmployeeSearchUserCard key={index} user={user} />
          )
      })
    }

    var placholderText = "Search..."
    if (currentView === INBOX_TAB) {
      placholderText = "Search messages..."
    }

    if (currentView === PATIENT_PROFILE_TAB) {
      placholderText = "Search patients..."
    }

    return (
      <Container fluid className="home__main_container">
        <NavMenu />
        <Row>
          {
            (currentView !== "production") && (currentView !== "scheduleGenerator")  && (currentView !== "repairIssuesList")  ? 
              (
                <Col xs={{ span: 12, offset: 0 }} md={{ span: 9, offset: 3 }} lg={{ span: 10, offset: 2 }}>
                      <input className="home__search_bar" type="text" placeholder={placholderText} onChange={this.updateSearch} value={currentSearchText}/>
                      {
                        (searchUsers.length > 0) ?
                          <div className="home__search_container">
                            { searchUserCards }
                          </div>
                        :''
                      }
                </Col>
              )
              :''
            }
        </Row>
        { currentTab }
      </Container>
    )
  }
}

export default withRouter(connect(
  state => ({
    currentEmployee: state.auth.currentEmployee,
    currentView: state.views.currentView,
    searchUsers: state.users.searchUsers,
    currentPage: state.conversations.currentPage,
    currentSearchText: state.views.currentSearchText,
  }),
  dispatch => bindActionCreators({
    setUserSearchText,
    setSearchText,
    getSearchUsers,
    clearSearchUsers,
    getConversations,
    getConversationsTotalPages,
    setCurrentMessagesPage,
    getConversationsForUserName,
    getConversationsForUserNameTotalPages,
    clearConversations,
    clearCurrentEmployee,
    logoutEmployee,
  }, dispatch),
)(Home))
