import axiosClient from '../utils/axios'
import { authHeaders } from '../utils/localStorage'

export const SET_CURRENT_MEDICAL_SURVEY = 'SET_CURRENT_MEDICAL_SURVEY'
export function setCurrentMedicalSurvey(medicalSurvey) {
  return {
    type: SET_CURRENT_MEDICAL_SURVEY,
    payload: medicalSurvey,
  }
}

export const CLEAR_CURRENT_MEDICAL_SURVEY = 'CLEAR_CURRENT_MEDICAL_SURVEY'
export function clearCurrentMedicalSurvey() {
  return {
    type: CLEAR_CURRENT_MEDICAL_SURVEY,
  }
}

export const CLEAR_MEDICAL_SURVEYS = 'CLEAR_MEDICAL_SURVEYS'
export function clearMedicalSurveys() {
  return {
    type: CLEAR_MEDICAL_SURVEYS,
  }
}

export const GET_MEDICAL_SURVEYS_REQUEST = 'GET_MEDICAL_SURVEYS_REQUEST'
export function getMedicalSurveysRequest() {
  return {
    type: GET_MEDICAL_SURVEYS_REQUEST,
  }
}

export const GET_MEDICAL_SURVEYS_SUCCESS = 'GET_MEDICAL_SURVEYS_SUCCESS'
export function getMedicalSurveysSuccess(insurancePolicies) {
  return {
    type: GET_MEDICAL_SURVEYS_SUCCESS,
    payload: insurancePolicies,
  }
}

export const GET_MEDICAL_SURVEYS_FAILURE = 'GET_MEDICAL_SURVEYS_FAILURE'
export function getMedicalSurveysFailure(error) {
  return {
    type: GET_MEDICAL_SURVEYS_FAILURE,
    payload: error,
  }
}

export function getMedicalSurveys(medicalSurveyCollectionId, employeeId, userId) {
  return (dispatch) => {
    dispatch(getMedicalSurveysRequest())
    return axiosClient
      .get(`${process.env.REACT_APP_API_URL}/v1/employees/${employeeId}/users/${userId}/medical_survey_collections/${medicalSurveyCollectionId}/medical_surveys`, { headers: authHeaders() })
      .then((response) => {
        dispatch(getMedicalSurveysSuccess(response.data))
        return Promise.resolve(response.data)
      })
      .catch((error) => {
        dispatch(getMedicalSurveysFailure(error))
        return Promise.reject(error)
      })
  }
}