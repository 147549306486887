import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import { bindActionCreators } from 'redux'
import _map from 'lodash/map'

import { getNoSsnUsers } from '../actions/users'

import NoSsnUserCard from './NoSsnUserCard'

import leftArrow from '../images/left-arrow.png'
import rightArrow from '../images/right-arrow.png'
import loadingAnimation from '../images/loading-animation.gif'

class NoSsnUsersList extends Component {
  componentDidMount() {
    const { currentEmployee, getNoSsnUsers } = this.props

    if (currentEmployee) {
      getNoSsnUsers(currentEmployee.id)
    }
  }
  loadPage(pageNumber, e) {

    e.preventDefault()
  }
  render() {
    const { loading, noSsnUsers, currentPage, totalPages } = this.props

    return (
      <div>
        <Row>
          <Col xs={{ span: 12, offset: 0 }} sm={{ span: 12, offset: 0 }} md={{ span: 9, offset: 3 }} lg={{ span: 10, offset: 2 }} className="inbox__main_container">
            {
              loading ?
              <img className="inbox__loading_animation" src={loadingAnimation} />
              :
              ''
            }
            {
              _map(noSsnUsers, (user, index) => {
                return (
                  <NoSsnUserCard key={index} user={user} />
                )
              })
            }
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 12, offset: 0 }} sm={{ span: 12, offset: 0 }} md={{ span: 9, offset: 3 }} lg={{ span: 10, offset: 2 }} >
            <div className="inbox__pagination_container">
              <div  className="inbox__pagination_left_arrow_container">
                { (currentPage === 1) && (totalPages !== 1) ?
                  <img className="inbox__pagination_left_arrow" src={leftArrow} /> 
                 : ''
                }
              </div>
              <div className="inbox__pagination__text_container">
                <p className="inbox__pagination_container_text">
                  Page&nbsp;
                  <a href="" onClick={(e) => this.loadPage(currentPage, e)}>
                    { currentPage }
                  </a>
                    &nbsp;of&nbsp;
                  <a href="" onClick={(e) => this.loadPage(currentPage, e)}>
                    { totalPages }
                  </a>
                </p>
              </div>
              <div className="inbox__pagination_right_arrow_container">
                { 
                  (currentPage < totalPages) ?
                  <img className="inbox__pagination_right_arrow" src={rightArrow} /> 
                  :
                  '' 
               }
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default connect(
  state => ({
    loading: state.users.loadingNoSsnUsers,
    currentEmployee: state.auth.currentEmployee,
    currentPage: state.conversations.currentPage,
    totalPages: state.conversations.totalPages,
    noSsnUsers: state.users.noSsnUsers,
  }),
  dispatch => bindActionCreators({
    getNoSsnUsers,
  }, dispatch),
)(NoSsnUsersList)
