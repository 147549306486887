import {
  CREATE_CONVERSATION_EMPLOYEE_REQUEST,
  CREATE_CONVERSATION_EMPLOYEE_SUCCESS,
  CREATE_CONVERSATION_EMPLOYEE_FAILURE,
} from '../actions/conversationEmployees'

export const defaultState = {
  loading: false,
}

export default (state = defaultState, action) => {
  const newState = Object.assign({}, state)

  switch (action.type) {

    case CREATE_CONVERSATION_EMPLOYEE_REQUEST:
      newState.loading = true
      return newState

    case CREATE_CONVERSATION_EMPLOYEE_SUCCESS:
      newState.loading = false
      return newState

    case CREATE_CONVERSATION_EMPLOYEE_FAILURE:
      newState.loading = false
      return newState

    default:
      return state
  }
}
