import {
  GET_EMPLOYEES_REQUEST,
  GET_EMPLOYEES_SUCCESS,
  GET_EMPLOYEES_FAILURE,
} from '../actions/employees'

export const defaultState = {
  loading: false,
  employees: [],
}

export default (state = defaultState, action) => {
  const newState = Object.assign({}, state)

  switch (action.type) {

    case GET_EMPLOYEES_REQUEST:
      newState.loading = true
      return newState

    case GET_EMPLOYEES_SUCCESS:
      newState.loading = false
      newState.employees = action.payload
      return newState

    case GET_EMPLOYEES_FAILURE:
      newState.loading = false
      return newState

    default:
      return state
  }
};
