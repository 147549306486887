import {
  SET_CURRENT_USER_ALLERGY,
  CLEAR_CURRENT_USER_ALLERGY,
  CLEAR_USER_ALLERGIES,
  GET_USER_ALLERGIES_REQUEST,
  GET_USER_ALLERGIES_SUCCESS,
  GET_USER_ALLERGIES_FAILURE,
} from '../actions/userAllergies'

export const defaultState = {
  loading: false,
  userAllergies: [],
  currentInsurancePolicy: null,
}

export default (state = defaultState, action) => {
  const newState = Object.assign({}, state)

  switch (action.type) {

  	case SET_CURRENT_USER_ALLERGY:
      newState.currentInsurancePolicy = action.payload
      return newState

    case CLEAR_CURRENT_USER_ALLERGY:
      newState.currentInsurancePolicy = null
      return newState

    case CLEAR_USER_ALLERGIES:
      newState.userAllergies = []
      return newState

    case GET_USER_ALLERGIES_REQUEST:
      newState.loading = true
      return newState

    case GET_USER_ALLERGIES_SUCCESS:
      newState.loading = false
      newState.userAllergies = action.payload
      return newState

    case GET_USER_ALLERGIES_FAILURE:
      newState.loading = false
      return newState

    default:
      return state
  }
};
