import axios from 'axios'
import qs from 'qs'

const axiosClient = axios.create();
// axiosClient.defaults.headers.post['Content-Type'] = 'application/json'
axiosClient.defaults.baseURL = process.env.REACT_APP_API_URL
axiosClient.defaults.paramsSerializer = (params) => {
  return qs.stringify(params, { arrayFormat: 'brackets' })
}

export default axiosClient
