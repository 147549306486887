export const setAuth = (id, email, client, authToken) => {
	localStorage.setItem("id", id)
	localStorage.setItem("email", email)
	localStorage.setItem("client", client)
	localStorage.setItem("authToken", authToken)
}

export const getCurrentEmployeeId = () => {
	return parseInt(localStorage.getItem("id"))
}

export const isLoggedIn = () => {
	if (localStorage.getItem("id") !== null && localStorage.getItem("email") !== null && localStorage.getItem("client") !== null && localStorage.getItem("authToken") !== null) {
		return true
	} else {
		return false
	}
}

export const authHeaders = () => {
	if (localStorage.getItem("email") && localStorage.getItem("client") && localStorage.getItem("authToken")) {
		return {
			"Content-Type": "application/json",
	        "email": localStorage.getItem("email"),
	        "client": localStorage.getItem("client"),
	        "access-token": localStorage.getItem("authToken")
		}
	}
}

export const clearAuth = () => {
	localStorage.clear()
}