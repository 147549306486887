import axiosClient from '../utils/axios'
import { authHeaders } from '../utils/localStorage'

export const CREATE_CONVERSATION_EMPLOYEE_REQUEST = 'CREATE_CONVERSATION_EMPLOYEE_REQUEST'
export function createConversationEmployeeRequest() {
  return {
    type: CREATE_CONVERSATION_EMPLOYEE_REQUEST,
  }
}

export const CREATE_CONVERSATION_EMPLOYEE_SUCCESS = 'CREATE_CONVERSATION_EMPLOYEE_SUCCESS'
export function createConversationEmployeeSuccess(conversationEmployee) {
  return {
    type: CREATE_CONVERSATION_EMPLOYEE_SUCCESS,
    payload: conversationEmployee,
  }
}

export const CREATE_CONVERSATION_EMPLOYEE_FAILURE = 'CREATE_CONVERSATION_EMPLOYEE_FAILURE'
export function createConversationEmployeeFailure(error) {
  return {
    type: CREATE_CONVERSATION_EMPLOYEE_FAILURE,
    payload: error,
  }
}

export function createConversationEmployee(employeeId, formattedConversationEmployee) {
  return (dispatch) => {
    dispatch(createConversationEmployeeRequest())
    return axiosClient
      .post(`${process.env.REACT_APP_API_URL}/v1/employees/${employeeId}/conversation_employees`, { conversation_employee: formattedConversationEmployee }, { headers: authHeaders() })
      .then((response) => {
        dispatch(createConversationEmployeeSuccess(response.data))
        return Promise.resolve(response.data)
      })
      .catch((error) => {
        dispatch(createConversationEmployeeFailure(error))
        return Promise.reject(error)
      })
  }
}