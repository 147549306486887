import { createStore, applyMiddleware } from "redux"
import asyncReducer from "./reducers"

import thunk from "redux-thunk"
import { composeWithDevTools } from "redux-devtools-extension"

const composeEnhancers = composeWithDevTools({
  trace: true,
  traceLimit: 25,
})

const store = createStore(asyncReducer, composeEnhancers(applyMiddleware(thunk)));

export default store
