import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Row, Col } from 'react-bootstrap'
import { bindActionCreators } from 'redux'
import { Formik, Form, Field, ErrorMessage } from 'formik'

import { isLoggedIn } from '../utils/localStorage'
import { loginEmployee, setCurrentEmployee } from '../actions/auth'

import logo from '../images/logo.png'

let initialValues = {
  email: '',
  password: ''
}

const TextField = ({field, form, ...props}) => {
    return (
      <div className={props.textFieldContainerClass}>
        <h4 className={props.textFieldHeaderClass}>{ props.placeholder }</h4>
        <input className={props.textFieldInputClass} {...field} {...props} />
        <div className={props.textFieldErrorClass}>
          <ErrorMessage name={field.name} component="div" />
        </div>
      </div>
    )
}

class Login extends Component {
  constructor(props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)
  }
  componentDidMount() {
    const { isLoggedIn } = this.props

    if (isLoggedIn === true)  {
      this.props.history.push("/inbox")
    }
  }
  validate(values) {
    const errors = {}

    if (!values.email) {
      errors.email = 'Required'
    }

    if (!values.password) {
      errors.password = 'Required'
    } 

    return errors
  }
  handleSubmit(values) {
    const { loginEmployee, setCurrentEmployee } = this.props

    const loginCreds = {
      email: values.email,
      password: values.password,
    }

    loginEmployee(loginCreds).then((response) => {
      if (response) {
        setCurrentEmployee(response)
        this.props.history.push("/inbox")
      }
    })
    .catch((error) => {
      console.log(error)
    })
  }
  render() {

    return (
      <div className="login__main_container">
       <Row>
  	    <Col xs={{ span: 10, offset: 1 }} className="login___container"> 
          <Formik
            initialValues={initialValues}
            validate={this.validate}
            onSubmit={this.handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form className="login___form_container">
                <Row>
                  <Col className="login___header_container" lg={{ span: 4, offset: 4 }} md={{ span: 8, offset: 2 }} xs={{ span: 10, offset: 1 }}>
                    <img className="login___logo" src={logo} />
                    <h1 className="login__header">Concierge Sign In</h1>
                  </Col>
                </Row>
                <Row>
                  <Col lg={{ span: 4, offset: 4 }} md={{ span: 8, offset: 2 }} xs={{ span: 10, offset: 1 }}>
                    <Field name="email" placeholder="Email" component={TextField} textFieldContainerClass="login__email_textfield_container" textFieldHeaderClass="login__email_text_field_header" textFieldInputClass="login__email_text_field_input" textFieldErrorClass="login__email_error_container" />
                  </Col>
                </Row>
                <Row>
                  <Col lg={{ span: 4, offset: 4 }} md={{ span: 8, offset: 2 }} xs={{ span: 10, offset: 1 }}>
                    <Field type="password" name="password" autoComplete="on" placeholder="Password" component={TextField} textFieldContainerClass="login__password_textfield_container" textFieldHeaderClass="login__password_text_field_header" textFieldInputClass="login__password_text_field_input" textFieldErrorClass="login__password_error_container" />
                  </Col>
                </Row>
                <Row>
                  <Col lg={{ span: 4, offset: 4 }} md={{ span: 8, offset: 2 }} xs={{ span: 10, offset: 1 }} className="login__button_container">
                    <button type="submit" className="login__button">
                      Sign In
                    </button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
  	    </Col>
       </Row>
      </div>
    )
  }
}

export default withRouter(connect(
  state => ({
  }),
  dispatch => bindActionCreators({
    loginEmployee,
    setCurrentEmployee,
  }, dispatch),
)(Login))
