import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Container } from 'react-bootstrap'
import { bindActionCreators } from 'redux'
import _map from 'lodash/map'
import _uniqBy from 'lodash/uniqBy'
import { withRouter } from 'react-router'

import { isLoggedIn, getCurrentEmployeeId } from '../utils/localStorage'
import { isEmployeeTokenValid, clearCurrentEmployee, setCurrentEmployee, logoutEmployee } from '../actions/auth'
import { getEmployee } from '../actions/employees'
import { getConversations, getConversationsTotalPages, clearConversations, clearConversationsTotalPages, setCurrentConversationPage } from '../actions/conversations'
import { setCurrentMessagesPage } from '../actions/messages'

import ConversationCard from './ConversationCard'

import leftArrow from '../images/left-arrow.png'
import rightArrow from '../images/right-arrow.png'

import loadingAnimation from '../images/loading-animation.gif'


class Inbox extends Component {
  constructor(props) {
    super(props)

    this.pollConversations = this.pollConversations.bind(this)
    this.loadPage = this.loadPage.bind(this)
    this.goToNextPage = this.goToNextPage.bind(this)
    this.goToPreviousPage = this.goToPreviousPage.bind(this)
  }
  componentDidMount() {
    const { 
      isEmployeeTokenValid, 
      clearCurrentEmployee, 
      setCurrentEmployee, 
      getEmployee,
      logoutEmployee, 
      getConversations, 
      getConversationsTotalPages, 
      currentEmployee, 
      currentPage, 
      setCurrentMessagesPage,
      clearConversations,
      clearConversationsTotalPages,
    } = this.props

    setCurrentMessagesPage(1)

    if (isLoggedIn() === true) {
      let employeeId = getCurrentEmployeeId()
      isEmployeeTokenValid(employeeId).then((response) => {
        getEmployee(employeeId).then((response) => {
          if (response) {
            clearCurrentEmployee()
            // clearConversations()
            clearConversationsTotalPages()

            setCurrentEmployee(response)
            getConversationsTotalPages(response.id, currentPage)
            getConversations(currentEmployee.id, currentPage)

            setInterval(this.pollConversations, 60000);
          }
        })
        .catch((error) => {
          logoutEmployee()
          clearCurrentEmployee()
          this.props.history.push("/login")
        })
      })
      .catch((error) => {
        logoutEmployee()
        clearCurrentEmployee()
        this.props.history.push("/login")
      })
    } else {
      logoutEmployee()
      clearCurrentEmployee()
      this.props.history.push("/login")
    }

    // setInterval(this.pollConversations, 60000);
  }
  pollConversations() {
    const { 
      isEmployeeTokenValid, 
      clearCurrentEmployee, 
      setCurrentEmployee, 
      getEmployee,
      logoutEmployee, 
      getConversations, 
      getConversationsTotalPages, 
      currentEmployee, 
      currentPage, 
      setCurrentMessagesPage,
      clearConversations,
      clearConversationsTotalPages,
    } = this.props

    console.log(currentPage)
    if (currentEmployee) {
      getConversations(currentEmployee.id, currentPage)
    }

    // let employeeId = getCurrentEmployeeId()
    //   if (currentEmployee && employeeId !== null) {
    //     console.log(currentPage)
    //     let page = currentPage

    //     isEmployeeTokenValid(employeeId).then((response) => {
    //       getEmployee(employeeId).then((response) => {
    //         if (response) {
    //           clearCurrentEmployee()
    //           // clearConversations()
    //           clearConversationsTotalPages()

    //           setCurrentEmployee(response)
    //           getConversations(response.id, page)
    //           getConversationsTotalPages(response.id, page)
    //         }
    //       })
    //       .catch((error) => {
    //         logoutEmployee()
    //         clearCurrentEmployee()
    //         this.props.history.push("/login")
    //       })
    //     })
    //     .catch((error) => {
    //       logoutEmployee()
    //       clearCurrentEmployee()
    //       this.props.history.push("/login")
    //     })
    //   }
  }
  loadPage(pageNumber, e) {
    const { 
      currentPage,
      totalPages,
      currentEmployee, 
      clearConversations, 
      clearConversationsTotalPages, 
      getConversations, 
      getConversationsTotalPages,
      setCurrentConversationPage,
    } = this.props

    e.preventDefault()

    clearConversations()
    clearConversationsTotalPages()

    setCurrentConversationPage(pageNumber)
    getConversations(currentEmployee.id, pageNumber)
    getConversationsTotalPages(currentEmployee.id, pageNumber)
  }
  goToNextPage() {
    const { 
      getConversations, 
      getConversationsTotalPages, 
      currentEmployee, 
      currentPage, 
      totalPages,
      setCurrentMessagesPage,
      clearConversations,
      clearConversationsTotalPages,
      setCurrentConversationPage,
    } = this.props

    let nextPage = currentPage < totalPages ? currentPage+1 : currentPage
    setCurrentMessagesPage(nextPage)
    clearConversations()
    clearConversationsTotalPages()

    setCurrentConversationPage(nextPage)
    getConversations(currentEmployee.id, nextPage)
    getConversationsTotalPages(currentEmployee.id, nextPage)
  }
  goToPreviousPage() {
    const { 
      getConversations, 
      getConversationsTotalPages, 
      currentEmployee, 
      currentPage, 
      totalPages,
      setCurrentMessagesPage,
      clearConversations,
      clearConversationsTotalPages,
      setCurrentConversationPage,
    } = this.props

    let prevPage = (currentPage - 1) < 1 ? currentPage : currentPage-1
    setCurrentMessagesPage(prevPage)
    clearConversations()
    clearConversationsTotalPages()

    setCurrentConversationPage(prevPage)
    getConversations(currentEmployee.id, prevPage)
    getConversationsTotalPages(currentEmployee.id, prevPage)
  }
  render() {
    const { loading, conversations, currentPage, totalPages } = this.props

    let filteredConversations =  _uniqBy(conversations, 'id');

    return (
      <Container fluid>
        <Row>
          <Col xs={{ span: 12, offset: 0 }} sm={{ span: 12, offset: 0 }} md={{ span: 9, offset: 3 }} lg={{ span: 10, offset: 2 }} className="inbox__main_container">
            {
              loading ?
              (
                <img className="inbox__loading_animation" src={loadingAnimation} />
              )
              :
              ''
            }
            {
              _map(filteredConversations, (conversation, index) => {
                return (
                  <ConversationCard key={index} conversation={conversation} />
                )
              })
            }
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 12, offset: 0 }} sm={{ span: 12, offset: 0 }} md={{ span: 9, offset: 3 }} lg={{ span: 10, offset: 2 }} >
            <div className="inbox__pagination_container">
              <div  className="inbox__pagination_left_arrow_container">
                <img onClick={(e) => this.goToPreviousPage(e)} className="inbox__pagination_left_arrow" src={leftArrow} /> 
              </div>
              <div className="inbox__pagination__text_container">
                <p className="inbox__pagination_container_text">
                  Page&nbsp;
                  <a href="" onClick={(e) => this.loadPage(currentPage, e)}>
                    { currentPage }
                  </a>
                    &nbsp;of&nbsp;
                  <a href="" onClick={(e) => this.loadPage(totalPages, e)}>
                    { totalPages }
                  </a>
                </p>
              </div>
              <div className="inbox__pagination_right_arrow_container">
                <img onClick={(e) => this.goToNextPage(e)} className="inbox__pagination_right_arrow" src={rightArrow} /> 
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default withRouter(connect(
  state => ({
    currentEmployee: state.auth.currentEmployee,
    loading: state.conversations.loading,
    currentPage: state.conversations.currentPage,
    totalPages: state.conversations.totalPages,
    conversations: state.conversations.conversations,
  }),
  dispatch => bindActionCreators({
    clearCurrentEmployee,
    setCurrentEmployee,
    getEmployee,
    isEmployeeTokenValid,
    logoutEmployee,
    clearConversations, 
    clearConversationsTotalPages,
    getConversations,
    getConversationsTotalPages,
    setCurrentMessagesPage,
    setCurrentConversationPage,
  }, dispatch),
)(Inbox))
