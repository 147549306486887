import axiosClient from '../utils/axios'
import { authHeaders } from '../utils/localStorage'

// export const SET_CURRENT_PRACTICE = 'SET_CURRENT_PRACTICE'
// export function setCurrentPractice(practice) {
//   return {
//     type: SET_CURRENT_PRACTICE,
//     payload: practice,
//   }
// }

export const CLEAR_REPAIR_ISSUES = 'CLEAR_REPAIR_ISSUES'
export function clearRepairIssues() {
  return {
    type: CLEAR_REPAIR_ISSUES,
  }
}

export const GET_REPAIR_ISSUES_REQUEST = 'GET_REPAIR_ISSUES_REQUEST'
export function getRepairIssuesRequest() {
  return {
    type: GET_REPAIR_ISSUES_REQUEST,
  }
}

export const GET_REPAIR_ISSUES_SUCCESS = 'GET_REPAIR_ISSUES_SUCCESS'
export function getRepairIssuesSuccess(employees) {
  return {
    type: GET_REPAIR_ISSUES_SUCCESS,
    payload: employees,
  }
}

export const GET_REPAIR_ISSUES_FAILURE = 'GET_REPAIR_ISSUES_FAILURE'
export function getRepairIssuesFailure(error) {
  return {
    type: GET_REPAIR_ISSUES_FAILURE,
    payload: error,
  }
}

export function getRepairIssues(employeeId, pageNumber, status) {
  return (dispatch) => {
    dispatch(getRepairIssuesRequest())
    return axiosClient
      .get(`${process.env.REACT_APP_API_URL}/v1/employees/${employeeId}/repair_issues`, { params: { page: pageNumber, status: status }, headers: authHeaders() })
      .then((response) => {
        dispatch(getRepairIssuesSuccess(response.data))
        return response.data
      })
      .catch((error) => {
        dispatch(getRepairIssuesFailure(error))
      })
  }
}

export const SET_CURRENT_REPAIR_ISSUE_PAGE = 'SET_CURRENT_REPAIR_ISSUE_PAGE'
export function setCurrentRepairIssuePage(page) {
  return {
    type: SET_CURRENT_REPAIR_ISSUE_PAGE,
    payload: page,
  }
}


export const GET_REPAIR_ISSUES_TOTAL_PAGES_REQUEST = 'GET_REPAIR_ISSUES_TOTAL_PAGES_REQUEST'
export function getRepairIssuesTotalPagesRequest() {
  return {
    type: GET_REPAIR_ISSUES_TOTAL_PAGES_REQUEST,
  }
}

export const GET_REPAIR_ISSUES_TOTAL_PAGES_SUCCESS = 'GET_REPAIR_ISSUES_TOTAL_PAGES_SUCCESS'
export function getRepairIssuesTotalPagesSuccess(conversations) {
  return {
    type: GET_REPAIR_ISSUES_TOTAL_PAGES_SUCCESS,
    payload: conversations,
  }
}

export const GET_REPAIR_ISSUES_TOTAL_PAGES_FAILURE = 'GET_REPAIR_ISSUES_TOTAL_PAGES_FAILURE'
export function getRepairIssuesTotalPagesFailure(error) {
  return {
    type: GET_REPAIR_ISSUES_TOTAL_PAGES_FAILURE,
    payload: error,
  }
}

export const CLEAR_REPAIR_ISSUES_TOTAL_PAGES = 'CLEAR_REPAIR_ISSUES_TOTAL_PAGES'
export function clearRepairIssuesTotalPages() {
  return {
    type: CLEAR_REPAIR_ISSUES_TOTAL_PAGES,
  }
}

export function getRepairIssuesTotalPages(employeeId, pageNumber) {
  return (dispatch) => {
    dispatch(getRepairIssuesTotalPagesRequest())
    return axiosClient
      .get(`${process.env.REACT_APP_API_URL}/v1/employees/${employeeId}/repair_issues/total_pages`, { params: { page: pageNumber }, headers: authHeaders() })
      .then((response) => {
        dispatch(getRepairIssuesTotalPagesSuccess(response.data))
      })
      .catch((error) => {
        dispatch(getRepairIssuesTotalPagesFailure(error))
      })
  }
}


export const UPDATE_REPAIR_ISSUES_REQUEST = 'UPDATE_REPAIR_ISSUES_REQUEST'
export function updateRepairIssueRequest() {
  return {
    type: UPDATE_REPAIR_ISSUES_REQUEST,
  }
}

export const UPDATE_REPAIR_ISSUES_SUCCESS = 'UPDATE_REPAIR_ISSUES_SUCCESS'
export function updateRepairIssueSuccess(employees) {
  return {
    type: UPDATE_REPAIR_ISSUES_SUCCESS,
    payload: employees,
  }
}

export const UPDATE_REPAIR_ISSUES_FAILURE = 'UPDATE_REPAIR_ISSUES_FAILURE'
export function updateRepairIssueFailure(error) {
  return {
    type: UPDATE_REPAIR_ISSUES_FAILURE,
    payload: error,
  }
}

export function updateRepairIssue(formattedRepairIssue, employeeId) {
  return (dispatch) => {
    dispatch(updateRepairIssueRequest())
    return axiosClient
      .put(`${process.env.REACT_APP_API_URL}/v1/employees/${employeeId}/repair_issues/${formattedRepairIssue.id}`, { repair_issue: formattedRepairIssue }, { headers: authHeaders() })
      .then((response) => {
        dispatch(updateRepairIssueSuccess(response.data))
        return Promise.resolve(response.data)
      })
      .catch((error) => {
        dispatch(updateRepairIssueFailure(error))
        return Promise.reject(error)
      })
  }
}

export const CLEAR_STATUS_FILTER = 'CLEAR_STATUS_FILTER'
export function clearStatusFilter() {
  return {
    type: CLEAR_STATUS_FILTER,
  }
}

export const SET_STATUS_FILTER = 'SET_STATUS_FILTER'
export function setStatusFilter(status) {
  return {
    type: SET_STATUS_FILTER,
    payload: status,
  }
}