import moment from 'moment'
import {
  CLEAR_SCHEDULE_GENERATOR_DATE_RANGE,
  SET_SCHEDULE_GENERATOR_DATE_RANGE,
} from '../actions/scheduleGenerators'



export const defaultState = {
  loading: false,
  scheduleGeneratorDateRange: { startDate: moment().clone().startOf('day'), endDate: moment().clone().endOf('day')},
}

export default (state = defaultState, action) => {
  const newState = Object.assign({}, state)

  switch (action.type) {

    case CLEAR_SCHEDULE_GENERATOR_DATE_RANGE:
      newState.scheduleGeneratorDateRange = { startDate: moment().clone().startOf('day'), endDate: moment().clone().endOf('day')}
      return newState

    case SET_SCHEDULE_GENERATOR_DATE_RANGE:
      newState.scheduleGeneratorDateRange = { startDate: action.payload.clone().startOf('day'), endDate: action.payload.clone().endOf('day')}
      return newState

    default:
      return state
  }
};
