import React from 'react'
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom"
import { Provider } from 'react-redux'
import store from './reducers/store'
import AWS from 'aws-sdk'
import { createBrowserHistory } from "history";

import { isLoggedIn } from './utils/localStorage'

import Login from './components/Login'
import Home from './components/Home'
import Inbox from './components/Inbox'

const history = createBrowserHistory();

// Initialize the Amazon Cognito credentials provider
AWS.config.region = process.env.REACT_APP_AWS_S3_REGION; // Region
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: process.env.REACT_APP_AWS_S3_IDENTITY_POOL_ID,
});
AWS.config.credentials.get()

function App() {
  return (
    <BrowserRouter history={history}>
      <Provider store={store}>
        <div className="app_root">
        <Switch>
          <Route path="/login">
            <Login />
          </Route>
          <PrivateRoute path="/">
            <Home />
          </PrivateRoute>
        </Switch>

          
        </div>
      </Provider>
    </BrowserRouter>
  );
}

function PrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        (isLoggedIn() === true) ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

export default App
