import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import { bindActionCreators } from 'redux'
import moment from 'moment'

import { setCurrentView } from '../actions/views'
import { setCurrentConversation } from '../actions/conversations'

class ConversationCard extends Component {
  constructor(props) {
    super(props)

    this.showConversation = this.showConversation.bind(this)
  }
  showConversation() {
    const { conversation, setCurrentView, setCurrentConversation, currentEmployee } = this.props

    setCurrentView("conversation")
    setCurrentConversation(conversation)
  }
  render() {
    const { conversation } = this.props

    var conversationStatus = null
    if (conversation.has_unread_user_messages && conversation.has_unread_user_messages === true) {
      conversationStatus = (
        <div>
          <span className="conversation_card__last_message_status_has_unread"></span>
          <span className="conversation_card__last_message_status_has_unread_label">New</span>
        </div>
      )
    } else {
      conversationStatus = (
        <div className="conversation_card__status_container">
          <span className="conversation_card__last_message_status"></span>
          <span className="conversation_card__last_message_status_label"></span>
        </div>
      )
    }

    var lastMessageFormatted = ""
    var lastMessage = ""

    var lastMessageModifer = ""
    if (conversation.last_message && conversation.last_message.messageable_type === "User") {
      lastMessageModifer = conversation.user.first_name + " " + conversation.user.last_name
    }

    if (conversation.last_message && conversation.last_message.messageable_type === "Employee") {
      lastMessageModifer = "Dentologie"
    }
    
    if (conversation.last_message === null) {
      lastMessageFormatted = "No message history"
    } else {

      switch(conversation.last_message.message_type) {
        case "text":
          lastMessage = conversation.last_message.body
          break
        case "image":
          lastMessage = "Sent an image"
          break
        default:
          lastMessage = conversation.last_message.body
      }

      lastMessageFormatted = `${lastMessageModifer}: ${lastMessage}`
    }

    return (
      <div className="conversation_card__container" onClick={() => this.showConversation()} >
        <Row className="conversation_card__row_container">
          <Col xs={3} sm={2} lg={2}>
            { conversationStatus }
          </Col>
          <Col xs={3} sm={2} lg={2}>
            <p className="conversation_card__user_name_label">{ conversation.user ? conversation.user.first_name : "Non-Existent" } { conversation.user ? conversation.user.last_name : "Patient" }</p>
          </Col>
          <Col xs={4} sm={6} lg={6}>
            <p className="conversation_card__last_message_body_label">{ lastMessageFormatted }</p>
          </Col>
          <Col xs={2} sm={2} lg={2}>
            <span className="conversation_card__last_message_created_at_label">{ conversation.last_message ? moment(conversation.last_message.created_at).format('l') : 'N/A' }</span>
          </Col>
        </Row>
      </div>
    )
  }
}

export default connect(
  state => ({
    currentEmployee: state.auth.currentEmployee,
  }),
  dispatch => bindActionCreators({
    setCurrentView,
    setCurrentConversation,
  }, dispatch),
)(ConversationCard)
