import axiosClient from '../utils/axios'
import { authHeaders } from '../utils/localStorage'

// export const SET_CURRENT_INSURANCE_POLICY = 'SET_CURRENT_INSURANCE_POLICY'
// export function setCurrentInsurancePolicy(insurancePolicy) {
//   return {
//     type: SET_CURRENT_INSURANCE_POLICY,
//     payload: insurancePolicy,
//   }
// }

// export const CLEAR_CURRENT_INSURANCE_POLICY = 'CLEAR_CURRENT_INSURANCE_POLICY'
// export function clearCurrentInsurancePolicy() {
//   return {
//     type: CLEAR_CURRENT_INSURANCE_POLICY,
//   }
// }

export const CLEAR_OPERATORIES = 'CLEAR_OPERATORIES'
export function clearOperatories() {
  return {
    type: CLEAR_OPERATORIES,
  }
}

export const GET_OPERATORIES_REQUEST = 'GET_OPERATORIES_REQUEST'
export function getOperatoriesRequest() {
  return {
    type: GET_OPERATORIES_REQUEST,
  }
}

export const GET_OPERATORIES_SUCCESS = 'GET_OPERATORIES_SUCCESS'
export function getOperatoriesSuccess(operatories) {
  return {
    type: GET_OPERATORIES_SUCCESS,
    payload: operatories,
  }
}

export const GET_OPERATORIES_FAILURE = 'GET_OPERATORIES_FAILURE'
export function getOperatoriesFailure(error) {
  return {
    type: GET_OPERATORIES_FAILURE,
    payload: error,
  }
}

export function getOperatories(employeeId, practiceId) {
  return (dispatch) => {
    dispatch(getOperatoriesRequest())
    return axiosClient
      .get(`${process.env.REACT_APP_API_URL}/v1/employees/${employeeId}/practices/${practiceId}/operatories`, { headers: authHeaders() })
      .then((response) => {
        dispatch(getOperatoriesSuccess(response.data))
        return response.data
      })
      .catch((error) => {
        dispatch(getOperatoriesFailure(error))
      })
  }
}