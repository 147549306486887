import axiosClient from '../utils/axios'
import { setAuth } from '../utils/localStorage'
import { authHeaders } from '../utils/localStorage'

export const SET_CURRENT_EMPLOYEE = 'SET_CURRENT_EMPLOYEE'
export function setCurrentEmployee(employee) {
  return {
    type: SET_CURRENT_EMPLOYEE,
    payload: employee,
  }
}

export const CLEAR_CURRENT_EMPLOYEE = 'CLEAR_CURRENT_EMPLOYEE'
export function clearCurrentEmployee() {
  return {
    type: CLEAR_CURRENT_EMPLOYEE,
  }
}

export const LOGOUT_EMPLOYEE = 'LOGOUT_EMPLOYEE'
export function logoutEmployee() {
  return {
    type: LOGOUT_EMPLOYEE,
  }
}

export const LOGIN_EMPLOYEE_REQUEST = 'LOGIN_EMPLOYEE_REQUEST'
export function loginEmployeeRequest() {
  return {
    type: LOGIN_EMPLOYEE_REQUEST,
  }
}

export const LOGIN_EMPLOYEE_SUCCESS = 'LOGIN_EMPLOYEE_SUCCESS'
export function loginEmployeeSuccess(employee) {
  return {
    type: LOGIN_EMPLOYEE_SUCCESS,
    payload: employee,
  }
}

export const LOGIN_EMPLOYEE_FAILURE = 'LOGIN_EMPLOYEE_FAILURE'
export function loginEmployeeFailure(error) {
  return {
    type: LOGIN_EMPLOYEE_FAILURE,
    payload: error,
  }
}

export function loginEmployee(loginCreds) {
  return (dispatch) => {
    dispatch(loginEmployeeRequest())
    return axiosClient
      .post(`${process.env.REACT_APP_API_URL}/v1/employees/sign_in`, loginCreds)
      .then((response) => {
        let id = response.data.id
        let email = response.data.email
        let client = response.headers["client"]
        let authToken = response.headers["access-token"]
        setAuth(id, email, client, authToken)
        
        dispatch(loginEmployeeSuccess(response.data))
        // return response.data
        return Promise.resolve(response.data)
      })
      .catch((error) => {
        dispatch(loginEmployeeFailure(error))
        return Promise.reject(error)
      })
  }
}


export const IS_EMPLOYEE_TOKEN_VALID_REQUEST = 'IS_EMPLOYEE_TOKEN_VALID_REQUEST'
export function isEmployeeTokenValidRequest() {
  return {
    type: IS_EMPLOYEE_TOKEN_VALID_REQUEST,
  }
}

export const IS_EMPLOYEE_TOKEN_VALID_SUCCESS = 'IS_EMPLOYEE_TOKEN_VALID_SUCCESS'
export function isEmployeeTokenValidSuccess(isValid) {
  return {
    type: IS_EMPLOYEE_TOKEN_VALID_SUCCESS,
    payload: isValid,
  }
}

export const IS_EMPLOYEE_TOKEN_VALID_FAILURE = 'IS_EMPLOYEE_TOKEN_VALID_FAILURE'
export function isEmployeeTokenValidFailure(error) {
  return {
    type: IS_EMPLOYEE_TOKEN_VALID_FAILURE,
    payload: error,
  }
}

export function isEmployeeTokenValid(employeeId) {
  return (dispatch) => {
    dispatch(isEmployeeTokenValidRequest())
    return axiosClient
      .get(`${process.env.REACT_APP_API_URL}/v1/employees/${employeeId}/is_token_valid`, { headers: authHeaders() })
      .then((response) => {
        dispatch(isEmployeeTokenValidSuccess(response.data))
        return Promise.resolve(response.data)
      })
      .catch((error) => {
        dispatch(isEmployeeTokenValidFailure(error))
        return Promise.reject(error)
      })
  }
}