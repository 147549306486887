import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Container } from 'react-bootstrap'
import { bindActionCreators } from 'redux'
import _map from 'lodash/map'
import _filter from 'lodash/filter'
import _isEmpty from 'lodash/isEmpty'
import _xor from 'lodash/xor'
import _uniq from 'lodash/uniq'
import { withRouter } from 'react-router'
import { Formik, Field, Form } from 'formik';

import leftArrow from '../images/left-arrow.png'
import rightArrow from '../images/right-arrow.png'

import loadingAnimation from '../images/loading-animation.gif'

import { getEmployeeProductionStats } from '../actions/stats'
import { clearCurrentEmployeeProductionIds, setCurrentEmployeeProductionIds, clearInitialValuesForEmployeeProductionForm, setInitialValuesForEmployeeProductionForm } from '../actions/stats'
import { getEmployees } from '../actions/employees'

import EmployeeProductionHorizontalBarChart from './EmployeeProductionHorizontalBarChart'

let initialValues = {
  checkedEmployees: []
}

class EmployeeProduction extends Component {
  constructor(props) {
  	super(props)

    this.state = {
      allDentistsFilterOn: false,
      allHygenistsFilterOn: false,
    }

  	this.handleSubmit = this.handleSubmit.bind(this)
  	this.clickedAllDentistsFilter = this.clickedAllDentistsFilter.bind(this)
  	this.clickedAllHygienistsFilter = this.clickedAllHygienistsFilter.bind(this)
    this.onChangeDentist = this.onChangeDentist.bind(this)
  }
  componentDidMount() {
    const { getEmployees  } = this.props

    getEmployees()
  }
  onChangeDentist(e, setFieldValue, idValue) {
    const { clearCurrentEmployeeProductionIds, setInitialValuesForEmployeeProductionForm, initialValues, employees, setCurrentEmployeeProductionIds, employeeProductionIds  } = this.props

    if (e.target.checked === true) {
      const dentists = _filter(employees, { role: "dentist", active: true })
      const dentistIds = _map(dentists, 'id')

      var newEmployeeIds = employeeProductionIds
      newEmployeeIds.push(parseInt(idValue))
      newEmployeeIds = _uniq(newEmployeeIds)
      if (_isEmpty(_xor(dentistIds, newEmployeeIds)) === true) {
        this.setState({ allDentistsFilterOn: true })
      }

      setCurrentEmployeeProductionIds(newEmployeeIds)
      setFieldValue('checkedEmployees', newEmployeeIds)
      let formattedValues = { checkedEmployees: newEmployeeIds }
      setInitialValuesForEmployeeProductionForm(formattedValues)
    } else {
      if (employeeProductionIds.includes(idValue)) {
        let newEmployeeIds = _filter(employeeProductionIds, (e) => { return e != idValue })
        newEmployeeIds = _uniq(newEmployeeIds)
        setCurrentEmployeeProductionIds(newEmployeeIds)
        setFieldValue('checkedEmployees', newEmployeeIds)
        let formattedValues = { checkedEmployees: newEmployeeIds }
        setInitialValuesForEmployeeProductionForm(formattedValues)

        this.setState({ allDentistsFilterOn: false })
      } 
    }
  }
  clickedAllDentistsFilter(e, setFieldValue) {
    const { clearCurrentEmployeeProductionIds, setInitialValuesForEmployeeProductionForm, initialValues, employees, setCurrentEmployeeProductionIds, employeeProductionIds  } = this.props

    const dentists = _filter(employees, { role: "dentist", active: true })
    const dentistIds = _map(dentists, 'id')

    if (_isEmpty(_xor(dentistIds, employeeProductionIds)) === true) {
      clearCurrentEmployeeProductionIds()
      setInitialValuesForEmployeeProductionForm({ checkedEmployees: []})
      setFieldValue('checkedEmployees', [])
    } else {
      var idsConverted = _map(employeeProductionIds.concat(dentistIds), (x) => { return parseInt(x) })
      let oldAndNewIds = _uniq(idsConverted)
      let formattedValues = { checkedEmployees: oldAndNewIds }
      setInitialValuesForEmployeeProductionForm(formattedValues)
      setCurrentEmployeeProductionIds(oldAndNewIds)
      setFieldValue('checkedEmployees', oldAndNewIds)
    }

    this.setState({ allDentistsFilterOn: !this.state.allDentistsFilterOn })
  }
  clickedAllHygienistsFilter(e, setFieldValue) {
    const { clearCurrentEmployeeProductionIds, setInitialValuesForEmployeeProductionForm, initialValues, employees, setCurrentEmployeeProductionIds, employeeProductionIds  } = this.props

    const hyegienists = _filter(employees, { role: "hygienist", active: true })
    const hyegienistIds = _map(hyegienists, 'id')

    if (_isEmpty(_xor(hyegienistIds, employeeProductionIds)) === true) {
    	clearCurrentEmployeeProductionIds()
    	setInitialValuesForEmployeeProductionForm({ checkedEmployees: []})
    	setFieldValue('checkedEmployees', [])
    } else {
      var idsConverted = _map(employeeProductionIds.concat(hyegienistIds), (x) => { return parseInt(x) })
    	let oldAndNewIds = _uniq(idsConverted)
    	let formattedValues = { checkedEmployees: oldAndNewIds }

    	setInitialValuesForEmployeeProductionForm(formattedValues)

    	clearCurrentEmployeeProductionIds()
    	setCurrentEmployeeProductionIds(oldAndNewIds)
    	setFieldValue('checkedEmployees', oldAndNewIds)
    }
  }
  handleSubmit(values) {
    const { currentEmployee, clearCurrentEmployeeProductionIds, setCurrentEmployeeProductionIds, getEmployeeProductionStats, employeeProductionIds, employeeProductionDateRange   } = this.props

  	if (currentEmployee) {
  		clearCurrentEmployeeProductionIds()
	  	setCurrentEmployeeProductionIds(values.checkedEmployees)

      let uniqueIds =_uniq(_map(values.checkedEmployees, (x) => { return parseInt(x) }))
  		getEmployeeProductionStats(currentEmployee.id, uniqueIds, employeeProductionDateRange.startDate.toISOString(), employeeProductionDateRange.endDate.toISOString())
  	}
  }
  render() {
    const { employees, employeeProductionStats, loading } = this.props

    const dentists = _filter(employees, { role: "dentist", active: true })
    const hygienists = _filter(employees, { role: "hygienist", active: true })

    return (
      <Container fluid className="employee_production__main_container">
        <Row>
          <Col xs={{ span: 12, offset: 0 }} sm={{ span: 12, offset: 0 }} md={{ span: 12, offset: 0 }} lg={{ span: 12, offset: 0 }} className="employee_production__main_container">
	          <Row>
	          	<Col xs={{ span: 2, offset: 0 }} className="employee_production__employee_container">
		          <Formik
		            initialValues={initialValues}
		            onSubmit={this.handleSubmit}
		          >
		            {({ isSubmitting, setFieldValue }) => (
		              <Form className="employee_production___form_container">
			          		<div className="employee_production__dentist_form_container">
			          			<h4 className="employee_production__form_title">Dentists&nbsp;&nbsp; {/* <input onClick={(e) => this.clickedAllDentistsFilter(e, setFieldValue)} type="checkbox" id="dentists" name="dentists" value="dentists" checked={this.state.allDentistsFilterOn}/>*/} </h4>
                      {
                        _map(dentists, (employee, index) => {
                            return (
                                <div key={employee.id}>
                                  <label key={employee.id}>
                                    <Field key={employee.id} type="checkbox" id={employee.id} name="checkedEmployees" value={`${employee.id}`} />
                                    &nbsp;{employee.first_name} {employee.last_name}
                                  </label>
                                </div>
                            )
                          })
                      }
			          		</div>
			          		<div className="employee_production__hygienist_form_container">
			          			<h4 className="employee_production__form_title">Hygienists&nbsp;&nbsp; {/*<input onClick={(e) => this.clickedAllHygienistsFilter(e, setFieldValue)} type="checkbox" id="hygienists" name="hygienists" value="hygienists" />*/}</h4>
                      {
                        _map(hygienists, (employee, index) => {
                            return (
                                <div key={employee.id}>
                                  <label key={employee.id}>
                                    <Field key={employee.id} type="checkbox" id={`${employee.id}`} name="checkedEmployees" value={`${employee.id}`} />
                                    &nbsp;{employee.first_name} {employee.last_name}
                                  </label>
                                </div>
                              )
                          }
                        )
                      }
			          		</div>
			          		<button type="submit" className="employee_production__form_button">
			          		  Submit
			          		</button>
	          	    </Form>
	          	  )}
	          	</Formik>
	          	</Col>
	          	<Col xs={{ span: 10, offset: 0}}>
                {
                  loading ?
                  <img className="employee_production__loading_animation" src={loadingAnimation} />
                  :
                  ''
                }
	          		<EmployeeProductionHorizontalBarChart employeeProductionStats={employeeProductionStats} />
	          	</Col>
	          </Row>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default withRouter(connect(
  state => ({
    loading: state.stats.loading,
    currentEmployee: state.auth.currentEmployee,
    employees: state.employees.employees,
    employeeProductionIds: state.stats.employeeProductionIds,
    employeeProductionDateRange: state.stats.employeeProductionDateRange,
    employeeProductionStats: state.stats.employeeProductionStats,
  }),
  dispatch => bindActionCreators({
  	clearInitialValuesForEmployeeProductionForm,
  	setInitialValuesForEmployeeProductionForm,
  	clearCurrentEmployeeProductionIds,
  	setCurrentEmployeeProductionIds,
  	getEmployeeProductionStats,
  	getEmployees,
  }, dispatch),
)(EmployeeProduction))
