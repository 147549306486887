import axiosClient from '../utils/axios'
import { authHeaders } from '../utils/localStorage'
import AWS from 'aws-sdk'

const s3 = new AWS.S3();

function getSignedURL(bucket, path) {
    console.log("Generating Presigned Link ... ");

    let params = {
        Bucket: bucket,
        Key: path
    };

    //expires in default 15 mins.
    return s3.getSignedUrl('getObject', params);
}

function validSignedURL(userId, imageUrl) {
    s3.config.update({credentials: AWS.config.credentials})
    var imageIdentifier = /[^/]*$/.exec(imageUrl)[0]
    let bucket = process.env.REACT_APP_AWS_S3_BUCKET
    let fileKey = `users/${userId}/profile-pictures/${imageIdentifier}`
    let params = {
      Bucket: bucket,
      Key: fileKey
    };

    return new Promise(function(resolve, reject){
        s3.headObject(params).promise()
            .then(function (data) {
              // console.log('s3 File exists' + data);
              resolve(getSignedURL(bucket, fileKey));
            }).catch(function (err) {
            console.log('Generating Presigned Link ... Failed' + err);
            resolve('');
        });
    });
}

export const SET_USER_SEARCH_TEXT = 'SET_USER_SEARCH_TEXT'
export function setUserSearchText(text) {
  return {
    type: SET_USER_SEARCH_TEXT,
    payload: text,
  }
}

export const SET_CURRENT_USER_ID = 'SET_CURRENT_USER_ID'
export function setCurrentUserId(id) {
  return {
    type: SET_CURRENT_USER_ID,
    payload: id,
  }
}

export const SET_CURRENT_USER = 'SET_CURRENT_USER'
export function setCurrentUser(user) {
  return {
    type: SET_CURRENT_USER,
    payload: user,
  }
}

export const CLEAR_CURRENT_USER = 'CLEAR_CURRENT_USER'
export function clearCurrentUser() {
  return {
    type: CLEAR_CURRENT_USER,
  }
}

export const GET_USER_REQUEST = 'GET_USER_REQUEST'
export function getUserRequest() {
  return {
    type: GET_USER_REQUEST,
  }
}

export const GET_USER_SUCCESS = 'GET_USER_SUCCESS'
export function getUserSuccess(users) {
  return {
    type: GET_USER_SUCCESS,
    payload: users,
  }
}

export const GET_USER_FAILURE = 'GET_USER_FAILURE'
export function getUserFailure(error) {
  return {
    type: GET_USER_FAILURE,
    payload: error,
  }
}

export function getUser(employeeId, userId) {
  return (dispatch) => {
    dispatch(getUserRequest())
    return axiosClient
      .get(`${process.env.REACT_APP_API_URL}/v1/employees/${employeeId}/users/${userId}`, { headers: authHeaders() })
      .then((response) => {
        dispatch(getUserSuccess(response.data))
        return response.data
      })
      .catch((error) => {
        dispatch(getUserFailure(error))
      })
  }
}

export const GET_USER_PROFILE_PICTURE_REQUEST = 'GET_USER_PROFILE_PICTURE_REQUEST'
export function getUserProfilePictureRequest(userId) {
  return {
    type: GET_USER_PROFILE_PICTURE_REQUEST,
  }
}

export const GET_USER_PROFILE_PICTURE_SUCCESS = 'GET_USER_PROFILE_PICTURE_SUCCESS'
export function getUserProfilePictureSuccess(imageDict) {
  return {
    type: GET_USER_PROFILE_PICTURE_SUCCESS,
    payload: imageDict,
  }
}

export const GET_USER_PROFILE_PICTURE_FAILURE = 'GET_USER_PROFILE_PICTURE_FAILURE'
export function getUserProfilePictureFailure(error) {
  return {
    type: GET_USER_PROFILE_PICTURE_FAILURE,
    payload: error,
  }
}

export function getUserProfilePicture(userId, imageUrl) {
  return (dispatch) => {
    let userIdNo = userId
    dispatch(getUserProfilePictureRequest())
    validSignedURL(userId, imageUrl)
    .then((signedURL) => {
      var imageDict = {}
      imageDict[userIdNo] = signedURL
      dispatch(getUserProfilePictureSuccess(imageDict))
    })
    .catch((error) => {
      console.log('Generating Presigned Link ... Failed' + error);

      dispatch(getUserProfilePictureFailure(error))
    })
  }
}

export const CLEAR_SEARCH_USERS = 'CLEAR_SEARCH_USERS'
export function clearSearchUsers() {
  return {
    type: CLEAR_SEARCH_USERS,
  }
}

export const GET_SEARCH_USERS_REQUEST = 'GET_SEARCH_USERS_REQUEST'
export function getSearchUsersRequest() {
  return {
    type: GET_SEARCH_USERS_REQUEST,
  }
}

export const GET_SEARCH_USERS_SUCCESS = 'GET_SEARCH_USERS_SUCCESS'
export function getSearchUsersSuccess(users) {
  return {
    type: GET_SEARCH_USERS_SUCCESS,
    payload: users,
  }
}

export const GET_SEARCH_USERS_FAILURE = 'GET_SEARCH_USERS_FAILURE'
export function getSearchUsersFailure(error) {
  return {
    type: GET_SEARCH_USERS_FAILURE,
    payload: error,
  }
}

export function getSearchUsers(employeeId, lastName) {
  return (dispatch) => {
    dispatch(getSearchUsersRequest())
    return axiosClient
      .get(`${process.env.REACT_APP_API_URL}/v1/employees/${employeeId}/users`, { params: { last_name: lastName },  headers: authHeaders() })
      .then((response) => {
        dispatch(getSearchUsersSuccess(response.data))
        return response.data
      })
      .catch((error) => {
        dispatch(getSearchUsersFailure(error))
      })
  }
}

export const GET_NO_SSN_USERS_REQUEST = 'GET_NO_SSN_USERS_REQUEST'
export function getNoSsnUsersRequest() {
  return {
    type: GET_NO_SSN_USERS_REQUEST,
  }
}

export const GET_NO_SSN_USERS_SUCCESS = 'GET_NO_SSN_USERS_SUCCESS'
export function getNoSsnUsersSuccess(users) {
  return {
    type: GET_NO_SSN_USERS_SUCCESS,
    payload: users,
  }
}

export const GET_NO_SSN_USERS_FAILURE = 'GET_NO_SSN_USERS_FAILURE'
export function getNoSsnUsersFailure(error) {
  return {
    type: GET_NO_SSN_USERS_FAILURE,
    payload: error,
  }
}

export function getNoSsnUsers(employeeId) {
  return (dispatch) => {
    dispatch(getNoSsnUsersRequest())
    return axiosClient
      .get(`${process.env.REACT_APP_API_URL}/v1/employees/${employeeId}/users/no_ssn`, { headers: authHeaders() })
      .then((response) => {
        dispatch(getNoSsnUsersSuccess(response.data))
        return response.data
      })
      .catch((error) => {
        dispatch(getNoSsnUsersFailure(error))
      })
  }
}

export const GET_NO_INSURANCE_USERS_REQUEST = 'GET_NO_INSURANCE_USERS_REQUEST'
export function getNoInsuranceUsersRequest() {
  return {
    type: GET_NO_INSURANCE_USERS_REQUEST,
  }
}

export const GET_NO_INSURANCE_USERS_SUCCESS = 'GET_NO_INSURANCE_USERS_SUCCESS'
export function getNoInsuranceUsersSuccess(users) {
  return {
    type: GET_NO_INSURANCE_USERS_SUCCESS,
    payload: users,
  }
}

export const GET_NO_INSURANCE_USERS_FAILURE = 'GET_NO_INSURANCE_USERS_FAILURE'
export function getNoInsuranceUsersFailure(error) {
  return {
    type: GET_NO_INSURANCE_USERS_FAILURE,
    payload: error,
  }
}

export function getNoInsuranceUsers(employeeId) {
  return (dispatch) => {
    dispatch(getNoInsuranceUsersRequest())
    return axiosClient
      .get(`${process.env.REACT_APP_API_URL}/v1/employees/${employeeId}/users/no_insurance`, { headers: authHeaders() })
      .then((response) => {
        dispatch(getNoInsuranceUsersSuccess(response.data))
        return response.data
      })
      .catch((error) => {
        dispatch(getNoInsuranceUsersFailure(error))
      })
  }
}