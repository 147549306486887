import axiosClient from '../utils/axios'
import { authHeaders } from '../utils/localStorage'


export const CLEAR_OPERATORY_SCHEDULES = 'CLEAR_OPERATORY_SCHEDULES'
export function clearOperatorySchedules() {
  return {
    type: CLEAR_OPERATORY_SCHEDULES,
  }
}

export const GET_OPERATORY_SCHEDULES_REQUEST = 'GET_OPERATORY_SCHEDULES_REQUEST'
export function getOperatorySchedulesRequest() {
  return {
    type: GET_OPERATORY_SCHEDULES_REQUEST,
  }
}

export const GET_OPERATORY_SCHEDULES_SUCCESS = 'GET_OPERATORY_SCHEDULES_SUCCESS'
export function getOperatorySchedulesSuccess(operatorySchedules) {
  return {
    type: GET_OPERATORY_SCHEDULES_SUCCESS,
    payload: operatorySchedules,
  }
}

export const GET_OPERATORY_SCHEDULES_FAILURE = 'GET_OPERATORY_SCHEDULES_FAILURE'
export function getOperatorySchedulesFailure(error) {
  return {
    type: GET_OPERATORY_SCHEDULES_FAILURE,
    payload: error,
  }
}

export function getOperatorySchedules(employeeId, practiceId, currentDate) {
  return (dispatch) => {
    dispatch(getOperatorySchedulesRequest())
    return axiosClient
      .get(`${process.env.REACT_APP_API_URL}/v1/employees/${employeeId}/operatory_schedules`, { params: { date: currentDate, practice_id: practiceId },  headers: authHeaders() })
      .then((response) => {
        dispatch(getOperatorySchedulesSuccess(response.data))
        return response.data
      })
      .catch((error) => {
        dispatch(getOperatorySchedulesFailure(error))
      })
  }
}