import {
  CLEAR_CURRENT_EMPLOYEE,
  SET_CURRENT_EMPLOYEE,
  LOGOUT_EMPLOYEE,
  LOGIN_EMPLOYEE_REQUEST,
  LOGIN_EMPLOYEE_SUCCESS,
  LOGIN_EMPLOYEE_FAILURE,
} from '../actions/auth'
import { clearAuth } from '../utils/localStorage'

export const defaultState = {
  loading: false,
  currentEmployee: null,
}

export default (state = defaultState, action) => {
  const newState = Object.assign({}, state)

  switch (action.type) {

    case CLEAR_CURRENT_EMPLOYEE:
      newState.currentEmployee = null
      return newState

    case SET_CURRENT_EMPLOYEE:
      newState.currentEmployee = action.payload
      return newState

    case LOGOUT_EMPLOYEE:
      clearAuth()
      return newState

    case LOGIN_EMPLOYEE_REQUEST:
      newState.loading = true
      return newState

    case LOGIN_EMPLOYEE_SUCCESS:
      newState.loading = false
      return newState

    case LOGIN_EMPLOYEE_FAILURE:
      newState.loading = false
      return newState

    default:
      return state
  }
};
