import {
  SET_CURRENT_MEDICAL_SURVEY_QUESTION,
  CLEAR_CURRENT_MEDICAL_SURVEY_QUESTION,
  CLEAR_MEDICAL_SURVEY_QUESTIONS,
  GET_MEDICAL_SURVEY_QUESTIONS_REQUEST,
  GET_MEDICAL_SURVEY_QUESTIONS_SUCCESS,
  GET_MEDICAL_SURVEY_QUESTIONS_FAILURE,
} from '../actions/medicalSurveyQuestions'

export const defaultState = {
  loading: false,
  medicalSurveyQuestions: [],
  currentMedicalSurveyQuestion: null,
}

export default (state = defaultState, action) => {
  const newState = Object.assign({}, state)

  switch (action.type) {

  	case SET_CURRENT_MEDICAL_SURVEY_QUESTION:
      newState.currentMedicalSurveyQuestion = action.payload
      return newState

    case CLEAR_CURRENT_MEDICAL_SURVEY_QUESTION:
      newState.currentMedicalSurveyQuestion = null
      return newState

    case CLEAR_MEDICAL_SURVEY_QUESTIONS:
      newState.medicalSurveyQuestions = []
      return newState

    case GET_MEDICAL_SURVEY_QUESTIONS_REQUEST:
      newState.loading = true
      return newState

    case GET_MEDICAL_SURVEY_QUESTIONS_SUCCESS:
      newState.loading = false
      newState.medicalSurveyQuestions = action.payload
      return newState

    case GET_MEDICAL_SURVEY_QUESTIONS_FAILURE:
      newState.loading = false
      return newState

    default:
      return state
      
  }
};
