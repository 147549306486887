import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import { bindActionCreators } from 'redux'
import AWS from 'aws-sdk'
import moment from 'moment'

import { setCurrentView } from '../actions/views'
import { getUser, getUserProfilePicture } from '../actions/users'
import { getAppointments } from '../actions/appointments'
import { getInsurancePolicies } from '../actions/insurancePolicies'
import { clearCurrentConversation, getConversationsForUser, getConversationsForUserTotalPages } from '../actions/conversations'
import { clearMessages } from '../actions/messages'

import AppointmentCard from './AppointmentCard'
import InsurancePolicyCard from './InsurancePolicyCard'
import MedicalSurveyCollectionCard from './MedicalSurveyCollectionCard'

import loadingAnimation from '../images/loading-animation.gif'

let formatPhoneNumber = (str) => {
  let cleaned = ('' + str).replace(/\D/g, '')
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3]
  }

  return null
}

function getPresignedUrl(currentUser) {
  const s3 = new AWS.S3();
  s3.config.update({credentials: AWS.config.credentials})
  var imageIdentifier = /[^/]*$/.exec(currentUser.image_url)[0]
  let fileKey = `users/${currentUser.id}/profile-pictures/${imageIdentifier}`
  const params = {
    Bucket: process.env.REACT_APP_AWS_S3_BUCKET,
    Key: fileKey,
    Expires: 60
  };

  const presignedUrl = s3.getSignedUrl("getObject", params);
  return presignedUrl
}

class PatientProfile extends Component {
  constructor(props) {
    super(props)

    this.state = {
      avatarImage: null,
    }

    this.showUserConversations = this.showUserConversations.bind(this)
    this.showNewConversation = this.showNewConversation.bind(this)
  }
  componentDidUpdate(prevProps) {
    const { currentUser, currentUserId } = this.props

    if (currentUser !== prevProps.currentUser) {
      this.componentDidMount()
    }
  }
  componentDidMount() {
    const { getUserProfilePicture, currentUserId, currentUser } = this.props

    if (currentUser) {
      this.setState({
        avatarImage: getPresignedUrl(currentUser)
      })
    }
  }
  showUserConversations() {
    const { currentEmployee, currentUserId, currentPage, setCurrentView, getConversationsForUser, getConversationsForUserTotalPages } = this.props

    getConversationsForUserTotalPages(currentEmployee.id, currentUserId, currentPage).then(() => {
      getConversationsForUser(currentEmployee.id, currentUserId, currentPage).then(() => {
        setCurrentView("inbox")
      })  
    })
  }
  showNewConversation() {
    const { setCurrentView, clearCurrentConversation, clearMessages } = this.props

    clearCurrentConversation()
    clearMessages()
    setCurrentView("conversation")
  }
  render() {
    const { loading, currentUser, userImageUrls, appointments, insurancePolicies, medicalSurveyCollection, medicalSurveyQuestions } = this.props

    return (
      <Row>
        {
          currentUser ?
            <Col xs={{ span: 10, offset: 2 }}>
              <Row>
                <Col xs={12}>
                  <h1 className="patient_profile__title_label">Patient Profile</h1>
                </Col>
              </Row>
              <Row>
                <Col xs={{ span: 2, offset: 0 }} sm={{ span: 2, offset: 0 }} md={{ span: 2, offset: 1 }} lg={{ span: 2, offset: 1 }} >
                  <div className="patient_profile__avatar_container">
                    { (currentUser && currentUser.image_url) ?
                      <img src={ (currentUser && currentUser.image_url && this.state.avatarImage) ? this.state.avatarImage : "" } className="patient_profile__avatar_image" alt="patient avatar"/>
                      :
                      <div className="patient_profile__avatar_container_text">No Image Uploaded</div>
                    }
                  </div>
                </Col>
                <Col xs={{ span: 4, offset: 0 }} sm={{ span: 4, offset: 0 }} md={{ span: 4, offset: 0 }} lg={{ span: 4, offset: 0 }} >
                  <div>
                    <h3 className="patient_profile__user_name_label">{ currentUser ? currentUser.first_name : '' } { currentUser ? currentUser.last_name : '' }</h3>
                    <h4 className="patient_profile__user_info"><span className="patient_profile__user_info_label">Preferred Name:</span> { currentUser.preferred_name ? currentUser.preferred_name : 'N/A' }</h4>
                    <h4 className="patient_profile__user_info"><span className="patient_profile__user_info_label">Preferred Pronouns:</span> { currentUser.preferred_pronouns ? currentUser.preferred_pronouns : '' }</h4>
                    <h4 className="patient_profile__user_info"><span className="patient_profile__user_info_label">Birthdate:</span> { currentUser.date_of_birth ? moment(currentUser.date_of_birth).format("M/DD/YYYY") : '' }</h4>
                    <h4 className="patient_profile__user_info"><span className="patient_profile__user_info_label">Email:</span> { currentUser.email ? currentUser.email : '' }</h4>
                    <h4 className="patient_profile__user_info"><span className="patient_profile__user_info_label">Phone:</span> { currentUser.phone ? formatPhoneNumber(currentUser.phone) : '' }</h4>
                    <h4 className="patient_profile__user_info"><span className="patient_profile__user_info_label">Open Dental ID:</span> { currentUser.ehr_identifier ? currentUser.ehr_identifier : '' }</h4>
                  </div>
                </Col>
                <Col xs={{ span: 5, offset: 0 }} sm={{ span: 5, offset: 0 }} md={{ span: 5, offset: 0 }} lg={{ span: 5, offset: 0 }} >
                  <div className="patient_profile__send_button_container">
                    <button className="patient_profile__button" onClick={this.showUserConversations}>View Messages</button>
                  </div>
                  <div className="patient_profile__send_button_container">
                    <button className="patient_profile__button" onClick={this.showNewConversation}>New Message</button>
                  </div>
                  <div className="patient_profile__send_button_container">
                    <button className="patient_profile__button">Send Appointment</button>
                  </div>
                </Col>
              </Row>
              <Row className="patient_profile__insurance_container">
                <Col xs={{ span: 6, offset: 0 }} sm={{ span: 6, offset: 0 }} md={{ span: 6, offset: 0 }} lg={{ span: 5, offset: 1 }} >
                  <h1 className="patient_profile__insurance_header">Insurance</h1>
                  <div className="patient_profile__insurance_main_container">
                    {
                      insurancePolicies.map((insurancePolicy, index) => {
                        return (
                          <InsurancePolicyCard key={index} insurancePolicy={insurancePolicy} />
                        )
                      })
                    }
                  </div>
                </Col>
                <Col xs={{ span: 6, offset: 0 }} sm={{ span: 6, offset: 0 }} md={{ span: 6, offset: 0 }} lg={{ span: 5, offset: 0 }}>
                  <h1 className="patient_profile__medical_history_header">Medical History</h1>
                  {
                    medicalSurveyCollection ?
                      (
                        <MedicalSurveyCollectionCard  
                          medicalSurveyCollection={medicalSurveyCollection} 
                          medicalSurveyQuestions={medicalSurveyQuestions}
                        />
                      )
                      :''
                  }
                </Col>
              </Row>
              <Row className="patient_profile__bottom_container">
                <Col className="patient_profile__appointment_container" xs={{ span: 6, offset: 0 }} sm={{ span: 6, offset: 0 }} md={{ span: 6, offset: 0 }} lg={{ span: 5, offset: 1 }} >
                  <h1 className="patient_profile__header">Appointments</h1>
                  {
                    appointments.map((appointment, index) => {
                      return (
                        <AppointmentCard key={index} appointment={appointment} />
                      )
                    })
                  }
                </Col>   
              </Row>
            </Col>
          :
          <Col xs={{ span: 10, offset: 2 }}>
            {
              loading ?
              <img className="patient_profile__loading_animation" src={loadingAnimation} />
              :
              ''
            }
            <p className="patient_profile__empty_text">Please select a user from the search...</p>
          </Col>
        }
      </Row>
    )
  }
}

export default connect(
  state => ({
    currentEmployee: state.auth.currentEmployee,
    loading: state.users.loadingCurrentUser,
    currentUserId: state.users.currentUserId,
    currentUser: state.users.currentUser,
    userImageUrls: state.users.userImageUrls,
    appointments: state.appointments.appointments,
    insurancePolicies: state.insurancePolicies.insurancePolicies,
    medicalSurveyCollection: state.medicalSurveyCollections.medicalSurveyCollection,
    medicalSurveyQuestions: state.medicalSurveyQuestions.medicalSurveyQuestions,
    currentPage: state.conversations.currentPage,
  }),
  dispatch => bindActionCreators({
    getUser,
    getUserProfilePicture,
    getAppointments,
    setCurrentView,
    getInsurancePolicies,
    getConversationsForUser, 
    getConversationsForUserTotalPages,
    clearCurrentConversation,
    clearMessages,
  }, dispatch),
)(PatientProfile)