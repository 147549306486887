import React, { Component, useRef } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Container } from 'react-bootstrap'
import { bindActionCreators } from 'redux'
import _uniqBy from 'lodash/uniqBy'
import _map from 'lodash/map'
import _capitalize from 'lodash/capitalize'
import _find from 'lodash/find'
import _filter from 'lodash/filter'
import _forEach from 'lodash/forEach'
import _isEmpty from 'lodash/isEmpty'
import _findIndex from 'lodash/findIndex'
import { withRouter } from 'react-router'
import classNames from 'classnames'
import { SingleDatePicker } from 'react-dates'


import { Formik, Field, Form } from 'formik'
import moment from 'moment'

import EmployeeProduction from './EmployeeProduction'
import PracticeProduction from './PracticeProduction'
import leftArrow from '../images/left-arrow.png'
import rightArrow from '../images/right-arrow.png'

import loadingAnimation from '../images/loading-animation.gif'
import refreshIcon from '../images/refresh-icon.png'

import { logoutEmployee, clearCurrentEmployee } from '../actions/auth'
import { setCurrentView } from '../actions/views'

import { getPractices, setCurrentPractice } from '../actions/practices'
import { clearOperatories, getOperatories } from '../actions/operatories'
import { getAppointmentTypes } from '../actions/appointmentTypes'
import { clearOperatorySchedules, getOperatorySchedules } from '../actions/operatorySchedules'
import { clearOperatoryScheduleRules, createOperatoryScheduleRule, getOperatoryScheduleRules } from '../actions/operatoryScheduleRules'
import { clearScheduleGeneratorDateRange, setScheduleGeneratorDateRange } from '../actions/scheduleGenerators'

var opFormRefs = []

const appointmentIndentifiers = [
  "new-patient-cleaning",
  "cleaning",
  "new-patient-emergency",
  "emergency",
  "new-patient-invisalign-consult",
  "invisalign-consult"
]

const defaultInitialValues = {
  "new-patient-cleaning": 100,
  "cleaning": 100,
  "new-patient-emergency": 100,
  "emergency": 100,
  "new-patient-invisalign-consult": 100,
  "invisalign-consult": 100,
}

class ScheduleGenerator extends Component {
  constructor(props) {
  	super(props)

    this.state = {
      onRepeatLoading: false,
      focused: null,
      currentOpScheduleRules: [],
      totalOpScheduleRules: [],
      initialValuesDict: {},
      initialValuesArr: [],
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.onDateChange = this.onDateChange.bind(this)
    this.onFocusChange = this.onFocusChange.bind(this)
    this.onPracticeChange = this.onPracticeChange.bind(this)
    this.onRepeatSumbit = this.onRepeatSumbit.bind(this)
  }
  componentDidMount() {
    const { 
      setCurrentView,
      logoutEmployee, 
      clearCurrentEmployee,
      currentEmployee, 
      setCurrentPractice, 
      currentPractice,
      clearOperatories,
      getOperatories, 
      getAppointmentTypes, 
      clearScheduleGeneratorDateRange, 
      setScheduleGeneratorDateRange, 
      getPractices, 
      clearOperatoryScheduleRules, 
      getOperatoryScheduleRules,
      clearOperatorySchedules, 
      getOperatorySchedules,
    } = this.props

    if (currentEmployee) {

      clearScheduleGeneratorDateRange()
      var now = moment()
      setScheduleGeneratorDateRange(now)

      getPractices().then((practicesResponse) => {
        getAppointmentTypes().then((appointmentTypesResponse) => {
          var appointmentTypesForForm
          appointmentTypesForForm = _filter(appointmentTypesResponse, (appointmentType) => {
            return appointmentIndentifiers.includes(appointmentType.appointment_type_identifier) === true
          })

          if (practicesResponse) {
            let firstPractice = practicesResponse[0]

            if (currentPractice === null) {
              setCurrentPractice(firstPractice)
            }
            

            this.setState({ initialValuesDict: {} })

            clearOperatories()
            getOperatories(currentEmployee.id, firstPractice.id).then((operatoryResponse) => {

              clearOperatoryScheduleRules()
              clearOperatorySchedules()

              getOperatorySchedules(currentEmployee.id, firstPractice.id, now.toISOString()).then((operatoryScheduleResponse) => {

                let getOperatoryScheduleRulesPromise = _map(operatoryResponse, (operatory) => {
                  return getOperatoryScheduleRules(currentEmployee.id, operatory.id, now.toISOString())
                })

                Promise.all(getOperatoryScheduleRulesPromise).then((values) => {
                  // console.log("values.flat()")
                  // console.log(values.flat())
                  // console.log(values.flat().length)

                  this.setState({ totalOpScheduleRules: values.flat() })
                }).then(() => {
                  // console.log(this.state.totalOpScheduleRules)

                  var opDict = {}
                  _forEach(operatoryResponse, (operatory) => {
                    var loadedInitialValuesObj = new Object()
                    let opScheduleRulesForOperatory = _filter(this.state.totalOpScheduleRules, (opSchedRule) => {
                      return opSchedRule.operatory_id === operatory.id
                    })

                    // console.log(operatory.id)
                    // console.log(opScheduleRulesForOperatory)

                    _forEach(opScheduleRulesForOperatory, (opSchedRule) => {
                      var appointmentTypeForOpScheduleRule = _filter(appointmentTypesForForm, (appointmentType) => {
                        return opSchedRule.appointment_type_id === appointmentType.id
                      })[0]

                      if (appointmentTypeForOpScheduleRule) {
                        let appointmentTypeIdentifier = appointmentTypeForOpScheduleRule.appointment_type_identifier
                        loadedInitialValuesObj[appointmentTypeIdentifier] = opSchedRule.limit
                      }
                    })

                    // console.log(opDict)
                    opDict[operatory.id] = loadedInitialValuesObj
                    this.setState({ initialValuesDict: opDict })
                    // console.log(operatory.id)
                  })
                })
              })
            })
          }
        })
      })
    } else {
      logoutEmployee()
      clearCurrentEmployee()
      this.props.history.push("/login")
    }
  }
  onPracticeChange(values) {
    const { 
      appointmentTypes,
      currentEmployee, 
      setCurrentPractice, 
      currentPractice, 
      currentPractices, 
      clearOperatories,
      getOperatories, 
      getAppointmentTypes, 
      clearScheduleGeneratorDateRange, 
      setScheduleGeneratorDateRange, 
      scheduleGeneratorDateRange, 
      clearOperatoryScheduleRules,
      clearOperatorySchedules,
      getOperatorySchedules,
      getOperatoryScheduleRules,
    } = this.props


    if (currentEmployee) {
      var appointmentTypesForForm
      appointmentTypesForForm = _filter(appointmentTypes, (appointmentType) => {
        return appointmentIndentifiers.includes(appointmentType.appointment_type_identifier) === true
      })

      let matchingId = parseInt(values.practiceId)
      
      // debugger
      var newCurrentPractice = _find(currentPractices, (practice) => { return practice.id === matchingId })

      // debugger
      if (newCurrentPractice) {
        setCurrentPractice(newCurrentPractice)


        this.setState({ initialValuesDict: {} })

        clearOperatories()
        getOperatories(currentEmployee.id, newCurrentPractice.id).then((operatoryResponse) => {

          clearOperatoryScheduleRules()
          clearOperatorySchedules()

          getOperatorySchedules(currentEmployee.id, newCurrentPractice.id, scheduleGeneratorDateRange.startDate.toISOString()).then((operatoryScheduleResponse) => {

            let getOperatoryScheduleRulesPromise = _map(operatoryResponse, (operatory) => {
              return getOperatoryScheduleRules(currentEmployee.id, operatory.id, scheduleGeneratorDateRange.startDate.toISOString())
            })

            Promise.all(getOperatoryScheduleRulesPromise).then((values) => {
              this.setState({ totalOpScheduleRules: values.flat() })
            }).then(() => {
              // console.log(this.state.totalOpScheduleRules)

              var opDict = {}
              _forEach(operatoryResponse, (operatory) => {
                var loadedInitialValuesObj = new Object()

                let opScheduleRulesForOperatory = _filter(this.state.totalOpScheduleRules, (opSchedRule) => {
                  return opSchedRule.operatory_id === operatory.id
                })

                // console.log(operatory.id)
                // console.log(opScheduleRulesForOperatory)

                _forEach(opScheduleRulesForOperatory, (opSchedRule) => {
                  var appointmentTypeForOpScheduleRule = _filter(appointmentTypesForForm, (appointmentType) => {
                    return opSchedRule.appointment_type_id === appointmentType.id
                  })[0]

                  if (appointmentTypeForOpScheduleRule) {
                    let appointmentTypeIdentifier = appointmentTypeForOpScheduleRule.appointment_type_identifier
                    loadedInitialValuesObj[appointmentTypeIdentifier] = opSchedRule.limit
                  }
                })
                
                opDict[operatory.id] = loadedInitialValuesObj
                this.setState({ initialValuesDict: opDict })
              })
            })
          })
        })
      }

      // console.log(newCurrentPractice)
      // let ops = getOperatories(currentEmployee.id, newCurrentPractice.id)
      // let appointmentTypes = getAppointmentTypes()

      // clearScheduleGeneratorDateRange()

      // var now = moment()
      // setScheduleGeneratorDateRange(now)



      // clearOperatories()
      // let ops = getOperatories(currentEmployee.id, newCurrentPractice.id).then((response) => {

      //   clearOperatoryScheduleRules()
      //   for (var i = 0; i < response.length; i++) {
      //     getOperatoryScheduleRules(currentEmployee.id, response[i].id, scheduleGeneratorDateRange.startDate.toISOString())
      //   }
      // })
    }
  }
  handleSubmit(values, operatoryId) {
    const { 
      currentPractice,
      currentEmployee, 
      appointmentTypes, 
      createOperatoryScheduleRul, 
      scheduleGeneratorDateRange, 
      currentOperatories, 
      createOperatoryScheduleRule,
      getOperatoryScheduleRules,
      currentOperatoryScheduleRules,
      clearOperatories,
      getOperatories,
      clearOperatoryScheduleRules,
      clearOperatorySchedules,
      getOperatorySchedules,
    } = this.props

    if (currentEmployee) {
      var appointmentTypesForForm; 
      appointmentTypesForForm = _filter(appointmentTypes, (appointmentType) => {
        return appointmentIndentifiers.includes(appointmentType.appointment_type_identifier) === true
      })

      let createOpScheduleRulesPromises = _map(appointmentTypesForForm, (appointmentTypeResponse) => {

        var matchingOp = _filter(currentOperatories, (operatory) => {
              return operatory.id === operatoryId
        })[0]

        let appointmentTypeIdentifier = appointmentTypeResponse.appointment_type_identifier

        let dateStr = scheduleGeneratorDateRange.startDate.format("YYYYMMDD")
        let ehrId = dateStr + `L${matchingOp.ehr_identifier}`
        let formattedOperatorySchedule = {
          ehr_identifier: ehrId,
          operatory_id: operatoryId,
          appointment_type_identifier: appointmentTypeIdentifier,
          limit: parseInt(values[appointmentTypeIdentifier])
        }

        return createOperatoryScheduleRule(currentEmployee.id, formattedOperatorySchedule)
      })


      Promise.all(createOpScheduleRulesPromises).then(() => {

        this.setState({ initialValuesDict: {} })
        clearOperatories()
        getOperatories(currentEmployee.id, currentPractice.id).then((operatoryResponse) => {

          clearOperatoryScheduleRules()
          clearOperatorySchedules()

          getOperatorySchedules(currentEmployee.id, currentPractice.id, scheduleGeneratorDateRange.startDate.toISOString()).then((operatoryScheduleResponse) => {

            let getOperatoryScheduleRulesPromise = _map(operatoryResponse, (operatory) => {
              return getOperatoryScheduleRules(currentEmployee.id, operatory.id, scheduleGeneratorDateRange.startDate.toISOString())
            })

            this.setState({ totalOpScheduleRules: {} })
            Promise.all(getOperatoryScheduleRulesPromise).then((opScheduleRuleResponseValues) => {
              this.setState({ totalOpScheduleRules: opScheduleRuleResponseValues.flat() })
            }).then(() => {
              // console.log(this.state.totalOpScheduleRules)

              var opDict = {}
              _forEach(operatoryResponse, (operatory) => {
                var loadedInitialValuesObj = new Object()
                let opScheduleRulesForOperatory = _filter(this.state.totalOpScheduleRules, (opSchedRule) => {
                  return opSchedRule.operatory_id === operatory.id
                })

                // console.log(operatory.id)
                // console.log(opScheduleRulesForOperatory)

                _forEach(opScheduleRulesForOperatory, (opSchedRule) => {
                  var appointmentTypeForOpScheduleRule = _filter(appointmentTypesForForm, (appointmentType) => {
                    return opSchedRule.appointment_type_id === appointmentType.id
                  })[0]

                  if (appointmentTypeForOpScheduleRule) {
                    let appointmentTypeIdentifier = appointmentTypeForOpScheduleRule.appointment_type_identifier
                    loadedInitialValuesObj[appointmentTypeIdentifier] = opSchedRule.limit
                  }
                })
                
                // console.log(operatory.id)
                // console.log(loadedInitialValuesObj)
                opDict[operatory.id] = loadedInitialValuesObj
                this.setState({ initialValuesDict: opDict })
              })
            })
          })
        })
      }).then(() => {

      })


      // for (var i = 0; i < appointmentTypesForForm.length; i++) {
      //   let appointmentTypeIdentifier = appointmentTypesForForm[i].appointment_type_identifier

      //   var matchingOp = _filter(currentOperatories, (operatory) => {
      //         return operatory.id === operatoryId
      //   })[0]

      //   let dateStr = scheduleGeneratorDateRange.startDate.format("YYYYMMDD")
      //   let ehrId = dateStr + `L${matchingOp.ehr_identifier}`
      //   let formattedOperatorySchedule = {
      //     ehr_identifier: ehrId,
      //     operatory_id: operatoryId,
      //     appointment_type_identifier: appointmentTypeIdentifier,
      //     limit: parseInt(values[appointmentTypeIdentifier])
      //   }

      //   createOperatoryScheduleRule(currentEmployee.id, formattedOperatorySchedule).then(() => {
      //     this.setState({ initialValuesDict: {} })

      //     clearOperatories()
      //     getOperatories(currentEmployee.id, currentPractice.id).then((operatoryResponse) => {

      //       clearOperatoryScheduleRules()
      //       clearOperatorySchedules()

      //       getOperatorySchedules(currentEmployee.id, currentPractice.id, scheduleGeneratorDateRange.startDate.toISOString()).then((operatoryScheduleResponse) => {

      //         let getOperatoryScheduleRulesPromise = _map(operatoryResponse, (operatory) => {
      //           return getOperatoryScheduleRules(currentEmployee.id, operatory.id, scheduleGeneratorDateRange.startDate.toISOString()).then((opScheduleRuleResponse) => {
      //             console.log(currentEmployee.id)
      //             console.log(operatory.id)
      //             console.log(scheduleGeneratorDateRange.startDate.toISOString())
      //             // var currentOperatoryScheduleRulesTemp
      //             // for (var y = 0; y < opScheduleRuleResponse.length; y++) {
      //             //   currentOperatoryScheduleRulesTemp = this.state.totalOpScheduleRules
      //             //   let opSchedRuleTemp = opScheduleRuleResponse[y]
      //             //   var index = _findIndex(currentOperatoryScheduleRulesTemp, { id: opSchedRuleTemp.id })

      //             //   currentOperatoryScheduleRulesTemp.splice(index, 1, opSchedRuleTemp)
      //             // }

      //             // this.setState({ totalOpScheduleRules: currentOperatoryScheduleRulesTemp })
      //           })
      //         })

      //         Promise.all(getOperatoryScheduleRulesPromise).then((opScheduleRuleResponseValues) => {

      //           this.setState({ totalOpScheduleRules: opScheduleRuleResponseValues.flat() })
      //         }).then(() => {
      //           // console.log(this.state.totalOpScheduleRules)

      //           var opDict = {}
      //           _forEach(operatoryResponse, (operatory) => {
      //             var loadedInitialValuesObj = new Object()
      //             let opScheduleRulesForOperatory = _filter(this.state.totalOpScheduleRules, (opSchedRule) => {
      //               return opSchedRule.operatory_id === operatory.id
      //             })

      //             // console.log(operatory.id)
      //             // console.log(opScheduleRulesForOperatory)

      //             _forEach(opScheduleRulesForOperatory, (opSchedRule) => {
      //               var appointmentTypeForOpScheduleRule = _filter(appointmentTypesForForm, (appointmentType) => {
      //                 return opSchedRule.appointment_type_id === appointmentType.id
      //               })[0]

      //               if (appointmentTypeForOpScheduleRule) {
      //                 let appointmentTypeIdentifier = appointmentTypeForOpScheduleRule.appointment_type_identifier
      //                 loadedInitialValuesObj[appointmentTypeIdentifier] = opSchedRule.limit
      //               }
      //             })
                  
      //             console.log(operatory.id)
      //             console.log(loadedInitialValuesObj)
      //             opDict[operatory.id] = loadedInitialValuesObj
      //             this.setState({ initialValuesDict: opDict })
      //           })
      //         })
      //       })
      //     })
      //   })
      // }
    }
  }
  onRepeatSumbit(values) {
    const { 
      currentEmployee, 
      appointmentTypes, 
      createOperatoryScheduleRul, 
      scheduleGeneratorDateRange, 
      currentOperatories, 
      createOperatoryScheduleRule 
    } = this.props

    if (currentEmployee) {
      var appointmentTypesForForm
      appointmentTypesForForm = _filter(appointmentTypes, (appointmentType) => {
        return appointmentIndentifiers.includes(appointmentType.appointment_type_identifier) === true
      })

      var createPromises = []
      for (var x = 1; x < (parseInt(values.repeatCount) + 1); x++) {
        // console.log("week counter")
        // console.log(x)

        let startDate = scheduleGeneratorDateRange.startDate
        var newDate = moment(startDate).add(x * 7, 'days')
        var newDateStart = newDate.clone().startOf('day')

        for (var i = 0; i < appointmentTypesForForm.length; i++) {
          let appointmentTypeIdentifier = appointmentTypesForForm[i].appointment_type_identifier

          this.setState({ onRepeatLoading: true })
          createPromises = _map(currentOperatories, (currentOp, index) => {
            let matchingOpFormRef = opFormRefs[index]
            let matchingLimit = matchingOpFormRef.current.values[appointmentTypeIdentifier]

            let dateStr = newDateStart.format("YYYYMMDD")
            let ehrId = dateStr + `L${currentOp.ehr_identifier}`
            let formattedOperatorySchedule = {
              ehr_identifier: ehrId,
              operatory_id: currentOp.id,
              appointment_type_identifier: appointmentTypeIdentifier,
              limit: parseInt(matchingLimit)
            }
            return createOperatoryScheduleRule(currentEmployee.id, formattedOperatorySchedule)
          })
        }
      }

      Promise.all(createPromises).then((response) => {
        this.setState({ onRepeatLoading: false })
      })
      .catch(() => {
        this.setState({ onRepeatLoading: false })
      })
    }
  }
  onDateChange(date) {
    const { 
      appointmentTypes,
      currentEmployee,
      currentOperatories,
      currentPractice,
      clearOperatories,
      getOperatories, 
      clearOperatoryScheduleRules, 
      getOperatoryScheduleRules, 
      setScheduleGeneratorDateRange,
      clearOperatorySchedules, 
      getOperatorySchedules,
      currentOperatorySchedules,
      scheduleGeneratorDateRange,
    } = this.props

    setScheduleGeneratorDateRange(date)

    // clearCurrentEmployeeProductionDateRange()
    // setCurrentEmployeeProductionDateRange({startDate, endDate})

    var appointmentTypesForForm
    appointmentTypesForForm = _filter(appointmentTypes, (appointmentType) => {
      return appointmentIndentifiers.includes(appointmentType.appointment_type_identifier) === true
    })

    this.setState({ initialValuesDict: {} })

    clearOperatories()
    getOperatories(currentEmployee.id, currentPractice.id).then((operatoryResponse) => {

      clearOperatoryScheduleRules()
      clearOperatorySchedules()

      getOperatorySchedules(currentEmployee.id, currentPractice.id, date.toISOString()).then((operatoryScheduleResponse) => {

        let getOperatoryScheduleRulesPromise = _map(operatoryResponse, (operatory) => {
          return getOperatoryScheduleRules(currentEmployee.id, operatory.id, date.toISOString())
        })

        Promise.all(getOperatoryScheduleRulesPromise).then((values) => {
          this.setState({ totalOpScheduleRules: values.flat() })
        }).then(() => {

          var opDict = {}
          _forEach(operatoryResponse, (operatory) => {
            var loadedInitialValuesObj = new Object()
            let opScheduleRulesForOperatory = _filter(this.state.totalOpScheduleRules, (opSchedRule) => {
              return opSchedRule.operatory_id === operatory.id
            })

            _forEach(opScheduleRulesForOperatory, (opSchedRule) => {
              var appointmentTypeForOpScheduleRule = _filter(appointmentTypesForForm, (appointmentType) => {
                return opSchedRule.appointment_type_id === appointmentType.id
              })[0]

              if (appointmentTypeForOpScheduleRule) {
                let appointmentTypeIdentifier = appointmentTypeForOpScheduleRule.appointment_type_identifier
                loadedInitialValuesObj[appointmentTypeIdentifier] = opSchedRule.limit
              }
            })
            
            opDict[operatory.id] = loadedInitialValuesObj
            this.setState({ initialValuesDict: opDict })
          })
        })
      })
    })
  }
  onFocusChange(focused) {
    this.setState({focused});
  }
  // shouldComponentUpdate(nextProps, nextState) {
  //   return this.state.totalOpScheduleRules.length !== nextState.totalOpScheduleRules.length
  // }
  render() {
    const { operatorySchedulesLoading, operatoryScheduleRulesLoading, currentEmployee, currentPractice, currentOperatorySchedules, currentOperatoryScheduleRules, appointmentTypes, employeeProductionDateRange, currentOperatories, scheduleGeneratorDateRange, currentPractices } = this.props

    // const employeeTabClasses = classNames({
    //     'production__tab_link': true,
    //     'production__tab_link_selected': this.state.currentView === "employeeProduction",
    // })

    // const practiceTabClasses = classNames({
    //     'production__tab_link': true,
    //     'production__tab_link_selected': this.state.currentView === "practiceProduction",
    // })

    // let dateRange = { startDate: moment().clone().startOf('month'), endDate: moment().clone().endOf('month')}
    var now = moment()
    var appointmentTypesForForm; 
    appointmentTypesForForm = _filter(appointmentTypes, (appointmentType) => {
      return appointmentIndentifiers.includes(appointmentType.appointment_type_identifier) === true
    })
    
    var appointmentTypesHeaders;
    appointmentTypesHeaders = _map(appointmentTypesForForm.sort((a, b) => a.rank - b.rank), (appoointmentType, index) => {
      return (
        <div 
         key={index}
         className="schedule_generator__appointment_type_header_container"
        >
          <h4 className="schedule_generator__appointment_type_header">{appoointmentType.appointment_type_identifier.includes("new-patient") === false ? "Existing" : ""} {appoointmentType.appointment_type_identifier.split('-').map((word) => { return word[0].toUpperCase() + word.substring(1); }).join(" ").replace("New Patient", "NP")}</h4>
        </div>
      )
    })

    var fieldsForAppointmentType
    fieldsForAppointmentType = _map(appointmentTypesForForm.sort((a, b) => a.rank - b.rank), (appointmentType, index) => {
      return (
        <div key={index} className="schedule_generator__form_select_container">
          <Field className="schedule_generator__form_select" as="select" name={`${appointmentType.appointment_type_identifier}`}>
            <option value="0">0</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="100">∞</option>
          </Field>
        </div>
      )
    })

    var opForms = null
    opFormRefs = []
    
    // console.log("LOGGING")
    // console.log("LOGGING")
    // console.log(appointmentTypes.length)
    // console.log(currentOperatorySchedules.length)
    // console.log(currentOperatoryScheduleRules.length)
    // console.log(currentOperatoryScheduleRules)
    // console.log(currentOperatories.length)
    // console.log(Object.keys(this.state.initialValuesDict).length)
    // console.log(appointmentTypesForForm.length * currentOperatories.length)
    if (
      appointmentTypes.length > 0 
      && currentOperatories.length > 0 
      && currentOperatorySchedules.length > 0 
      && currentOperatoryScheduleRules.length > 0 
      && Object.keys(this.state.initialValuesDict).length === currentOperatories.length 
      && this.state.totalOpScheduleRules.length === (appointmentTypesForForm.length * currentOperatories.length)
    ) {
      opForms = _map(currentOperatories, (operatory, index) => {

        var formRef = React.createRef();
        opFormRefs.push(formRef)

        const initialValues = this.state.initialValuesDict[operatory.id]

        return (
          <Formik
            key={index}
            isInitialValid={true}
            initialValues={initialValues ? initialValues : defaultInitialValues}
            onSubmit={(values) => this.handleSubmit(values, operatory.id)}
            innerRef={formRef}
          >
            {({ setFieldValue, values, isSubmitting, isValid, dirty }) => (
               <Form className="schedule_generator__form">
                  <h2 className="schedule_generator__form_op_header">{operatory.ehr_name}</h2>

                  {fieldsForAppointmentType}
                  <div className="schedule_generator__form_save_button_container">
                    <button className="schedule_generator__form_save_button" type="submit">Save</button>
                  </div>
              </Form>
            )}
           </Formik>
        )
      })
    }

    return (
      <Container fluid>
        <Row>
          <Col xs={{ span: 2, offset: 0 }} sm={{ span: 12, offset: 0 }} md={{ span: 9, offset: 3 }} lg={{ span: 3, offset: 2 }} className="schedule_generator__header_form_container">
          	<h1 className="schedule_generator__header">Schedule Maker</h1>
            <div className="schedule_generator__practice_form_container">
              <Formik
                isInitialValid={false}
                initialValues={currentPractice ? { practice_id: currentPractice.id } : {}}
                onSubmit={this.onPracticeChange}
              >
                {({ handleSubmit, setFieldValue, values, isSubmitting, isValid, dirty }) => (
                     <Form>
                      <h4 className="schedule_generator__practice_header"></h4>
                      <button className="schedule_generator__form_practice_update_button" type="submit">
                        <img className="schedule_generator__form_practice_update_button_icon" src={refreshIcon} />
                      </button>
                      <Field className="schedule_generator__practice_form_select" as="select" name="practiceId">
                      {
                        _map(currentPractices, (practice) => {
                          // let practiceId = practice.id
                          // console.log(practiceId)
                          return (
                            <option value={practice.id}>{practice.name}</option>
                          )
                        })
                      }
                      </Field>
                    </Form>
                  )}
               </Formik>
            </div>
          	<div className="schedule_generator__legend">
              {appointmentTypesHeaders}
          	</div>
          </Col>
          <Col xs={{ span: 2, offset: 0 }} sm={{ span: 12, offset: 0 }} md={{ span: 9, offset: 3 }} lg={{ span: 6, offset: 0 }} className="schedule_generator__form_container">
          <div className="schedule_generator__date_picker">
            <SingleDatePicker
              numberOfMonths={1}
              date={scheduleGeneratorDateRange.endDate} // momentPropTypes.momentObj or null
              onDateChange={this.onDateChange} // PropTypes.func.isRequired
              focused={this.state.focused} // PropTypes.bool
              onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
            />
            <p className="schedule_generator__date_picker_title">{ scheduleGeneratorDateRange.startDate.format('dddd') }</p>

            <div className="schedule_generator__date_copy_form">
              <Formik
                isInitialValid={true}
                initialValues={{ repeatCount: 1 }}
                onSubmit={this.onRepeatSumbit}
              >
                {({ handleSubmit, setFieldValue, values, isSubmitting, isValid, dirty }) => (
                     <Form>
                      <p className="schedule_generator__date_copy_text">
                        Copy to { scheduleGeneratorDateRange.startDate.format('dddd') }s for the next...
                      </p>
                      <Field className="schedule_generator__repeat_select" as="select" name="repeatCount">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                      </Field>
                      <p className="schedule_generator__date_copy_text">weeks</p>
                      <button className="schedule_generator__date_copy_button">Copy</button>
                    </Form>
                  )}
               </Formik>
            </div>
          </div>

            <div className="schedule_generator__op_form_container">
              <div className="schedule_generator__op_form_scroll_container">
                {
                  operatoryScheduleRulesLoading === true || this.state.onRepeatLoading === true ?
                  <img className="schedule_generator__loading_animation" src={loadingAnimation} />
                  :
                  ''
                }
                { opForms }
              </div>
            </div>

          </Col>
        </Row>
      </Container>
    )
  }
}

export default withRouter(connect(
  state => ({
    operatorySchedulesLoading: state.operatorySchedules.loading,
    operatoryScheduleRulesLoading: state.operatoryScheduleRules.loading,
    appointmentTypes: state.appointmentTypes.appointmentTypes,
    currentOperatories: state.operatories.operatories,
    currentPractices: state.practices.practices,
    currentPractice: state.practices.currentPractice,
    currentEmployee: state.auth.currentEmployee,
    scheduleGeneratorDateRange: state.scheduleGenerators.scheduleGeneratorDateRange,
    currentOperatorySchedules: state.operatorySchedules.operatorySchedules,
    currentOperatoryScheduleRules: state.operatoryScheduleRules.operatoryScheduleRules,
  }),
  dispatch => bindActionCreators({
    setCurrentView,
    logoutEmployee,
    clearCurrentEmployee,
    getPractices,
    setCurrentPractice,
    clearOperatories,
    getOperatories,
    clearScheduleGeneratorDateRange, 
    setScheduleGeneratorDateRange,
    getAppointmentTypes,
    clearOperatoryScheduleRules,
    createOperatoryScheduleRule,
    getOperatoryScheduleRules,
    clearOperatorySchedules,
    getOperatorySchedules,
  }, dispatch),
)(ScheduleGenerator))
