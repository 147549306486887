import {
  CLEAR_OPERATORIES,
  GET_OPERATORIES_REQUEST,
  GET_OPERATORIES_SUCCESS,
  GET_OPERATORIES_FAILURE,
} from '../actions/operatories'

export const defaultState = {
  loading: false,
  operatories: [],
}

export default (state = defaultState, action) => {
  const newState = Object.assign({}, state)

  switch (action.type) {

    case CLEAR_OPERATORIES:
      newState.operatories = []
      return newState

    case GET_OPERATORIES_REQUEST:
      newState.loading = true
      return newState

    case GET_OPERATORIES_SUCCESS:
      newState.loading = false
      newState.operatories = action.payload
      return newState

    case GET_OPERATORIES_FAILURE:
      newState.loading = false
      return newState

    default:
      return state
  }
}
