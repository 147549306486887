import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import classNames from 'classnames'
import AWS from 'aws-sdk'

let getSignedUrl = (userId, imageUrl) => {
  const s3 = new AWS.S3();
  s3.config.update({credentials: AWS.config.credentials})
  var imageIdentifier = /[^/]*$/.exec(imageUrl)[0]
  let fileKey = `users/${userId}/messages/${imageIdentifier}`

  const params = {
    Bucket: process.env.REACT_APP_AWS_S3_BUCKET,
    Key: fileKey,
    Expires: 60
  };

  const presignedUrl = s3.getSignedUrl("getObject", params);

  return presignedUrl
}

class Message extends Component {
  render() {
    const { currentUser, currentEmployee, message } = this.props

    const containerClasses = classNames({
        'message___user': message.messageable_type === "User",
        'message___employee': message.messageable_type === "Employee",
        'message___main_container': true,
    })

    const timestampClasses = classNames({
        'message__timestamp': true,
        'message__timestamp_employee': message.messageable_type === "Employee",
        'message__timestamp_user': message.messageable_type === "User",
    })

    var messageBody = null
    if (message.message_type === "text") {
      messageBody = (
        <p className="message___text">{message.body}</p>
      )
    }

    if (message.messageable_type === "User" && message.message_type === "image") {
      let mediaJson = JSON.parse(message.media)
      messageBody = (
        <img className="message___image" src={getSignedUrl(message.messageable_id, mediaJson.image.url)} />
      )
    }

    var displayName = ''
    if (message.messageable_type === "User") {

      if (message.messageable_id) {
        displayName = `${currentUser.first_name[0]}. ${currentUser.last_name} `
      } else {
        displayName = `Non-Existent Patient `
      }
    }

    if (message.messageable_type === "Employee") {
      displayName = ""
    }

    if (message.messageable_type === "Employee" && currentEmployee && message.messageable_id === currentEmployee.id) {
      displayName = "Me "
    }


    return (
     <Row>
	    <Col xs={12} className="message___container">
	      	<div className={containerClasses}>
            { messageBody }
	      	</div>
          <div className={timestampClasses}>
            {displayName}{ moment(message.created_at).format("h:mma") }
          </div>
	     </Col>
     </Row>
    )
  }
}

export default connect(
  state => ({
    currentEmployee: state.auth.currentEmployee,
    currentUser: state.users.currentUser,
  }),
  dispatch => bindActionCreators({
  }, dispatch),
)(Message)
