import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Container } from 'react-bootstrap'
import { bindActionCreators } from 'redux'
import _map from 'lodash/map'
import _filter from 'lodash/filter'
import _isEmpty from 'lodash/isEmpty'
import _xor from 'lodash/xor'
import _uniq from 'lodash/uniq'
import { withRouter } from 'react-router'
import { Formik, Field, Form } from 'formik';

import leftArrow from '../images/left-arrow.png'
import rightArrow from '../images/right-arrow.png'

import loadingAnimation from '../images/loading-animation.gif'

import { getPracticeProductionStats } from '../actions/stats'
import { clearCurrentPracticeProductionIds, setCurrentPracticeProductionIds, clearInitialValuesForEmployeeProductionForm, setInitialValuesForPracticeProductionForm } from '../actions/stats'
import { getPractices } from '../actions/practices'
import { getEmployees } from '../actions/employees'

import PracticeProductionHorizontalBarChart from './PracticeProductionHorizontalBarChart'

let initialValues = {
  checkedPractices: []
}

class PracticeProduction extends Component {
  constructor(props) {
  	super(props)

  	this.handleSubmit = this.handleSubmit.bind(this)
  }
  componentDidMount() {
    const { getPractices  } = this.props

    getPractices()
  }
  clickedAllPracticesFilter(e, setFieldValue) {
    const { clearCurrentPracticeProductionIds, setInitialValuesForPracticeProductionForm, initialValues, practices, setCurrentPracticeProductionIds, practiceProductionIds  } = this.props

    const practiceIds = _map(practices, 'id')

    if (_isEmpty(_xor(practiceIds, practiceProductionIds)) === true) {
    	clearCurrentPracticeProductionIds()
    	setInitialValuesForPracticeProductionForm({ checkedPractices: []})
    	setFieldValue('checkedPractices', [])
    } else {
    	var idsConverted = _map(practiceIds, (x) => { return parseInt(x) })
    	let oldAndNewIds = _uniq(idsConverted)
    	let formattedValues = { checkedPractices: oldAndNewIds }
    	setInitialValuesForPracticeProductionForm(formattedValues)
    	// clearCurrentPracticeProductionIds()
    	setCurrentPracticeProductionIds(oldAndNewIds)
    	setFieldValue('checkedPractices', oldAndNewIds)
    }
  }
  handleSubmit(values) {
    const { currentEmployee, clearCurrentPracticeProductionIds, setInitialValuesForPracticeProductionForm, setCurrentPracticeProductionIds, getPracticeProductionStats, practiceProductionIds, employeeProductionDateRange   } = this.props

  	if (currentEmployee) {
  		clearCurrentPracticeProductionIds()
		var idsConverted = _map(values.checkedPractices, (x) => { return parseInt(x) })
    	let oldAndNewIds = _uniq(idsConverted)
	  	setCurrentPracticeProductionIds(oldAndNewIds)
	  	let formattedValues = { checkedPractices: oldAndNewIds }
    	setInitialValuesForPracticeProductionForm(formattedValues)
  		getPracticeProductionStats(currentEmployee.id, values.checkedPractices, employeeProductionDateRange.startDate.toISOString(), employeeProductionDateRange.endDate.toISOString())
  	}
  }
  render() {
    const { practices, practiceProductionStats } = this.props


    return (
      <Container fluid className="practice_production__main_container">
        <Row>
          <Col xs={{ span: 12, offset: 0 }} sm={{ span: 12, offset: 0 }} md={{ span: 12, offset: 0 }} lg={{ span: 12, offset: 0 }} className="practice_production__main_container">
	          <Row>
	          	<Col xs={{ span: 2, offset: 0 }} className="practice_production__practice_container">
		          <Formik
		            initialValues={initialValues}
		            onSubmit={this.handleSubmit}
		          >
		            {({ isSubmitting, setFieldValue }) => (
		              <Form className="practice_production___form_container">
			          		<div className="practice_production__practice_form_container">
			          			<h4 className="practice_production__form_title">Practices&nbsp;&nbsp; {/*<input onClick={(data) => this.clickedAllPracticesFilter(data, setFieldValue)} type="checkbox" id="practices" name="practices" value="dentists" /> */} </h4>
                      {
                        _map(practices, (practice, index) => {
                            return (
                                <div key={practice.id}>
                                  <label key={practice.id}>
                                    <Field key={practice.id} type="checkbox" id={`${practice.id}`} name="checkedPractices" value={`${practice.id}`} />
                                    &nbsp;{practice.display_name}
                                  </label>
                                </div>
                              )
                          }
                        )
                      }
			          		</div>
			          		<button type="submit" className="practice_production__form_button">
			          		  Submit
			          		</button>
	          	    </Form>
	          	  )}
	          	</Formik>
	          	</Col>
	          	<Col xs={{ span: 10, offset: 0}}>
	          		<PracticeProductionHorizontalBarChart practiceProductionStats={practiceProductionStats} />
	          	</Col>
	          </Row>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default withRouter(connect(
  state => ({
    currentEmployee: state.auth.currentEmployee,
    employeeProductionDateRange: state.stats.employeeProductionDateRange,
    practices: state.practices.practices,
    practiceProductionIds: state.stats.practiceProductionIds,
    practiceProductionStats: state.stats.practiceProductionStats,
  }),
  dispatch => bindActionCreators({
  	getPractices,
  	clearInitialValuesForEmployeeProductionForm,
  	setInitialValuesForPracticeProductionForm,
  	clearCurrentPracticeProductionIds,
  	setCurrentPracticeProductionIds,
  	getPracticeProductionStats,
  	getEmployees,
  }, dispatch),
)(PracticeProduction))
