import {
  SET_CURRENT_MESSAGE,
  SET_MESSAGES,
  SET_CURRENT_MESSAGES_PAGE,
  CLEAR_CURRENT_MESSAGE,
  APPEND_TO_MESSAGES,
  CLEAR_MESSAGES,
  GET_MESSAGES_REQUEST,
  GET_MESSAGES_SUCCESS,
  GET_MESSAGES_FAILURE,
  GET_MESSAGES_TOTAL_PAGES_REQUEST,
  GET_MESSAGES_TOTAL_PAGES_SUCCESS,
  GET_MESSAGES_TOTAL_PAGES_FAILURE,
} from '../actions/messages'
import moment from 'moment'
import _groupBy from 'lodash/groupBy'
import _flatten from 'lodash/flatten'
import _uniqBy from 'lodash/uniqBy'

export const defaultState = {
  loading: false,
  messages: [],
  messageDates: [],
  messagesByDateDict: null,
  currentPage: 1,
  totalPages: 1,
  currentMessage: '',
}

export default (state = defaultState, action) => {
  const newState = Object.assign({}, state)

  switch (action.type) {

    case SET_CURRENT_MESSAGE:
      newState.currentMessage = action.payload
      return newState

    case SET_MESSAGES:
      newState.messages = action.payload
      newState.messagesByDateDict = _groupBy(newState.messages, (message) => {
            return moment(message.created_at).startOf('day').format()
      })
      newState.messageDates = Object.keys(newState.messagesByDateDict).sort((a, b) => moment(a).diff(moment(b)))
      return newState

    case SET_CURRENT_MESSAGES_PAGE:
      newState.currentPage = action.payload
      return newState

    case CLEAR_CURRENT_MESSAGE:
      newState.currentMessage = ''
      return newState

    case CLEAR_MESSAGES:
      newState.messages = []
      newState.messageDates = []
      newState.messagesByDateDict = null
      newState.currentPage = 1
      newState.totalPages = 1
      newState.currentMessage = ''
      return newState

    case GET_MESSAGES_REQUEST:
      newState.loading = true
      return newState

    case GET_MESSAGES_SUCCESS:
      newState.loading = false
      // newState.messages = action.payload
      return newState

    case GET_MESSAGES_FAILURE:
      newState.loading = false
      return newState

    case APPEND_TO_MESSAGES:
      newState.messages = _uniqBy(_flatten([...state.messages, action.payload]), 'id')
      newState.messagesByDateDict = _groupBy(newState.messages, (message) => {
            return moment(message.created_at).startOf('day').format()
      })
      newState.messageDates = Object.keys(newState.messagesByDateDict).sort((a, b) => moment(a).diff(moment(b)))
      return newState

    case GET_MESSAGES_TOTAL_PAGES_REQUEST:
      // newState.loading = true
      return newState

    case GET_MESSAGES_TOTAL_PAGES_SUCCESS:
      // newState.loading = false
      newState.totalPages = action.payload
      return newState

    case GET_MESSAGES_TOTAL_PAGES_FAILURE:
      // newState.loading = false
      return newState

    default:
      return state
  }
};
