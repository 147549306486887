import {
  CLEAR_CURRENT_PRACTICE,
  SET_CURRENT_PRACTICE,
  GET_PRACTICES_REQUEST,
  GET_PRACTICES_SUCCESS,
  GET_PRACTICES_FAILURE,
} from '../actions/practices'

export const defaultState = {
  loading: false,
  practices: [],
  currentPractice: null,
}

export default (state = defaultState, action) => {
  const newState = Object.assign({}, state)

  switch (action.type) {

    case CLEAR_CURRENT_PRACTICE:
      newState.currentPractice = null
      return newState   

    case SET_CURRENT_PRACTICE:
      newState.currentPractice = action.payload
      return newState

    case GET_PRACTICES_REQUEST:
      newState.loading = true
      return newState

    case GET_PRACTICES_SUCCESS:
      newState.loading = false
      newState.practices = action.payload
      return newState

    case GET_PRACTICES_FAILURE:
      newState.loading = false
      return newState

    default:
      return state
  }
};
