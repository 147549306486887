import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import { bindActionCreators } from 'redux'

let formatPhoneNumber = (str) => {
  let cleaned = ('' + str).replace(/\D/g, '')
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3]
  }

  return null
}

class NoSsnUserCard extends Component {
  render() {
    const { user } = this.props

    return (
      <div className="no_ssn_user_card__main_container" >
        <Row className="no_ssn_user_card__container">
          <Col xs={3}>
            <h3 className="no_ssn_user_card__name">{ user.first_name } { user.last_name }</h3>
          </Col>
          <Col xs={3}>
            <h3 className="no_ssn_user_card__name">Preferred Pronoun: { user.preferred_pronouns ? user.preferred_pronouns : 'N/A'  }</h3>
          </Col>
          <Col xs={3}>
            <h3 className="no_ssn_user_card__email">Email: { user.email }</h3>
          </Col>
          <Col xs={3}>
            <h3 className="no_ssn_user_card__phone">Phone: { formatPhoneNumber(user.phone) }</h3>
          </Col>
        </Row>
      </div>
    )
  }
}

export default connect(
  state => ({
  }),
  dispatch => bindActionCreators({
  }, dispatch),
)(NoSsnUserCard)
