import { combineReducers } from 'redux'
import auth from './auth'
import views from './views'
import conversations from './conversations'
import messages from './messages'
import appointments from './appointments'
import users from './users'
import insurancePolicies from './insurancePolicies'
import medicalSurveyCollections from './medicalSurveyCollections'
import medicalSurveys from './medicalSurveys'
import medicalSurveyQuestions from './medicalSurveyQuestions'
import conversationEmployees from './conversationEmployees'
import userAllergies from './userAllergies'
import userMedications from './userMedications'
import employees from './employees'
import practices from './practices'
import stats from './stats'
import operatories from './operatories'
import appointmentTypes from './appointmentTypes'
import scheduleGenerators from './scheduleGenerators'
import operatoryScheduleRules from './operatoryScheduleRules'
import operatorySchedules from './operatorySchedules'
import repairIssues from './repairIssues'

const rootReducer = combineReducers({
  auth,
  views,
  conversations,
  practices,
  employees,
  messages,
  appointments,
  users,
  insurancePolicies,
  medicalSurveyCollections,
  medicalSurveys,
  medicalSurveyQuestions,
  conversationEmployees,
  userAllergies,
  userMedications,
  stats,
  operatories,
  appointmentTypes,
  scheduleGenerators,
  operatoryScheduleRules,
  operatorySchedules,
  repairIssues,
})

export default rootReducer
