import axiosClient from '../utils/axios'
import { authHeaders } from '../utils/localStorage'

export const SET_CURRENT_INSURANCE_POLICY = 'SET_CURRENT_INSURANCE_POLICY'
export function setCurrentInsurancePolicy(insurancePolicy) {
  return {
    type: SET_CURRENT_INSURANCE_POLICY,
    payload: insurancePolicy,
  }
}

export const CLEAR_CURRENT_INSURANCE_POLICY = 'CLEAR_CURRENT_INSURANCE_POLICY'
export function clearCurrentInsurancePolicy() {
  return {
    type: CLEAR_CURRENT_INSURANCE_POLICY,
  }
}

export const CLEAR_INSURANCE_POLICIES = 'CLEAR_INSURANCE_POLICIES'
export function clearInsurancePolicies() {
  return {
    type: CLEAR_INSURANCE_POLICIES,
  }
}

export const GET_INSURANCE_POLICIES_REQUEST = 'GET_INSURANCE_POLICIES_REQUEST'
export function getInsurancePoliciesRequest() {
  return {
    type: GET_INSURANCE_POLICIES_REQUEST,
  }
}

export const GET_INSURANCE_POLICIES_SUCCESS = 'GET_INSURANCE_POLICIES_SUCCESS'
export function getInsurancePoliciesSuccess(insurancePolicies) {
  return {
    type: GET_INSURANCE_POLICIES_SUCCESS,
    payload: insurancePolicies,
  }
}

export const GET_INSURANCE_POLICIES_FAILURE = 'GET_INSURANCE_POLICIES_FAILURE'
export function getInsurancePoliciesFailure(error) {
  return {
    type: GET_INSURANCE_POLICIES_FAILURE,
    payload: error,
  }
}

export function getInsurancePolicies(employeeId, userId) {
  return (dispatch) => {
    dispatch(getInsurancePoliciesRequest())
    return axiosClient
      .get(`${process.env.REACT_APP_API_URL}/v1/employees/${employeeId}/users/${userId}/insurance_policies`, { headers: authHeaders() })
      .then((response) => {
        dispatch(getInsurancePoliciesSuccess(response.data))
        return response.data
      })
      .catch((error) => {
        dispatch(getInsurancePoliciesFailure(error))
      })
  }
}