import axiosClient from '../utils/axios'
import { authHeaders } from '../utils/localStorage'

export const SET_CURRENT_PRACTICE = 'SET_CURRENT_PRACTICE'
export function setCurrentPractice(practice) {
  return {
    type: SET_CURRENT_PRACTICE,
    payload: practice,
  }
}

export const CLEAR_CURRENT_PRACTICE = 'CLEAR_CURRENT_PRACTICE'
export function clearCurrentPractice() {
  return {
    type: CLEAR_CURRENT_PRACTICE,
  }
}

export const GET_PRACTICES_REQUEST = 'GET_PRACTICES_REQUEST'
export function getPracticesRequest() {
  return {
    type: GET_PRACTICES_REQUEST,
  }
}

export const GET_PRACTICES_SUCCESS = 'GET_PRACTICES_SUCCESS'
export function getPracticesSuccess(employees) {
  return {
    type: GET_PRACTICES_SUCCESS,
    payload: employees,
  }
}

export const GET_PRACTICES_FAILURE = 'GET_PRACTICES_FAILURE'
export function getPracticesFailure(error) {
  return {
    type: GET_PRACTICES_FAILURE,
    payload: error,
  }
}

export function getPractices() {
  return (dispatch) => {
    dispatch(getPracticesRequest())
    return axiosClient
      .get(`${process.env.REACT_APP_API_URL}/v1/practices`)
      .then((response) => {
        dispatch(getPracticesSuccess(response.data))
        return Promise.resolve(response.data)
      })
      .catch((error) => {
        dispatch(getPracticesFailure(error))
        return Promise.reject(error)
      })
  }
}