import axiosClient from '../utils/axios'
import { authHeaders } from '../utils/localStorage'

// export const SET_CURRENT_INSURANCE_POLICY = 'SET_CURRENT_INSURANCE_POLICY'
// export function setCurrentInsurancePolicy(insurancePolicy) {
//   return {
//     type: SET_CURRENT_INSURANCE_POLICY,
//     payload: insurancePolicy,
//   }
// }

// export const CLEAR_CURRENT_INSURANCE_POLICY = 'CLEAR_CURRENT_INSURANCE_POLICY'
// export function clearCurrentInsurancePolicy() {
//   return {
//     type: CLEAR_CURRENT_INSURANCE_POLICY,
//   }
// }

// export const CLEAR_OPERATORIES = 'CLEAR_OPERATORIES'
// export function clearAppointmentTypes() {
//   return {
//     type: CLEAR_OPERATORIES,
//   }
// }

export const GET_APPOINTMENT_TYPES_REQUEST = 'GET_APPOINTMENT_TYPES_REQUEST'
export function getAppointmentTypesRequest() {
  return {
    type: GET_APPOINTMENT_TYPES_REQUEST,
  }
}

export const GET_APPOINTMENT_TYPES_SUCCESS = 'GET_APPOINTMENT_TYPES_SUCCESS'
export function getAppointmentTypesSuccess(operatories) {
  return {
    type: GET_APPOINTMENT_TYPES_SUCCESS,
    payload: operatories,
  }
}

export const GET_APPOINTMENT_TYPES_FAILURE = 'GET_APPOINTMENT_TYPES_FAILURE'
export function getAppointmentTypesFailure(error) {
  return {
    type: GET_APPOINTMENT_TYPES_FAILURE,
    payload: error,
  }
}

export function getAppointmentTypes() {
  return (dispatch) => {
    dispatch(getAppointmentTypesRequest())
    return axiosClient
      .get(`${process.env.REACT_APP_API_URL}/v1/appointment_types`, { headers: authHeaders() })
      .then((response) => {
        dispatch(getAppointmentTypesSuccess(response.data))
        return response.data
      })
      .catch((error) => {
        dispatch(getAppointmentTypesFailure(error))
      })
  }
}