import {
  SET_CURRENT_APPOINTMENT,
  CLEAR_CURRENT_APPOINTMENT,
  CLEAR_APPOINTMENTS,
  GET_APPOINTMENTS_REQUEST,
  GET_APPOINTMENTS_SUCCESS,
  GET_APPOINTMENTS_FAILURE,
} from '../actions/appointments'

export const defaultState = {
  loading: false,
  appointments: [],
  currentAppointment: null,
}

export default (state = defaultState, action) => {
  const newState = Object.assign({}, state)

  switch (action.type) {

  	case SET_CURRENT_APPOINTMENT:
      newState.currentAppointment = action.payload
      return newState

    case CLEAR_CURRENT_APPOINTMENT:
      newState.currentAppointment = null
      return newState

    case CLEAR_APPOINTMENTS:
      newState.appointments = []
      return newState

    case GET_APPOINTMENTS_REQUEST:
      newState.loading = true
      return newState

    case GET_APPOINTMENTS_SUCCESS:
      newState.loading = false
      newState.appointments = action.payload
      return newState

    case GET_APPOINTMENTS_FAILURE:
      newState.loading = false
      return newState

    default:
      return state
  }
};
