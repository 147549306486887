import axiosClient from '../utils/axios'
import { authHeaders } from '../utils/localStorage'

export const SET_CURRENT_USER_ALLERGY = 'SET_CURRENT_USER_ALLERGY'
export function setCurrentUserAllergy(userAllergy) {
  return {
    type: SET_CURRENT_USER_ALLERGY,
    payload: userAllergy,
  }
}

export const CLEAR_CURRENT_USER_ALLERGY = 'CLEAR_CURRENT_USER_ALLERGY'
export function clearCurrentUserAllergy() {
  return {
    type: CLEAR_CURRENT_USER_ALLERGY,
  }
}

export const CLEAR_USER_ALLERGIES = 'CLEAR_USER_ALLERGIES'
export function clearUserAllergies() {
  return {
    type: CLEAR_USER_ALLERGIES,
  }
}

export const GET_USER_ALLERGIES_REQUEST = 'GET_USER_ALLERGIES_REQUEST'
export function getUserAllergiesRequest() {
  return {
    type: GET_USER_ALLERGIES_REQUEST,
  }
}

export const GET_USER_ALLERGIES_SUCCESS = 'GET_USER_ALLERGIES_SUCCESS'
export function getUserAllergiesSuccess(userAllergies) {
  return {
    type: GET_USER_ALLERGIES_SUCCESS,
    payload: userAllergies,
  }
}

export const GET_USER_ALLERGIES_FAILURE = 'GET_USER_ALLERGIES_FAILURE'
export function getUserAllergiesFailure(error) {
  return {
    type: GET_USER_ALLERGIES_FAILURE,
    payload: error,
  }
}

export function getUserAllergies(employeeId, userId) {
  return (dispatch) => {
    dispatch(getUserAllergiesRequest())
    return axiosClient
      .get(`${process.env.REACT_APP_API_URL}/v1/employees/${employeeId}/users/${userId}/user_allergies`, { headers: authHeaders() })
      .then((response) => {
        dispatch(getUserAllergiesSuccess(response.data))
      })
      .catch((error) => {
        dispatch(getUserAllergiesFailure(error))
      })
  }
}