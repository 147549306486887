import axiosClient from '../utils/axios'
import { authHeaders } from '../utils/localStorage'

export const SET_CURRENT_MEDICAL_SURVEY_QUESTION = 'SET_CURRENT_MEDICAL_SURVEY_QUESTION'
export function setCurrentMedicalSurveyQuestion(medicalSurvey) {
  return {
    type: SET_CURRENT_MEDICAL_SURVEY_QUESTION,
    payload: medicalSurvey,
  }
}

export const CLEAR_CURRENT_MEDICAL_SURVEY_QUESTION = 'CLEAR_CURRENT_MEDICAL_SURVEY_QUESTION'
export function clearCurrentMedicalSurveyQuestion() {
  return {
    type: CLEAR_CURRENT_MEDICAL_SURVEY_QUESTION,
  }
}

export const CLEAR_MEDICAL_SURVEY_QUESTIONS = 'CLEAR_MEDICAL_SURVEY_QUESTIONS'
export function clearMedicalSurveyQuestions() {
  return {
    type: CLEAR_MEDICAL_SURVEY_QUESTIONS,
  }
}

export const GET_MEDICAL_SURVEY_QUESTIONS_REQUEST = 'GET_MEDICAL_SURVEY_QUESTIONS_REQUEST'
export function getMedicalSurveyQuestionsRequest() {
  return {
    type: GET_MEDICAL_SURVEY_QUESTIONS_REQUEST,
  }
}

export const GET_MEDICAL_SURVEY_QUESTIONS_SUCCESS = 'GET_MEDICAL_SURVEY_QUESTIONS_SUCCESS'
export function getMedicalSurveyQuestionsSuccess(insurancePolicies) {
  return {
    type: GET_MEDICAL_SURVEY_QUESTIONS_SUCCESS,
    payload: insurancePolicies,
  }
}

export const GET_MEDICAL_SURVEY_QUESTIONS_FAILURE = 'GET_MEDICAL_SURVEY_QUESTIONS_FAILURE'
export function getMedicalSurveyQuestionsFailure(error) {
  return {
    type: GET_MEDICAL_SURVEY_QUESTIONS_FAILURE,
    payload: error,
  }
}

export function getMedicalSurveyQuestions(medicalSurveyId, employeeId, userId) {
  return (dispatch) => {
    dispatch(getMedicalSurveyQuestionsRequest())
    return axiosClient
      .get(`${process.env.REACT_APP_API_URL}/v1/employees/${employeeId}/users/${userId}/medical_surveys/${medicalSurveyId}/medical_survey_questions`, { headers: authHeaders() })
      .then((response) => {
        dispatch(getMedicalSurveyQuestionsSuccess(response.data))
        return Promise.resolve(response.data)
      })
      .catch((error) => {
        dispatch(getMedicalSurveyQuestionsFailure(error))
        return Promise.reject(error)
      })
  }
}