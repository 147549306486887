import axiosClient from '../utils/axios'
import { authHeaders } from '../utils/localStorage'

export const SET_CURRENT_MEDICAL_SURVEY_COLLECTION = 'SET_CURRENT_MEDICAL_SURVEY_COLLECTION'
export function setCurrentMedicalSurveyCollection(medicalSurveyCollection) {
  return {
    type: SET_CURRENT_MEDICAL_SURVEY_COLLECTION,
    payload: medicalSurveyCollection,
  }
}

export const CLEAR_CURRENT_MEDICAL_SURVEY_COLLECTION = 'CLEAR_CURRENT_MEDICAL_SURVEY_COLLECTION'
export function clearCurrentMedicalSurveyCollection() {
  return {
    type: CLEAR_CURRENT_MEDICAL_SURVEY_COLLECTION,
  }
}

export const CLEAR_MEDICAL_SURVEY_COLLECTION = 'CLEAR_MEDICAL_SURVEY_COLLECTION'
export function clearMedicalSurveyCollections() {
  return {
    type: CLEAR_MEDICAL_SURVEY_COLLECTION,
  }
}

export const GET_MEDICAL_SURVEY_COLLECTION_REQUEST = 'GET_MEDICAL_SURVEY_COLLECTION_REQUEST'
export function getMedicalSurveyCollectionsRequest() {
  return {
    type: GET_MEDICAL_SURVEY_COLLECTION_REQUEST,
  }
}

export const GET_MEDICAL_SURVEY_COLLECTION_SUCCESS = 'GET_MEDICAL_SURVEY_COLLECTION_SUCCESS'
export function getMedicalSurveyCollectionsSuccess(insurancePolicies) {
  return {
    type: GET_MEDICAL_SURVEY_COLLECTION_SUCCESS,
    payload: insurancePolicies,
  }
}

export const GET_MEDICAL_SURVEY_COLLECTION_FAILURE = 'GET_MEDICAL_SURVEY_COLLECTION_FAILURE'
export function getMedicalSurveyCollectionsFailure(error) {
  return {
    type: GET_MEDICAL_SURVEY_COLLECTION_FAILURE,
    payload: error,
  }
}

export function getMedicalSurveyCollections(employeeId, userId) {
  return (dispatch) => {
    dispatch(getMedicalSurveyCollectionsRequest())
    return axiosClient
      .get(`${process.env.REACT_APP_API_URL}/v1/employees/${employeeId}/users/${userId}/medical_survey_collections`, { headers: authHeaders() })
      .then((response) => {
        dispatch(getMedicalSurveyCollectionsSuccess(response.data))
        return Promise.resolve(response.data)
      })
      .catch((error) => {
        dispatch(getMedicalSurveyCollectionsFailure(error))
        return Promise.reject(error)
      })
  }
}