import {
  SET_CURRENT_MEDICAL_SURVEY_COLLECTION,
  CLEAR_CURRENT_MEDICAL_SURVEY_COLLECTION,
  CLEAR_MEDICAL_SURVEY_COLLECTION,
  GET_MEDICAL_SURVEY_COLLECTION_REQUEST,
  GET_MEDICAL_SURVEY_COLLECTION_SUCCESS,
  GET_MEDICAL_SURVEY_COLLECTION_FAILURE,
} from '../actions/medicalSurveyCollections'

export const defaultState = {
  loading: false,
  medicalSurveyCollection: null,
  currentMedicalSurveyCollection: null,
}

export default (state = defaultState, action) => {
  const newState = Object.assign({}, state)

  switch (action.type) {

  	case SET_CURRENT_MEDICAL_SURVEY_COLLECTION:
      newState.currentMedicalSurveyCollection = action.payload
      return newState

    case CLEAR_CURRENT_MEDICAL_SURVEY_COLLECTION:
      newState.currentMedicalSurveyCollection = null
      return newState

    case CLEAR_MEDICAL_SURVEY_COLLECTION:
      newState.medicalSurveyCollection = null
      return newState

    case GET_MEDICAL_SURVEY_COLLECTION_REQUEST:
      newState.loading = true
      return newState

    case GET_MEDICAL_SURVEY_COLLECTION_SUCCESS:
      newState.loading = false
      newState.medicalSurveyCollection = action.payload
      return newState

    case GET_MEDICAL_SURVEY_COLLECTION_FAILURE:
      newState.loading = false
      return newState

    default:
      return state
      
  }
};
