import axiosClient from '../utils/axios'
import { authHeaders } from '../utils/localStorage'

export const CLEAR_SCHEDULE_GENERATOR_DATE_RANGE = 'CLEAR_SCHEDULE_GENERATOR_DATE_RANGE'
export function clearScheduleGeneratorDateRange() {
  return {
    type: CLEAR_SCHEDULE_GENERATOR_DATE_RANGE,
  }
}

export const SET_SCHEDULE_GENERATOR_DATE_RANGE = 'SET_SCHEDULE_GENERATOR_DATE_RANGE'
export function setScheduleGeneratorDateRange(dateRange) {
  return {
    type: SET_SCHEDULE_GENERATOR_DATE_RANGE,
    payload: dateRange,
  }
}