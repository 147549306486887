import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import { bindActionCreators } from 'redux'

import smilecycleLogo from '../images/smilecycle-logo.png'
import affirmLogo from '../images/affirm-logo.png'

class SmileCycleCheckout extends Component {
  render() {

    return (
      <div className="smile_cycle_checkout__main_container">
        <Row>
        </Row>
        <div className="smile_cycle_checkout__header_main_container">
          <Row className="smile_cycle_checkout__header_row_container">
            <Col  xs={{ span: 10, offset: 2 }}>
              <div className="smile_cycle_checkout__image_container">
                <img src={smilecycleLogo} className="smile_cycle_checkout__image" />
              </div>
            </Col>
          </Row>
          <Row>
            <Col  xs={{ span: 10, offset: 2 }}>
              <h4 className="smile_cycle_checkout__prompt_text">How would you like to complete your purchase?</h4>
            </Col>
          </Row>
          <Row>
            <Col  xs={{ span: 10, offset: 2 }}>
              <h4 className="smile_cycle_checkout__prompt_text">Total Cost<br /><span className="smile_cycle_checkout__prompt_text_bold">$3100</span></h4>
            </Col>
          </Row>
          <Row>
            <Col  xs={{ span: 5, offset: 2 }}>
              <div className="smile_cycle_checkout__affirm_container" >
                <p className="smile_cycle_checkout__pay_text">Pay with &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                <img src={affirmLogo} className="smile_cycle_checkout__affirm_logo" />
                <p className="smile_cycle_checkout__pay_line_1">Split your payments <span className="">over 24 months.</span></p>
                <p className="smile_cycle_checkout__pay_line_2">$3100 / 24 months</p>
                <p className="smile_cycle_checkout__pay_line_3_more_expensive">Monthly Cost - $129</p>
                <p className="smile_cycle_checkout__pay_line_4">Due Today: $129</p>
                <div className="smile_cycle_checkout__button_container">
                  <button className="smile_cycle_checkout__pay_button_affirm">Pay with Affirm</button>
                </div>
              </div>
            </Col>
            <Col  xs={{ span: 5, offset: 0 }}>
              <div className="smile_cycle_checkout__pay_in_full_container">
                <p className="smile_cycle_checkout__pay_text">Pay <span className="smile_cycle_checkout__bolded_header">in Full</span></p>
                <p className="smile_cycle_checkout__pay_line_1">Pay it all up front for a <span className="">10% discount.</span></p>
                <p className="smile_cycle_checkout__pay_line_2">$3100 - 10%</p>
                <p className="smile_cycle_checkout__pay_line_3_cheaper">One Time Dicounted Price - $2950</p>
                <p className="smile_cycle_checkout__pay_line_4">Due Today: $2950</p>
                <div className="smile_cycle_checkout__button_container">
                  <a href="https://quickclick.com/r/a06j1">
                    <button className="smile_cycle_checkout__pay_button_up_front">Pay in Full</button>
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}

export default connect(
  state => ({
  }),
  dispatch => bindActionCreators({
  }, dispatch),
)(SmileCycleCheckout)
