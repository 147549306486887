import {
  CLEAR_REPAIR_ISSUES,
  GET_REPAIR_ISSUES_REQUEST,
  GET_REPAIR_ISSUES_SUCCESS,
  GET_REPAIR_ISSUES_FAILURE,
  UPDATE_REPAIR_ISSUES_REQUEST,
  UPDATE_REPAIR_ISSUES_SUCCESS,
  UPDATE_REPAIR_ISSUES_FAILURE,
} from '../actions/repairIssues'

export const defaultState = {
  loading: false,
  currentPage: 1,
  totalPages: 1,
  repairIssues: [],
  currentRepairIssue: null,
  statusFilter: null,
}

export default (state = defaultState, action) => {
  const newState = Object.assign({}, state)

  switch (action.type) {

    case CLEAR_REPAIR_ISSUES:
      newState.repairIssues = []
      return newState   

    case GET_REPAIR_ISSUES_REQUEST:
      newState.loading = true
      return newState

    case GET_REPAIR_ISSUES_SUCCESS:
      newState.loading = false
      newState.repairIssues = action.payload
      return newState

    case GET_REPAIR_ISSUES_FAILURE:
      newState.loading = false
      return newState

    case UPDATE_REPAIR_ISSUES_REQUEST:
      newState.loading = true
      return newState

    case UPDATE_REPAIR_ISSUES_SUCCESS:
      newState.loading = false
      return newState

    case UPDATE_REPAIR_ISSUES_FAILURE:
      newState.loading = false
      return newState

    default:
      return state
  }
};
