import axiosClient from '../utils/axios'
import { authHeaders } from '../utils/localStorage'

export const SET_CURRENT_MESSAGE = 'SET_CURRENT_MESSAGE'
export function setCurrentMessage(message) {
  return {
    type: SET_CURRENT_MESSAGE,
    payload: message,
  }
}

export const SET_MESSAGES = 'SET_MESSAGES'
export function setMessages(messages) {
  return {
    type: SET_MESSAGES,
    payload: messages,
  }
}

export const SET_CURRENT_MESSAGES_PAGE = 'SET_CURRENT_MESSAGES_PAGE'
export function setCurrentMessagesPage(pageNumber) {
  return {
    type: SET_CURRENT_MESSAGES_PAGE,
    payload: pageNumber,
  }
}

export const APPEND_TO_MESSAGES = 'APPEND_TO_MESSAGES'
export function appendToMessages(message) {
  return {
    type: APPEND_TO_MESSAGES,
    payload: message,
  }
}

export const CLEAR_CURRENT_MESSAGE = 'CLEAR_CURRENT_MESSAGE'
export function clearCurrentMessage() {
  return {
    type: CLEAR_CURRENT_MESSAGE,
  }
}

export const CLEAR_MESSAGES = 'CLEAR_MESSAGES'
export function clearMessages() {
  return {
    type: CLEAR_MESSAGES,
  }
}

export const GET_MESSAGES_REQUEST = 'GET_MESSAGES_REQUEST'
export function getMessagesRequest() {
  return {
    type: GET_MESSAGES_REQUEST,
  }
}

export const GET_MESSAGES_SUCCESS = 'GET_MESSAGES_SUCCESS'
export function getMessagesSuccess(messages) {
  return {
    type: GET_MESSAGES_SUCCESS,
    payload: messages,
  }
}

export const GET_MESSAGES_FAILURE = 'GET_MESSAGES_FAILURE'
export function getMessagesFailure(error) {
  return {
    type: GET_MESSAGES_FAILURE,
    payload: error,
  }
}

export function getMessages(employeeId, conversationId, pageNumber) {
  return (dispatch) => {
    dispatch(getMessagesRequest())
    return axiosClient
      .get(`${process.env.REACT_APP_API_URL}/v1/employees/${employeeId}/conversations/${conversationId}/messages`, { params: { page: pageNumber },  headers: authHeaders() })
      .then((response) => {
        dispatch(getMessagesSuccess())
        return response.data
      })
      .catch((error) => {
        dispatch(getMessagesFailure(error))
      })
  }
}


export const GET_MESSAGES_TOTAL_PAGES_REQUEST = 'GET_MESSAGES_TOTAL_PAGES_REQUEST'
export function getMessagesTotalPagesRequest() {
  return {
    type: GET_MESSAGES_TOTAL_PAGES_REQUEST,
  }
}

export const GET_MESSAGES_TOTAL_PAGES_SUCCESS = 'GET_MESSAGES_TOTAL_PAGES_SUCCESS'
export function getMessagesTotalPagesSuccess(conversations) {
  return {
    type: GET_MESSAGES_TOTAL_PAGES_SUCCESS,
    payload: conversations,
  }
}

export const GET_MESSAGES_TOTAL_PAGES_FAILURE = 'GET_MESSAGES_TOTAL_PAGES_FAILURE'
export function getMessagesTotalPagesFailure(error) {
  return {
    type: GET_MESSAGES_TOTAL_PAGES_FAILURE,
    payload: error,
  }
}

export function getMessagesTotalPages(employeeId, conversationId, pageNumber) {
  return (dispatch) => {
    dispatch(getMessagesTotalPagesRequest())
    return axiosClient
      .get(`${process.env.REACT_APP_API_URL}/v1/employees/${employeeId}/conversations/${conversationId}/messages/total_pages`, { params: { page: pageNumber },  headers: authHeaders() })
      .then((response) => {
        dispatch(getMessagesTotalPagesSuccess(response.data))
      })
      .catch((error) => {
        dispatch(getMessagesTotalPagesFailure(error))
      })
  }
}