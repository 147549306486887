import {
  SET_CURRENT_USER_MEDICATION,
  CLEAR_CURRENT_USER_MEDICATION,
  CLEAR_USER_MEDICATIONS,
  GET_USER_MEDICATIONS_REQUEST,
  GET_USER_MEDICATIONS_SUCCESS,
  GET_USER_MEDICATIONS_FAILURE,
} from '../actions/userMedications'

export const defaultState = {
  loading: false,
  userMedications: [],
  currentUserMedication: null,
}

export default (state = defaultState, action) => {
  const newState = Object.assign({}, state)

  switch (action.type) {

  	case SET_CURRENT_USER_MEDICATION:
      newState.currentUserMedication = action.payload
      return newState

    case CLEAR_CURRENT_USER_MEDICATION:
      newState.currentUserMedication = null
      return newState

    case CLEAR_USER_MEDICATIONS:
      newState.userMedications = []
      return newState

    case GET_USER_MEDICATIONS_REQUEST:
      newState.loading = true
      return newState

    case GET_USER_MEDICATIONS_SUCCESS:
      newState.loading = false
      newState.userMedications = action.payload
      return newState

    case GET_USER_MEDICATIONS_FAILURE:
      newState.loading = false
      return newState

    default:
      return state
  }
};
