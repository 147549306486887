import {
  SET_CURRENT_MEDICAL_SURVEY,
  CLEAR_CURRENT_MEDICAL_SURVEY,
  CLEAR_MEDICAL_SURVEYS,
  GET_MEDICAL_SURVEYS_REQUEST,
  GET_MEDICAL_SURVEYS_SUCCESS,
  GET_MEDICAL_SURVEYS_FAILURE,
} from '../actions/medicalSurveys'

export const defaultState = {
  loading: false,
  medicalSurveys: [],
  currentMedicalSurvey: null,
}

export default (state = defaultState, action) => {
  const newState = Object.assign({}, state)

  switch (action.type) {

  	case SET_CURRENT_MEDICAL_SURVEY:
      newState.currentMedicalSurvey = action.payload
      return newState

    case CLEAR_CURRENT_MEDICAL_SURVEY:
      newState.currentMedicalSurvey = null
      return newState

    case CLEAR_MEDICAL_SURVEYS:
      newState.medicalSurveys = []
      return newState

    case GET_MEDICAL_SURVEYS_REQUEST:
      newState.loading = true
      return newState

    case GET_MEDICAL_SURVEYS_SUCCESS:
      newState.loading = false
      newState.medicalSurveys = action.payload
      return newState

    case GET_MEDICAL_SURVEYS_FAILURE:
      newState.loading = false
      return newState

    default:
      return state
      
  }
};
