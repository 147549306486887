import React, { Component, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import _map from 'lodash/map'
import _forEach from 'lodash/forEach'
import _orderBy from 'lodash/orderBy'
import _groupBy from 'lodash/groupBy'
import _filter from 'lodash/filter'

import { getCurrentEmployeeId } from '../utils/localStorage'

import { setCurrentView } from '../actions/views'
import { getUser } from '../actions/users'
import {
  clearMessages,
  setCurrentMessage, 
  setCurrentMessagesPage, 
  clearCurrentMessage, 
  getMessagesTotalPages, 
  getMessages, 
  setMessages, 
  appendToMessages  } from '../actions/messages'
import { createConversation } from '../actions/conversations'
import { createConversationEmployee } from '../actions/conversationEmployees'

import Message from './Message'

const AlwaysScrollToBottom = () => {
  const elementRef = useRef();
  useEffect(() => elementRef.current.scrollIntoView());
  return <div ref={elementRef} />;
}

function insertParam(url, key, value) {
    key = encodeURIComponent(key);
    value = encodeURIComponent(value);

    // kvp looks like ['key1=value1', 'key2=value2', ...]
    var kvp = document.location.search.substr(1).split('&');
    let i=0;

    for(; i<kvp.length; i++){
        if (kvp[i].startsWith(key + '=')) {
            let pair = kvp[i].split('=');
            pair[1] = value;
            kvp[i] = pair.join('=');
            break;
        }
    }

    if(i >= kvp.length){
        kvp[kvp.length] = [key,value].join('=');
    }

    // can return this or...
    let params = kvp.join('&');

    return url.concat(params)
}

const ActionCable = require("actioncable")

const MESSAGE_STATUS_UNREAD = "unread"
const MESSAGE_STATUS_READ = "read"
const MESSAGEABLE_TYPE_USER = "User"

class Chat extends Component {
  constructor(props) {
    super(props)

    this.state = {
      messageList: null,
      cable: null,
      channel: null,
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.updateMessages = this.updateMessages.bind(this)
    this.loadOldMessages = this.loadOldMessages.bind(this)
  }
  componentDidMount() {
    const { getUser, setCurrentView, clearMessages, getMessages, setMessages, messageDates, messagesByDateDict, currentEmployee, currentPage, currentConversation, appendToMessages, messages, getMessagesTotalPages } = this.props


    if (currentConversation.user_id && currentConversation && currentEmployee && currentEmployee.id) {
      getUser(currentEmployee.id, currentConversation.user_id).then(() => {
        getMessages(currentEmployee.id, currentConversation.id, currentPage).then((messagesForConversation) => {
          clearMessages()

          let filteredMessagesForConversation = _filter(messagesForConversation, (message) => {
            return ((message.messageable_type === "User") && (message.messageable_id === currentConversation.user_id)) || message.messageable_type === "Employee"
          })
          setMessages(filteredMessagesForConversation)

          let url = process.env.REACT_APP_API_ACTIONCABLE_URL + "?"
          let urlWithEmployee = insertParam(url, "connection_model", "Employee")
          let urlWithEmail = insertParam(urlWithEmployee, "email", "dentologie@gmail.com")
          let urlWithAuth = insertParam(urlWithEmail, "socketToken", "abc123")

          let cable = ActionCable.createConsumer(urlWithAuth);
          let channel = cable.subscriptions.create({
              channel: `ConversationMessagesChannel`,
              conversation_id: currentConversation.id,
          },
          {
              connected: () => {
                // console.log("connected!")
                this.updateMessages()
              },
              disconnected: () => {
                // console.log("disconnected from channel")
              },
              received: (data) => {
                // console.log("data from channel")
                if (data) {

                  let isNewMessage = _filter(messages, { id: data.id }).length == 0
                  if (isNewMessage === true) {
                    // console.log(data)
                    appendToMessages(data)
                    this.updateMessages()
                    this.scrollToBottom()
                  }
                }
              },
              speak: function(action, message) {
                return this.perform(action, {
                  message: message
                })
              }
          })


          this.setState({
            cable: cable,
            channel: channel,
          })

          this.scrollToBottom()
        })
      })
    } else {
      getMessages(currentEmployee.id, currentConversation.id, currentPage).then((messagesForConversation) => {
        clearMessages()

        let filteredMessagesForConversation = _filter(messagesForConversation, (message) => {
          return ((message.messageable_type === "User") && (message.messageable_id === currentConversation.user_id)) || message.messageable_type === "Employee"
        })
        setMessages(filteredMessagesForConversation)

        let url = process.env.REACT_APP_API_ACTIONCABLE_URL + "?"
        let urlWithEmployee = insertParam(url, "connection_model", "Employee")
        let urlWithEmail = insertParam(urlWithEmployee, "email", "dentologie@gmail.com")
        let urlWithAuth = insertParam(urlWithEmail, "socketToken", "abc123")

        let cable = ActionCable.createConsumer(urlWithAuth);
        let channel = cable.subscriptions.create({
            channel: `ConversationMessagesChannel`,
            conversation_id: currentConversation.id,
        },
        {
            connected: () => {
              // console.log("connected!")
              this.updateMessages()
            },
            disconnected: () => {
              // console.log("disconnected from channel")
            },
            received: (data) => {
              // console.log("data from channel")
              if (data) {

                let isNewMessage = _filter(messages, { id: data.id }).length == 0
                if (isNewMessage === true) {
                  // console.log(data)
                  appendToMessages(data)
                  this.updateMessages()
                  this.scrollToBottom()
                }
              }
            },
            speak: function(action, message) {
              return this.perform(action, {
                message: message
              })
            }
        })


        this.setState({
          cable: cable,
          channel: channel,
        })

        this.scrollToBottom()
      })
      
      // console.log("start new")
      // clearMessages()
      // setCurrentView("inbox")
      // this.scrollToBottom()
    }
  }
  handleChange(event) {
    const { messages, setCurrentMessage } = this.props

    setCurrentMessage(event.target.value)
  }
  handleSubmit(event) {
    const { getMessages, setMessages, messageDates, messagesByDateDict, currentEmployee, currentPage, currentConversation, appendToMessages, messages, getMessagesTotalPages, currentMessage, clearCurrentMessage, currentUser, createConversation, createConversationEmployee } = this.props
    
    event.preventDefault();

    if (currentConversation) {

      const message = {
          conversation_id: currentConversation.id,
          messageable_id: currentEmployee.id,
          messageable_type: "Employee",
          message_type: "text",
          status: "unread",
          body: currentMessage.trim().replace(/^\s+|\s+$/g, '')
      }

      const messageJsonString = JSON.stringify(message)
      
      this.state.channel.speak('speak', messageJsonString)
      clearCurrentMessage()
      this.scrollToBottom()


    } else {

      const conversation = {
        user_id: currentUser.id
      }

      createConversation(currentEmployee.id, conversation).then((conversation) => {
        const conversationEmployee = {
          conversation_id: conversation.id,
          employee_id: currentEmployee.id,
        }

        createConversationEmployee(currentEmployee.id, conversationEmployee).then(() => {

            let url = process.env.REACT_APP_API_ACTIONCABLE_URL + "?"
            let urlWithEmployee = insertParam(url, "connection_model", "Employee")
            let urlWithEmail = insertParam(urlWithEmployee, "email", "dentologie@gmail.com")
            let urlWithAuth = insertParam(urlWithEmail, "socketToken", "abc123")

            let cable = ActionCable.createConsumer(urlWithAuth);
            let channel = cable.subscriptions.create({
                channel: `ConversationMessagesChannel`,
                conversation_id: conversation.id,
            },
            {
                connected: () => {
                  // console.log("connected!")
                  const message = {
                      conversation_id: conversation.id,
                      messageable_id: currentEmployee.id,
                      messageable_type: "Employee",
                      message_type: "text",
                      status: "unread",
                      body: currentMessage.trim().replace(/^\s+|\s+$/g, '')
                  }

                  const messageJsonString = JSON.stringify(message)

                  channel.speak('speak', messageJsonString)
                  clearCurrentMessage()
                  this.scrollToBottom()

                  this.setState({
                    cable: cable,
                    channel: channel,
                  })

                  getMessagesTotalPages(currentEmployee.id, conversation.id, currentPage)
                },
                disconnected: () => {
                  // console.log("disconnected from channel")
                },
                received: (data) => {
                  // console.log("data from channel")
                },
                speak: function(action, message) {
                  return this.perform(action, {
                    message: message
                  })
                }
            })
        })
      })
    }
  }
  scrollToBottom() {
    if (this.state.messageList) {
      const scrollHeight = this.messageList.scrollHeight;
      const height = this.messageList.clientHeight;
      const maxScrollTop = scrollHeight - height;
      this.messageList.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }
  }
  updateMessages() {
    const { messages, clearMessages, getMessages, setMessages, currentEmployee, currentPage, currentConversation } = this.props
    let unreadUserMessages = _filter(messages, { messageable_type: MESSAGEABLE_TYPE_USER, status: MESSAGE_STATUS_UNREAD } )

    if (unreadUserMessages.length > 0) {
      _forEach(unreadUserMessages, (message) => {
        var messageToUpdate = Object.assign({}, message)
        messageToUpdate.status = "read" 

        const messageJsonString = JSON.stringify(messageToUpdate)
        
        let channel = this.state.channel
        return new Promise(function(resolve, reject) {
            channel.speak('updateAdmin', messageJsonString)
            resolve()
        }).then(() => {
          getMessages(currentEmployee.id, currentConversation.id, currentPage).then((messagesForConversation) => {
            clearMessages()

            let filteredMessagesForConversation = _filter(messagesForConversation, (message) => {
              return ((message.messageable_type === "User") && (message.messageable_id === currentConversation.user_id)) || message.messageable_type === "Employee"
            })

            setMessages(filteredMessagesForConversation)
          })
        })
      })
    }
  }
  loadOldMessages() {
    const { getMessages, currentEmployee, currentPage, totalPages, currentConversation, setCurrentMessagesPage, appendToMessages } = this.props

    if (currentPage < totalPages) {
      let nextPage = currentPage + 1
      setCurrentMessagesPage(nextPage)
      getMessages(currentEmployee.id, currentConversation.id, nextPage).then((messages) => {

        let filteredMessagesForConversation = _filter(messages, (message) => {
          return ((message.messageable_type === "User") && (message.messageable_id === currentConversation.user_id)) || message.messageable_type === "Employee"
        })

        appendToMessages(filteredMessagesForConversation)
        this.updateMessages()
      })
    }
  }
  render() {
    const { messages, messageDates, messagesByDateDict, currentMessage, currentPage, totalPages, currentConversation, currentUser } = this.props

    var messageDivs = null
    messageDivs = _map(messageDates, (messageDate, index) => {
      var yesterday = moment().subtract(1, "day").format("YYYY-MM-DD")
      var today = moment()

      const isYesterday = moment(messageDate).isSame(yesterday, 'day')
      const isToday = moment(messageDate).isSame(today, 'day')

      var dateModifier = ''
      dateModifier = (isYesterday === true) ? 'Yesterday ' : ''
      dateModifier = (isToday === true) ? 'Today ' : ''

      let messagesForDate = messagesByDateDict[messageDate]
      let messagesForDateSorted = messagesForDate.sort((a, b) => { return moment(a.created_at).diff(b.created_at) })

      let firstDate = messagesForDateSorted[0].created_at

      let messages = _map(messagesForDateSorted, (message) => {
        return (
          <Message key={message.id} message={message} />
        )
      })

      return (
        <div key={index}>
          <h4 key={index} className="chat__messages_date_header">{dateModifier}{dateModifier === '' ? moment(firstDate).format("M/DD/YYYY") : moment(firstDate).format("h:mma")}</h4>
          { messages }
        </div>
      )
    })

    return (
      <Row className="chat__container">
        <Col xs={{ span: 12, offset: 0 }} sm={{ span: 12, offset: 0 }} md={{ span: 9, offset: 3 }} lg={{ span: 10, offset: 2 }} className="chat__main_container">
          <div>
            {
              currentConversation.user ?
              <p className="chat__messages_header"><span className="chat__messages_subheader">Your are now speaking with</span> { currentConversation.user.first_name && currentConversation.user.first_name != null ? currentConversation.user.first_name : ""  } { currentConversation.user.preferred_name && currentConversation.user.preferred_name != null ? currentConversation.user.preferred_name : ""  } { currentConversation.user.last_name && currentConversation.user.last_name != null ? currentConversation.user.last_name : "" } { currentConversation.user && currentConversation.user.date_of_birth ? `(${moment(currentConversation.user.date_of_birth).format("M/D/YYYY")})` : '' }...</p>
              :
              <p className="chat__messages_header"><span className="chat__messages_subheader">Your are now speaking with</span> Non-Existent Patient...</p>
            }
          </div>
          <div 
            className="chat__messages_container"
            ref={(div) => {
              this.messageList = div;
            }}
          >
            {
              (totalPages > 1) && (currentPage < totalPages) ?
              <div className="chat__load_old_messages_link_container">
                <a className="chat__load_old_messages_link" onClick={this.loadOldMessages}>Load Old Messages</a>
              </div>
              :
              ''
            }
            { messageDivs }
            <AlwaysScrollToBottom />
          </div>
          <Row className="chat__message_input_field_container">
            <Col xs={12} className="chat__message_input_field_col_container">
              <textarea type="text" className="chat__message_input_field" value={currentMessage} onChange={this.handleChange} />
              <button className="chat__message_input_field_button" onClick={this.handleSubmit}>Send</button>
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }
}

export default connect(
  state => ({
    currentEmployee: state.auth.currentEmployee,
    currentMessage: state.messages.currentMessage,
    messages: state.messages.messages,
    messageDates: state.messages.messageDates,
    messagesByDateDict: state.messages.messagesByDateDict,
    currentPage: state.messages.currentPage,
    totalPages: state.messages.totalPages,
    currentConversation: state.conversations.currentConversation,
    currentUser: state.users.currentUser,
  }),
  dispatch => bindActionCreators({
    clearMessages,
    setCurrentMessage,
    setCurrentMessagesPage,
    clearCurrentMessage,
    getUser,
    setCurrentView,
    getMessages,
    setMessages,
    getMessagesTotalPages,
    appendToMessages,
    createConversation,
    createConversationEmployee,
  }, dispatch),
)(Chat)
