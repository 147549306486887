import {
  SET_CURRENT_VIEW,
  CLEAR_SEARCH_TEXT,
  SET_SEARCH_TEXT,
} from '../actions/views'

export const defaultState = {
  currentSearchText: '',
  currentView: 'inbox',
}

export default (state = defaultState, action) => {
  const newState = Object.assign({}, state)

  switch (action.type) {
    case SET_CURRENT_VIEW:
      newState.currentView = action.payload
      return newState

    case CLEAR_SEARCH_TEXT:
      newState.currentSearchText = ''
      return newState

    case SET_SEARCH_TEXT:
      newState.currentSearchText = action.payload
      return newState

    default:
      return state
  }
}
