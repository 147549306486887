import {
  SET_USER_SEARCH_TEXT,
  SET_CURRENT_USER,
  SET_CURRENT_USER_ID,
  CLEAR_CURRENT_USER,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,  
  GET_NO_SSN_USERS_REQUEST,
  GET_NO_SSN_USERS_SUCCESS,
  GET_NO_SSN_USERS_FAILURE,  
  GET_NO_INSURANCE_USERS_REQUEST,
  GET_NO_INSURANCE_USERS_SUCCESS,
  GET_NO_INSURANCE_USERS_FAILURE,
  GET_SEARCH_USERS_REQUEST,
  GET_SEARCH_USERS_SUCCESS,
  GET_SEARCH_USERS_FAILURE,
  GET_USER_PROFILE_PICTURE_REQUEST,
  GET_USER_PROFILE_PICTURE_SUCCESS,
  GET_USER_PROFILE_PICTURE_FAILURE,
  CLEAR_SEARCH_USERS,
} from '../actions/users'

export const defaultState = {
  loadingCurrentUser: false,
  currentUserId: null,
  currentUser: null,
  loadingSearchUsers: false,
  searchUsers: [],
  userSearchText: '',
  loadingNoSsnUsers: false,
  noSsnUsers: [],
  loadingNoInsuranceUsers: false,
  noInsuranceUsers: [],
  userImageUrlLoading: false,
  userImageUrls: {},
}

export default (state = defaultState, action) => {
  const newState = Object.assign({}, state)

  switch (action.type) {

    case GET_USER_REQUEST:
      newState.loadingCurrentUser = true
      return newState

    case GET_USER_SUCCESS:
      newState.loadingCurrentUser = false
      newState.currentUser = action.payload
      return newState

    case GET_USER_FAILURE:
      newState.loadingCurrentUser = false
      return newState

    case GET_NO_SSN_USERS_REQUEST:
      newState.loadingNoSsnUsers = true
      return newState

    case GET_NO_SSN_USERS_SUCCESS:
      newState.loadingNoSsnUsers = false
      newState.noSsnUsers = action.payload
      return newState

    case GET_NO_SSN_USERS_FAILURE:
      newState.loadingNoSsnUsers = false
      return newState

    case GET_NO_INSURANCE_USERS_REQUEST:
      newState.loadingNoInsuranceUsers = true
      return newState

    case GET_NO_INSURANCE_USERS_SUCCESS:
      newState.loadingNoInsuranceUsers = false
      newState.noInsuranceUsers = action.payload
      return newState

    case GET_NO_INSURANCE_USERS_FAILURE:
      newState.loadingNoInsuranceUsers = false
      return newState

    case GET_SEARCH_USERS_REQUEST:
      newState.loadingSearchUsers = true
      return newState

    case GET_SEARCH_USERS_SUCCESS:
      newState.loadingSearchUsers = false
      newState.searchUsers = action.payload
      return newState

    case GET_SEARCH_USERS_FAILURE:
      newState.loadingSearchUsers = false
      return newState

    case CLEAR_SEARCH_USERS:
      newState.searchUsers = []
      return newState

    case SET_CURRENT_USER:
      newState.currentUser = action.payload
      return newState

    case SET_CURRENT_USER_ID:
      newState.currentUserId = action.payload
      return newState

    case CLEAR_CURRENT_USER:
      newState.currentUserId = null
      newState.currentUser = null
      return newState

    case SET_USER_SEARCH_TEXT:
      newState.userSearchText = action.payload
      return newState

    case GET_USER_PROFILE_PICTURE_REQUEST:
      newState.userImageUrlLoading = true
      return newState

    case GET_USER_PROFILE_PICTURE_SUCCESS:
      newState.userImageUrlLoading = false
      newState.userImageUrls = Object.assign({}, state.userImageUrls, action.payload)
      return newState

    case GET_USER_PROFILE_PICTURE_FAILURE:
      newState.userImageUrlLoading = false
      return newState

    default:
      return state
  }
};
