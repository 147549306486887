import {
  SET_CURRENT_CONVERSATION,
  CLEAR_CURRENT_CONVERSATION,
  CLEAR_CONVERSATIONS,
  SET_CURRENT_CONVERSATION_PAGE,
  GET_CONVERSATIONS_REQUEST,
  GET_CONVERSATIONS_SUCCESS,
  GET_CONVERSATIONS_FAILURE,
  CLEAR_CONVERSATIONS_TOTAL_PAGES,
  GET_CONVERSATIONS_TOTAL_PAGES_REQUEST,
  GET_CONVERSATIONS_TOTAL_PAGES_SUCCESS,
  GET_CONVERSATIONS_TOTAL_PAGES_FAILURE,
  GET_CONVERSATIONS_FOR_USER_NAME_REQUEST,
  GET_CONVERSATIONS_FOR_USER_NAME_SUCCESS,
  GET_CONVERSATIONS_FOR_USER_NAME_FAILURE,
  GET_CONVERSATIONS_FOR_USER_REQUEST,
  GET_CONVERSATIONS_FOR_USER_SUCCESS,
  GET_CONVERSATIONS_FOR_USER_FAILURE,
  GET_CONVERSATIONS_FOR_USER_TOTAL_PAGES_REQUEST,
  GET_CONVERSATIONS_FOR_USER_TOTAL_PAGES_SUCCESS,
  GET_CONVERSATIONS_FOR_USER_TOTAL_PAGES_FAILURE,
  CREATE_CONVERSATION_REQUEST,
  CREATE_CONVERSATION_SUCCESS,
  CREATE_CONVERSATION_FAILURE,
} from '../actions/conversations'

export const defaultState = {
  loading: false,
  currentPage: 1,
  totalPages: 1,
  conversations: [],
  currentConversation: null,
  conversationTotalPages: null,
}

export default (state = defaultState, action) => {
  const newState = Object.assign({}, state)

  switch (action.type) {

    case GET_CONVERSATIONS_REQUEST:
      newState.loading = true
      return newState

    case GET_CONVERSATIONS_SUCCESS:
      newState.loading = false
      newState.conversations = action.payload
      return newState

    case GET_CONVERSATIONS_FAILURE:
      newState.loading = false
      return newState

    case GET_CONVERSATIONS_FOR_USER_NAME_REQUEST:
      newState.loading = true
      return newState

    case GET_CONVERSATIONS_FOR_USER_NAME_SUCCESS:
      newState.loading = false
      newState.conversations = action.payload
      return newState

    case GET_CONVERSATIONS_FOR_USER_NAME_FAILURE:
      newState.loading = false
      return newState

    case GET_CONVERSATIONS_FOR_USER_REQUEST:
      newState.loading = true
      return newState

    case GET_CONVERSATIONS_FOR_USER_SUCCESS:
      newState.loading = false
      newState.conversations = action.payload
      return newState

    case GET_CONVERSATIONS_FOR_USER_FAILURE:
      newState.loading = false
      return newState

    case GET_CONVERSATIONS_FOR_USER_TOTAL_PAGES_REQUEST:
      newState.loading = true
      return newState

    case GET_CONVERSATIONS_FOR_USER_TOTAL_PAGES_SUCCESS:
      newState.loading = false
      newState.totalPages = action.payload
      return newState

    case GET_CONVERSATIONS_FOR_USER_TOTAL_PAGES_FAILURE:
      newState.loading = false
      return newState

    case SET_CURRENT_CONVERSATION:
      newState.currentConversation = action.payload
      return newState

    case CLEAR_CURRENT_CONVERSATION:
      newState.currentConversation = null
      return newState

    case CLEAR_CONVERSATIONS:
      newState.conversations = []
      return newState

    case SET_CURRENT_CONVERSATION_PAGE:
      newState.currentPage = action.payload
      return newState

    case CLEAR_CONVERSATIONS_TOTAL_PAGES:
      newState.totalPages = 1
      return newState

    case GET_CONVERSATIONS_TOTAL_PAGES_REQUEST:
      newState.loading = true
      return newState

    case GET_CONVERSATIONS_TOTAL_PAGES_SUCCESS:
      newState.loading = false
      newState.totalPages = action.payload
      return newState

    case GET_CONVERSATIONS_TOTAL_PAGES_FAILURE:
      newState.loading = false
      return newState

    case CREATE_CONVERSATION_REQUEST:
      newState.loading = true
      return newState

    case CREATE_CONVERSATION_SUCCESS:
      newState.loading = false
      newState.currentConversation = action.payload
      return newState

    case CREATE_CONVERSATION_FAILURE:
      newState.loading = false
      return newState

    default:
      return state
  }
};
