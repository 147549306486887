import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import AWS from 'aws-sdk'

import { setCurrentView } from '../actions/views'
import { clearCurrentUser, setCurrentUserId, clearSearchUsers, getUser } from '../actions/users'
import { getAppointments } from '../actions/appointments'
import { getInsurancePolicies } from '../actions/insurancePolicies'
import { getMedicalSurveyCollections } from '../actions/medicalSurveyCollections'
import { getMedicalSurveys } from '../actions/medicalSurveys'
import { getMedicalSurveyQuestions } from '../actions/medicalSurveyQuestions'
import { getUserAllergies } from '../actions/userAllergies'
import { getUserMedications } from '../actions/userMedications'


function getPresignedUrl(currentUser) {
  const s3 = new AWS.S3();
  s3.config.update({credentials: AWS.config.credentials})
  var imageIdentifier = /[^/]*$/.exec(currentUser.image_url)[0]
  let fileKey = `users/${currentUser.id}/profile-pictures/${imageIdentifier}`
  const params = {
    Bucket: process.env.REACT_APP_AWS_S3_BUCKET,
    Key: fileKey,
    Expires: 60
  };

  const presignedUrl = s3.getSignedUrl("getObject", params);
  return presignedUrl
}

class EmployeeSearchUserCard extends Component {
  constructor(props) {
    super(props)

    this.state = {
      patientImageUrl: null,
    }

    this.selectUser = this.selectUser.bind(this)
  }
  componentDidMount() {
    const { user } = this.props

    if (user) {
      this.setState({
        avatarImage: getPresignedUrl(user)
      })
    }

  	if (user && user.image_url) {
        this.setState({ patientImageUrl: getPresignedUrl(user) })
    }
  }
  selectUser(e) {
    const { user, 
      currentEmployee, 
      clearCurrentUser, 
      setCurrentUserId, 
      setCurrentView, 
      clearSearchUsers, 
      getUser, 
      getAppointments, 
      getInsurancePolicies, 
      getMedicalSurveyCollections, 
      getMedicalSurveys, 
      getMedicalSurveyQuestions,
      getUserAllergies,
      getUserMedications,
    } = this.props

    e.preventDefault()
    clearCurrentUser()

    setCurrentView("patientProfile")
    setCurrentUserId(user.id)
    getUser(currentEmployee.id, user.id).then(() => {
      getAppointments(currentEmployee.id, user.id)
      getInsurancePolicies(currentEmployee.id, user.id)
      getUserMedications(currentEmployee.id, user.id)
      getUserAllergies(currentEmployee.id, user.id)
      getMedicalSurveyCollections(currentEmployee.id, user.id).then((medicalSurveyCollection) => {
        getMedicalSurveys(medicalSurveyCollection.id, currentEmployee.id, user.id).then((medicalSurveys) => {
          getMedicalSurveyQuestions(medicalSurveys[0].id, currentEmployee.id, user.id)
        })
      })
      clearSearchUsers()
    })
    
  }
  render() {
    const { user } = this.props

    return (
     <Row className="employee_search_user__container" onClick={this.selectUser}>
	    <Col xs={1} className="employee_search_user__main_container">
        <div className="employee_search_user__image_container">
  	       <img className="employee_search_user__image" src={ this.state.patientImageUrl ? this.state.patientImageUrl : "" } />
        </div>
	     </Col> 
	     <Col xs={3}>
	       <p className="employee_search_user__name">{user.first_name} {user.last_name}</p>
         <p className="employee_search_user__preferred_name">Preferred Name: {user.preferred_name || "N/A" }</p>
	     </Col>   
	     <Col xs={3}>
	       <p className="employee_search_user__ehr_id">OpenDental ID: {user.ehr_identifier}</p>
         <p className="employee_search_user__app_id">App ID: {user.id || "N/A" }</p>
	     </Col>   
       <Col xs={2}>
         <p className="employee_search_user__date_of_birth">DOB: {moment(user.date_of_birth).format('l')}</p>
       </Col>   
       <Col xs={3}>
         <p className="employee_search_user__practice">{user.practice_display_name}</p>
       </Col>
     </Row>
    )
  }
}

export default connect(
  state => ({
    currentEmployee: state.auth.currentEmployee,
  }),
  dispatch => bindActionCreators({
    clearCurrentUser,
  	setCurrentUserId,
  	setCurrentView,
  	clearSearchUsers,
    getUser,
    getAppointments,
    getInsurancePolicies,
    getMedicalSurveyCollections,
    getMedicalSurveys,
    getMedicalSurveyQuestions,
    getUserAllergies,
    getUserMedications,
  }, dispatch),
)(EmployeeSearchUserCard)
