import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Row, Col } from 'react-bootstrap'
import { bindActionCreators } from 'redux'
import _filter from 'lodash/filter'

import { setCurrentView, clearSearchText } from '../actions/views'
import { clearMessages } from '../actions/messages'
import { logoutEmployee, clearCurrentEmployee } from '../actions/auth'

import logo from '../images/logo.png'

class NavMenu extends Component {
  constructor(props) {
    super(props)

    this.updateView = this.updateView.bind(this)
    this.logout = this.logout.bind(this)
  }
  updateView(view) {
    const { setCurrentView, clearMessages, clearSearchText } = this.props

    clearMessages()
    clearSearchText()
    setCurrentView(view)
  }
  logout() {
    const { logoutEmployee, clearCurrentEmployee } = this.props

    logoutEmployee()
    clearCurrentEmployee()
    this.props.history.push("/login")
  }
  render() {
    const { conversations, currentEmployee } = this.props

    let hasConversationsWithNewMessages = _filter(conversations, { has_unread_user_messages: true } ).length > 0
    return (
      <Row>
        <Col lg={2} md={3} className="nav_menu___container d-none d-md-block">
          <Row>
            <Col xs={12}>
              <img src={logo} className="nav_menu__logo_image" onClick={() => this.updateView("inbox")}/>
            </Col>
          </Row>
          <Row>
            <Col xs={9}>
              <p className="nav_menu__link" onClick={() => this.updateView("inbox")}>Inbox</p>
            </Col>
            <Col xs={3}>
              <span className="nav_menu__new_message">{ hasConversationsWithNewMessages === true ? 'New' : '' }</span>
            </Col>
          </Row>
          <Row>
            <Col xs={9}>
              <p className="nav_menu__link" onClick={() => this.updateView("patientProfile")}>Patient Profile</p>
            </Col>
            <Col xs={3}>
              <span></span>
            </Col>
          </Row>
          <Row>
            <Col xs={9}>
              <p className="nav_menu__link" onClick={() => this.updateView("repairIssuesList")}>Repair Issues</p>
            </Col>
            <Col xs={3}>
              <span></span>
            </Col>
          </Row>
          <Row>
            <Col xs={9}>
              <p className="nav_menu__link" onClick={() => this.updateView("noSsnUsersList")}>No SSN</p>
              <p className="nav_menu__link" onClick={() => this.updateView("noInsuranceUsersList")}>No Insurance</p>
              {
                currentEmployee && currentEmployee.role && (currentEmployee.role === "admin" || currentEmployee.role === "dentist")  ?
                <p className="nav_menu__link" onClick={() => this.updateView("production")}>Production</p>
                :
                ''
              }
              <p className="nav_menu__link" onClick={() => this.updateView("scheduleGenerator")}>Schedule Maker</p>
              <p className="nav_menu__link" onClick={() => this.logout()}>Log Out</p>
            </Col>
            <Col xs={3}>
              <span></span>
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }
}

export default withRouter(connect(
  state => ({
    currentEmployee: state.auth.currentEmployee,
    conversations: state.conversations.conversations,
  }),
  dispatch => bindActionCreators({
    setCurrentView,
    clearSearchText,
    clearMessages,
    logoutEmployee,
    clearCurrentEmployee,
  }, dispatch),
)(NavMenu))
