import moment from 'moment'
import {
  CLEAR_CURRENT_EMPLOYEE_PRODUCTION_DATE_RANGE,
  SET_CURRENT_EMPLOYEE_PRODUCTION_DATE_RANGE,
  CLEAR_CURRENT_EMPLOYEE_PRODUCTION_IDS,
  SET_CURRENT_EMPLOYEE_PRODUCTION_IDS,
  CLEAR_EMPLOYEE_PRODUCTION_STATS,
  GET_EMPLOYEE_PRODUCTION_STATS_REQUEST,
  GET_EMPLOYEE_PRODUCTION_STATS_SUCCESS,
  GET_EMPLOYEE_PRODUCTION_STATS_FAILURE,
  CLEAR_INITIAL_VALUES_FOR_PRACTICE_PRODUCTION_FORM,
  SET_INITIAL_VALUES_FOR_PRACTICE_PRODUCTION_FORM,
  CLEAR_CURRENT_PRACTICE_PRODUCTION_IDS,
  SET_CURRENT_PRACTICE_PRODUCTION_IDS,
  CLEAR_PRACTICE_PRODUCTION_STATS,
  GET_PRACTICE_PRODUCTION_STATS_REQUEST,
  GET_PRACTICE_PRODUCTION_STATS_SUCCESS,
  GET_PRACTICE_PRODUCTION_STATS_FAILURE,
  CLEAR_INITIAL_VALUES_FOR_EMPLOYEE_PRODUCTION_FORM,
  SET_INITIAL_VALUES_FOR_EMPLOYEE_PRODUCTION_FORM,
} from '../actions/stats'



export const defaultState = {
  loading: false,
  employeeProductionDateRange: { startDate: moment().clone().startOf('month'), endDate: moment().clone().endOf('month')},
  employeeProductionIds: [],
  employeeProductionStats: [],
  employeeProductionFormInitialValues: { checkedEmployees: [] },
  practiceProductionIds: [],
  practiceProductionStats: [],
  practiceProductionFormInitialValues: { checkedPractices: [] },
}

export default (state = defaultState, action) => {
  const newState = Object.assign({}, state)

  switch (action.type) {

    case CLEAR_CURRENT_EMPLOYEE_PRODUCTION_DATE_RANGE:
      newState.employeeProductionDateRange = { startDate: moment().clone().startOf('month'), endDate: moment().clone().endOf('month')}
      return newState

    case SET_CURRENT_EMPLOYEE_PRODUCTION_DATE_RANGE:
      newState.employeeProductionDateRange = action.payload
      return newState

    case CLEAR_CURRENT_EMPLOYEE_PRODUCTION_IDS:
      newState.employeeProductionIds = []
      return newState

    case SET_CURRENT_EMPLOYEE_PRODUCTION_IDS:
      newState.employeeProductionIds = action.payload
      return newState

    case CLEAR_EMPLOYEE_PRODUCTION_STATS:
      newState.employeeProductionStats = []
      return newState

    case GET_EMPLOYEE_PRODUCTION_STATS_REQUEST:
      newState.loading = true
      return newState

    case GET_EMPLOYEE_PRODUCTION_STATS_SUCCESS:
      newState.loading = false
      newState.employeeProductionStats = action.payload
      return newState

    case GET_PRACTICE_PRODUCTION_STATS_FAILURE:
      newState.loading = false
      return newState

    case CLEAR_CURRENT_PRACTICE_PRODUCTION_IDS:
      newState.practiceProductionIds = []
      return newState

    case SET_CURRENT_PRACTICE_PRODUCTION_IDS:
      newState.practiceProductionIds = action.payload
      return newState

    case CLEAR_PRACTICE_PRODUCTION_STATS:
      newState.employeeProductionStats = []
      return newState

    case CLEAR_PRACTICE_PRODUCTION_STATS:
      newState.practiceProductionStats = []
      return newState

    case GET_PRACTICE_PRODUCTION_STATS_REQUEST:
      newState.loading = true
      return newState

    case GET_PRACTICE_PRODUCTION_STATS_SUCCESS:
      newState.loading = false
      newState.practiceProductionStats = action.payload
      return newState

    case GET_PRACTICE_PRODUCTION_STATS_FAILURE:
      newState.loading = false
      return newState

    case CLEAR_INITIAL_VALUES_FOR_EMPLOYEE_PRODUCTION_FORM:
      newState.loading = false
      newState.employeeProductionFormInitialValues = { checkedEmployees: [] }
      return newState

    case SET_INITIAL_VALUES_FOR_EMPLOYEE_PRODUCTION_FORM:
      newState.loading = false
      newState.employeeProductionFormInitialValues = action.payload
      return newState

    case CLEAR_INITIAL_VALUES_FOR_PRACTICE_PRODUCTION_FORM:
      newState.loading = false
      newState.practiceProductionFormInitialValues = { checkedPractices: [] }
      return newState

    case SET_INITIAL_VALUES_FOR_PRACTICE_PRODUCTION_FORM:
      newState.loading = false
      newState.practiceProductionFormInitialValues = action.payload
      return newState

    default:
      return state
  }
};
