import axiosClient from '../utils/axios'
import { authHeaders } from '../utils/localStorage'


export const CREATE_OPERATORY_SCHEDULE_RULE_REQUEST = 'CREATE_OPERATORY_SCHEDULE_RULE_REQUEST'
export function createOperatoryScheduleRuleRequest() {
  return {
    type: CREATE_OPERATORY_SCHEDULE_RULE_REQUEST,
  }
}

export const CREATE_OPERATORY_SCHEDULE_RULE_SUCCESS = 'CREATE_OPERATORY_SCHEDULE_RULE_SUCCESS'
export function createOperatoryScheduleRuleSuccess(operatoryScheduleRule) {
  return {
    type: CREATE_OPERATORY_SCHEDULE_RULE_SUCCESS,
    payload: operatoryScheduleRule,
  }
}

export const CREATE_OPERATORY_SCHEDULE_RULE_FAILURE = 'CREATE_OPERATORY_SCHEDULE_RULE_FAILURE'
export function createOperatoryScheduleRuleFailure(error) {
  return {
    type: CREATE_OPERATORY_SCHEDULE_RULE_FAILURE,
    payload: error,
  }
}

export function createOperatoryScheduleRule(employeeId, formattedOperatoryScheduleRule) {
  return (dispatch) => {
    dispatch(createOperatoryScheduleRuleRequest())
    return axiosClient
      .post(`${process.env.REACT_APP_API_URL}/v1/employees/${employeeId}/operatory_schedule_rules`, { operatory_schedule_rule: formattedOperatoryScheduleRule }, { headers: authHeaders() })
      .then((response) => {
        dispatch(createOperatoryScheduleRuleSuccess(response.data))
        return Promise.resolve(response.data)
      })
      .catch((error) => {
        dispatch(createOperatoryScheduleRuleFailure(error))
        return Promise.reject(error)
      })
  }
}


export const CLEAR_OPERATORY_SCHEDULE_RULES = 'CLEAR_OPERATORY_SCHEDULE_RULES'
export function clearOperatoryScheduleRules() {
  return {
    type: CLEAR_OPERATORY_SCHEDULE_RULES,
  }
}

export const GET_OPERATORY_SCHEDULE_RULES_REQUEST = 'GET_OPERATORY_SCHEDULE_RULES_REQUEST'
export function getOperatoryScheduleRulesRequest() {
  return {
    type: GET_OPERATORY_SCHEDULE_RULES_REQUEST,
  }
}

export const GET_OPERATORY_SCHEDULE_RULES_SUCCESS = 'GET_OPERATORY_SCHEDULE_RULES_SUCCESS'
export function getOperatoryScheduleRulesSuccess(operatoryScheduleRules) {
  return {
    type: GET_OPERATORY_SCHEDULE_RULES_SUCCESS,
    payload: operatoryScheduleRules,
  }
}

export const GET_OPERATORY_SCHEDULE_RULES_FAILURE = 'GET_OPERATORY_SCHEDULE_RULES_FAILURE'
export function getOperatoryScheduleRulesFailure(error) {
  return {
    type: GET_OPERATORY_SCHEDULE_RULES_FAILURE,
    payload: error,
  }
}

export function getOperatoryScheduleRules(employeeId, operatoryId, currentDate) {
  return (dispatch) => {
    dispatch(getOperatoryScheduleRulesRequest())
    return axiosClient
      .get(`${process.env.REACT_APP_API_URL}/v1/employees/${employeeId}/operatory_schedule_rules`, { params: { date: currentDate, operatory_id: operatoryId },  headers: authHeaders() })
      .then((response) => {
        dispatch(getOperatoryScheduleRulesSuccess(response.data))
        return Promise.resolve(response.data)
      })
      .catch((error) => {
        dispatch(getOperatoryScheduleRulesFailure(error))
        return Promise.reject(error)
      })
  }
}