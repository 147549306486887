import axiosClient from '../utils/axios'
import { authHeaders } from '../utils/localStorage'

export const SET_CURRENT_USER_MEDICATION = 'SET_CURRENT_USER_MEDICATION'
export function setCurrentUserMedication(userMedication) {
  return {
    type: SET_CURRENT_USER_MEDICATION,
    payload: userMedication,
  }
}

export const CLEAR_CURRENT_USER_MEDICATION = 'CLEAR_CURRENT_USER_MEDICATION'
export function clearCurrentUserMedication() {
  return {
    type: CLEAR_CURRENT_USER_MEDICATION,
  }
}

export const CLEAR_USER_MEDICATIONS = 'CLEAR_USER_MEDICATIONS'
export function clearUserMedications() {
  return {
    type: CLEAR_USER_MEDICATIONS,
  }
}

export const GET_USER_MEDICATIONS_REQUEST = 'GET_USER_MEDICATIONS_REQUEST'
export function getUserMedicationsRequest() {
  return {
    type: GET_USER_MEDICATIONS_REQUEST,
  }
}

export const GET_USER_MEDICATIONS_SUCCESS = 'GET_USER_MEDICATIONS_SUCCESS'
export function getUserMedicationsSuccess(userMedications) {
  return {
    type: GET_USER_MEDICATIONS_SUCCESS,
    payload: userMedications,
  }
}

export const GET_USER_MEDICATIONS_FAILURE = 'GET_USER_MEDICATIONS_FAILURE'
export function getUserMedicationsFailure(error) {
  return {
    type: GET_USER_MEDICATIONS_FAILURE,
    payload: error,
  }
}

export function getUserMedications(employeeId, userId) {
  return (dispatch) => {
    dispatch(getUserMedicationsRequest())
    return axiosClient
      .get(`${process.env.REACT_APP_API_URL}/v1/employees/${employeeId}/users/${userId}/user_medications`, { headers: authHeaders() })
      .then((response) => {
        dispatch(getUserMedicationsSuccess(response.data))
      })
      .catch((error) => {
        dispatch(getUserMedicationsFailure(error))
      })
  }
}