import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import { bindActionCreators } from 'redux'

let formatPhoneNumber = (str) => {
  let cleaned = ('' + str).replace(/\D/g, '')
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3]
  }

  return null
}

class NoInsuranceUserCard extends Component {
  render() {
    const { user } = this.props

    return (
      <div className="no_ssn_user_card__main_container" >
        <Row className="no_ssn_user_card__container">
          <Col xs={3}>
            <h3 className="no_ssn_user_card__name">{ user.FName } { user.LName }</h3>
          </Col>
          <Col xs={3}>
            <h3 className="no_ssn_user_card__name">Open Dental ID: { user.PatNum ? user.PatNum : 'N/A'  }</h3>
          </Col>
          <Col xs={3}>
            <h3 className="no_ssn_user_card__email">Email: { user.Email }</h3>
          </Col>
          <Col xs={3}>
            <h3 className="no_ssn_user_card__phone">Phone: { user.WirelessPhone !== '' ? formatPhoneNumber(user.WirelessPhone) : user.HmPhone }</h3>
          </Col>
        </Row>
      </div>
    )
  }
}

export default connect(
  state => ({
  }),
  dispatch => bindActionCreators({
  }, dispatch),
)(NoInsuranceUserCard)
