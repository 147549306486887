import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import { bindActionCreators } from 'redux'
import moment from 'moment'

class AppointmentCard extends Component {
  render() {
    const { appointment } = this.props

    return (
      <div className="appointment_card__container" >
        <Row>
          <Col xs={12}>
            <div className="appointment_card__main_container" >
              <h3 className="appointment_card__title">{ appointment.description } <span className="appointment_card__duration_label">({ appointment.duration_min } min.)</span></h3>
              <p className="appointment_card__time_label">{ moment().format("MMM Do YYYY") } @ { moment(appointment.start_date).format("h:mma") }—{ moment(appointment.end_date).format("h:mma") }</p>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default connect(
  state => ({
  }),
  dispatch => bindActionCreators({
  }, dispatch),
)(AppointmentCard)
