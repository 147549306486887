export const SET_CURRENT_VIEW = 'SET_CURRENT_VIEW'
export function setCurrentView(view) {
  return {
    type: SET_CURRENT_VIEW,
    payload: view,
  }
}

export const CLEAR_SEARCH_TEXT = 'CLEAR_SEARCH_TEXT'
export function clearSearchText() {
  return {
    type: CLEAR_SEARCH_TEXT,
  }
}

export const SET_SEARCH_TEXT = 'SET_SEARCH_TEXT'
export function setSearchText(text) {
  return {
    type: SET_SEARCH_TEXT,
    payload: text,
  }
}