import {
  CLEAR_OPERATORY_SCHEDULE_RULES,
  GET_OPERATORY_SCHEDULE_RULES_REQUEST,
  GET_OPERATORY_SCHEDULE_RULES_SUCCESS,
  GET_OPERATORY_SCHEDULE_RULES_FAILURE,
  CREATE_OPERATORY_SCHEDULE_RULE_REQUEST,
  CREATE_OPERATORY_SCHEDULE_RULE_SUCCESS,
  CREATE_OPERATORY_SCHEDULE_RULE_FAILURE,
} from '../actions/operatoryScheduleRules'

export const defaultState = {
  loading: false,
  operatoryScheduleRules: [],
}

export default (state = defaultState, action) => {
  const newState = Object.assign({}, state)

  switch (action.type) {

    case CREATE_OPERATORY_SCHEDULE_RULE_REQUEST:
      newState.loading = true
      return newState

    case CREATE_OPERATORY_SCHEDULE_RULE_SUCCESS:
      newState.loading = false
      return newState

    case CREATE_OPERATORY_SCHEDULE_RULE_FAILURE:
      newState.loading = false
      return newState

    case CLEAR_OPERATORY_SCHEDULE_RULES:
      newState.operatoryScheduleRules  = []
      return newState

    case GET_OPERATORY_SCHEDULE_RULES_REQUEST:
      newState.loading = true
      return newState

    case GET_OPERATORY_SCHEDULE_RULES_SUCCESS:
      newState.loading = false
      newState.operatoryScheduleRules = action.payload
      return newState

    case GET_OPERATORY_SCHEDULE_RULES_FAILURE:
      newState.loading = false
      return newState

    default:
      return state
  }
};
