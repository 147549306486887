import axiosClient from '../utils/axios'
import { authHeaders } from '../utils/localStorage'

export const SET_CURRENT_APPOINTMENT = 'SET_CURRENT_APPOINTMENT'
export function setCurrentAppointment(message) {
  return {
    type: SET_CURRENT_APPOINTMENT,
    payload: message,
  }
}

export const CLEAR_CURRENT_APPOINTMENT = 'CLEAR_CURRENT_APPOINTMENT'
export function clearCurrentAppointment() {
  return {
    type: CLEAR_CURRENT_APPOINTMENT,
  }
}

export const CLEAR_APPOINTMENTS = 'CLEAR_APPOINTMENTS'
export function clearAppointments() {
  return {
    type: CLEAR_APPOINTMENTS,
  }
}

export const GET_APPOINTMENTS_REQUEST = 'GET_APPOINTMENTS_REQUEST'
export function getAppointmentsRequest() {
  return {
    type: GET_APPOINTMENTS_REQUEST,
  }
}

export const GET_APPOINTMENTS_SUCCESS = 'GET_APPOINTMENTS_SUCCESS'
export function getAppointmentsSuccess(appointments) {
  return {
    type: GET_APPOINTMENTS_SUCCESS,
    payload: appointments,
  }
}

export const GET_APPOINTMENTS_FAILURE = 'GET_APPOINTMENTS_FAILURE'
export function getAppointmentsFailure(error) {
  return {
    type: GET_APPOINTMENTS_FAILURE,
    payload: error,
  }
}

export function getAppointments(employeeId, userId) {
  return (dispatch) => {
    dispatch(getAppointmentsRequest())
    return axiosClient
      .get(`${process.env.REACT_APP_API_URL}/v1/employees/${employeeId}/users/${userId}/appointments`, { headers: authHeaders() })
      .then((response) => {
        dispatch(getAppointmentsSuccess(response.data))
        return response.data
      })
      .catch((error) => {
        dispatch(getAppointmentsFailure(error))
      })
  }
}