import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import { bindActionCreators } from 'redux'
import _filter from 'lodash/filter'
import _map from 'lodash/map'

class MedicalSurveyQuestionCard extends Component {
  render() {
    const { medicalSurveyQuestion, currentUserAllergies, currentUserMedications } = this.props

    let answers = _filter(medicalSurveyQuestion.answers, { selected: true } )
    var answer = null
    if (answers.length > 0) {
    	answer = _map(answers, (answer) => {
    		return (
    			<p className="medical_survey_question_card__answer">{answer.body}</p>
    		)
    	})
    } else {
    	answer = (
    		<p className="medical_survey_question_card__answer">Not answered</p>
    	)
    }

    var allergies = null
    if (medicalSurveyQuestion.status === "complete" && medicalSurveyQuestion.rank === 8) {
      allergies = _map(currentUserAllergies, (userAllergy) => {
        return (
          <p className="medical_survey_question_card__user_allergy">{userAllergy.description}</p>
        )
      })
    }

    var medications = null
    if (medicalSurveyQuestion.status === "complete" && medicalSurveyQuestion.rank === 9) {
      medications = _map(currentUserMedications, (userMedication) => {
        return (
          <p className="medical_survey_question_card__user_medication">{userMedication.description}</p>
        )
      })
    }

    return (
      <div className="medical_survey_question_card__container" >
        <Row>
          <Col xs={12}>
            <div className="medical_survey_question_card__main_container" >
              <p className="medical_survey_question_card__info"><span className="medical_survey_question_card__info_label">Q:</span> { medicalSurveyQuestion.body }</p>
              { answer }
              { allergies }
              { medications }
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default connect(
  state => ({
    currentUserAllergies: state.userAllergies.userAllergies,
    currentUserMedications: state.userMedications.userMedications,
  }),
  dispatch => bindActionCreators({
  }, dispatch),
)(MedicalSurveyQuestionCard)
