import {
  GET_APPOINTMENT_TYPES_REQUEST,
  GET_APPOINTMENT_TYPES_SUCCESS,
  GET_APPOINTMENT_TYPES_FAILURE,
} from '../actions/appointmentTypes'

export const defaultState = {
  loading: false,
  appointmentTypes: [],
}

export default (state = defaultState, action) => {
  const newState = Object.assign({}, state)

  switch (action.type) {

    case GET_APPOINTMENT_TYPES_REQUEST:
      newState.loading = true
      return newState

    case GET_APPOINTMENT_TYPES_SUCCESS:
      newState.loading = false
      newState.appointmentTypes = action.payload
      return newState

    case GET_APPOINTMENT_TYPES_FAILURE:
      newState.loading = false
      return newState

    default:
      return state
  }
};
