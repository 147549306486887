import {
  CLEAR_OPERATORY_SCHEDULES,
  GET_OPERATORY_SCHEDULES_REQUEST,
  GET_OPERATORY_SCHEDULES_SUCCESS,
  GET_OPERATORY_SCHEDULES_FAILURE,
} from '../actions/operatorySchedules'

export const defaultState = {
  loading: false,
  operatorySchedules: [],
}

export default (state = defaultState, action) => {
  const newState = Object.assign({}, state)

  switch (action.type) {

    case CLEAR_OPERATORY_SCHEDULES:
      newState.operatorySchedules  = []
      return newState

    case GET_OPERATORY_SCHEDULES_REQUEST:
      newState.loading = true
      return newState

    case GET_OPERATORY_SCHEDULES_SUCCESS:
      newState.loading = false
      newState.operatorySchedules = action.payload
      return newState

    case GET_OPERATORY_SCHEDULES_FAILURE:
      newState.loading = false
      return newState

    default:
      return state
  }
}
