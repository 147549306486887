import {
  SET_CURRENT_INSURANCE_POLICY,
  CLEAR_CURRENT_INSURANCE_POLICY,
  CLEAR_INSURANCE_POLICIES,
  GET_INSURANCE_POLICIES_REQUEST,
  GET_INSURANCE_POLICIES_SUCCESS,
  GET_INSURANCE_POLICIES_FAILURE,
} from '../actions/insurancePolicies'

export const defaultState = {
  loading: false,
  insurancePolicies: [],
  currentInsurancePolicy: null,
}

export default (state = defaultState, action) => {
  const newState = Object.assign({}, state)

  switch (action.type) {

  	case SET_CURRENT_INSURANCE_POLICY:
      newState.currentInsurancePolicy = action.payload
      return newState

    case CLEAR_CURRENT_INSURANCE_POLICY:
      newState.currentInsurancePolicy = null
      return newState

    case CLEAR_INSURANCE_POLICIES:
      newState.insurancePolicies = []
      return newState

    case GET_INSURANCE_POLICIES_REQUEST:
      newState.loading = true
      return newState

    case GET_INSURANCE_POLICIES_SUCCESS:
      newState.loading = false
      newState.insurancePolicies = action.payload
      return newState

    case GET_INSURANCE_POLICIES_FAILURE:
      newState.loading = false
      return newState

    default:
      return state
  }
};
