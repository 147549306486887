import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import { Formik, Field, Form } from 'formik'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import _map from 'lodash/map'

import { clearRepairIssues, getRepairIssues, updateRepairIssue } from '../actions/repairIssues'

import loadingAnimation from '../images/loading-animation.gif'
import refreshIcon from '../images/refresh-icon.png'

const statusOptions = [
  "open",
  "in-progress",
  "completed"
]

class RepairIssueCard extends Component {
  constructor(props) {
    super(props)

    this.updateRepairIssue = this.updateRepairIssue.bind(this)
  }
  componentDidMount() {

  }
  updateRepairIssue(values) {
    const { currentEmployee, repairIssue, clearRepairIssues, getRepairIssues, currentPage, updateRepairIssue } = this.props

    let formattedRepairIssue = {
      id: repairIssue.id,
      reporter_name: repairIssue.reporter_name,
      practice_id: repairIssue.practice_id,
      employee_id: currentEmployee.id,
      practice_display_name: repairIssue.practice_display_name,
      location: repairIssue.location,
      repair_issue_type: repairIssue.repair_issue_type,
      description: repairIssue.description,
      status: values.status,
    }

    updateRepairIssue(formattedRepairIssue, currentEmployee.id).then(() => {
      clearRepairIssues()
      getRepairIssues(currentEmployee.id, currentPage)
    })
  }
  render() {
    const { repairIssue, practice, employee } = this.props

    let status = repairIssue.status ? repairIssue.status : "Open"
    return (
      <div className="repair_issue_card__main_container" >
        <Row className="repair_issue_card__container">
          <Col xs={1}>
            <h3 className="repair_issue_card__id"><span className="repair_issue_card__title">ID:</span> { repairIssue.id }</h3>
          </Col>
          <Col xs={3}>
            <h3 className="repair_issue_card__practice"><span className="repair_issue_card__title">Practice:</span> { practice.display_name }</h3>
            <h3 className="repair_issue_card__name"><span className="repair_issue_card__title">Type:</span> { repairIssue.repair_issue_type }</h3>
            <h3 className="repair_issue_card__type"><span className="repair_issue_card__title">Reporter:</span> { repairIssue.reporter_name }</h3>
          </Col>
          <Col xs={5}>
            <h3 className="repair_issue_card__location"><span className="repair_issue_card__title">Location:</span> { repairIssue.location }</h3>
            <h3 className="repair_issue_card__description"><span className="repair_issue_card__title">Description:</span> { repairIssue.description }</h3>
          </Col>
          <Col xs={3}>
            <h3 className="repair_issue_card__date"><span className="repair_issue_card__title">Date:</span> { repairIssue.created_at ? moment(repairIssue.created_at).format("MM/DD/yyyy")  : ""}</h3>
            <Formik
              isInitialValid={true}
              initialValues={{ status: status  }}
              onSubmit={this.updateRepairIssue}
            >
              {({ handleSubmit, setFieldValue, values, isSubmitting, isValid, dirty }) => (
                   <Form>
                    <Field className="repair_issue_card__practice_form_select" as="select" name="status">
                    {
                      _map(statusOptions, (option, index) => {
                        return (
                          <option key={index} value={option}>{option.replace('-', ' ').replace(/(?:^|\s)\S/g, a => a.toUpperCase())}</option>
                        )
                      })
                    }
                    </Field>
                    <button className="repair_issue_card__form_practice_update_button" type="submit">
                      <img className="repair_issue_card__form_practice_update_button_icon" src={refreshIcon} />
                    </button>
                  </Form>
                )}
             </Formik>
             <h3 className="repair_issue_card__last_editor"><span className="repair_issue_card__title">Last Editor:</span> { employee && employee.first_name && employee.last_name ? employee.first_name+ " " + employee.last_name  : "None"}</h3>
          </Col>
        </Row>
      </div>
    )
  }
}

export default connect(
  state => ({
    currentEmployee: state.auth.currentEmployee,
    currentPage: state.repairIssues.currentPage,
  }),
  dispatch => bindActionCreators({
    clearRepairIssues, 
    getRepairIssues, 
    updateRepairIssue,
  }, dispatch),
)(RepairIssueCard)
