import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import { bindActionCreators } from 'redux'
import _map from 'lodash/map'
import moment from 'moment'
import classNames from 'classnames'

import MedicalSurveyQuestionCard from './MedicalSurveyQuestionCard'

class MedicalSurveyCollectionCard extends Component {
  render() {
    const { medicalSurveyCollection, medicalSurveys, medicalSurveyQuestions } = this.props

    const statusClasses = classNames({
        'medical_survey_collection_card__status_label': true,
        'medical_survey_collection_card__status_label_completed': medicalSurveyCollection.status === "complete",
        'medical_survey_collection_card__status_label_incomplete': medicalSurveyCollection.status !== "complete",
    })

    return (
      <div className="medical_survey_collection_card__container" >
        <Row>
          <Col xs={12}>
            <div className="medical_survey_collection_card__main_container" >
              <h4 className="medical_survey_collection_card__title"><span className="medical_survey_collection_card__info_label">Survey Name:</span> {medicalSurveyCollection.name}</h4>
              <p className="medical_survey_collection_card__status"><span className="medical_survey_collection_card__info_label">Status:</span> <span className={statusClasses}>{ medicalSurveyCollection.status }</span></p>
              <p className="medical_survey_collection_card__date_completed"><span className="medical_survey_collection_card__info_label">Date Completed:</span> { moment(medicalSurveyCollection.updated_at).format("M/DD/YYYY") }</p>
              {
              	_map(medicalSurveyQuestions, (medicalSurveyQuestion, index) => {
                    return (
                      <MedicalSurveyQuestionCard key={index} medicalSurveyQuestion={medicalSurveyQuestion} />
                    )
              	})
              }
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default connect(
  state => ({
  }),
  dispatch => bindActionCreators({
  }, dispatch),
)(MedicalSurveyCollectionCard)
