import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Container } from 'react-bootstrap'
import { bindActionCreators } from 'redux'
import _map from 'lodash/map'
import { withRouter } from 'react-router'
import classNames from 'classnames'
import { DateRangePicker } from 'react-dates'
import moment from 'moment'

import EmployeeProduction from './EmployeeProduction'
import PracticeProduction from './PracticeProduction'
import leftArrow from '../images/left-arrow.png'
import rightArrow from '../images/right-arrow.png'

import loadingAnimation from '../images/loading-animation.gif'

import { clearCurrentEmployeeProductionDateRange, setCurrentEmployeeProductionDateRange } from '../actions/stats'


class Production extends Component {
  constructor(props) {
  	super(props)
    
    this.state = {
      currentView: "employeeProduction",
      focusedInput: null,
    }

    this.onDatesChange = this.onDatesChange.bind(this)
    this.onFocusChange = this.onFocusChange.bind(this)
  }
  componentDidMount() {
    const { employeeProductionDateRange } = this.props

  }
  onDatesChange({startDate, endDate}) {
    const { clearCurrentEmployeeProductionDateRange, setCurrentEmployeeProductionDateRange } = this.props

    clearCurrentEmployeeProductionDateRange()
    setCurrentEmployeeProductionDateRange({startDate, endDate})
  }
  onFocusChange(focusedInput) {
    this.setState({focusedInput});
  }
  render() {
    const { currentEmployee, employeeProductionDateRange } = this.props

    const employeeTabClasses = classNames({
        'production__tab_link': true,
        'production__tab_link_selected': this.state.currentView === "employeeProduction",
    })

    const practiceTabClasses = classNames({
        'production__tab_link': true,
        'production__tab_link_selected': this.state.currentView === "practiceProduction",
    })

    var now = moment();
    let minDate = now.startOf('year').subtract(10, "years").startOf('day')
    let maxDate = now.endOf('day')

    return (
      <Container fluid>
        <Row>
          <Col xs={{ span: 12, offset: 0 }} sm={{ span: 12, offset: 0 }} md={{ span: 9, offset: 3 }} lg={{ span: 10, offset: 2 }} className="production__main_container">
          	<Row>
    	      		<Col xs={{ span: 2, offset: 0 }}>
    	          		<p className={employeeTabClasses} onClick={() => this.setState({currentView: "employeeProduction"})}>Employees</p>
    	      		</Col>
    	      		<Col xs={{ span: 2, offset: 0 }}>
    	          		<p className={practiceTabClasses} onClick={() => this.setState({currentView: "practiceProduction"})}>Practices</p>
    	      		</Col>
            	</Row>
              <Row>
                <Col>
                  <div className="production__date_picker">
                    <DateRangePicker
                      isOutsideRange={day => (moment().diff(day) < 0)}
                      startDate={employeeProductionDateRange.startDate} // momentPropTypes.momentObj or null,
                      startDateId={employeeProductionDateRange.startDate ? employeeProductionDateRange.startDate.format('YYYY-MM-DD-hh:mm') : moment().clone().startOf('month').format('YYYY-MM-DD-hh:mm')} // PropTypes.string.isRequired,
                      endDate={employeeProductionDateRange.endDate} // momentPropTypes.momentObj or null,
                      endDateId={employeeProductionDateRange.endDate ? employeeProductionDateRange.endDate.format('YYYY-MM-DD-hh:mm') : moment().clone().endOf('month').format('YYYY-MM-DD-hh:mm')} // PropTypes.string.isRequired,
                      onDatesChange={this.onDatesChange}
                      focusedInput={this.state.focusedInput}
                      onFocusChange={this.onFocusChange}
                      minDate={minDate}
                      maxDate={maxDate}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  {
                    this.state.currentView === "employeeProduction" ?
                    <EmployeeProduction />
                    :''
                  }
                  {
                    this.state.currentView === "practiceProduction" ?
                    <PracticeProduction />
                    :''
                  }
                </Col>
              </Row>
            </Col>
        </Row>
      </Container>
    )
  }
}

export default withRouter(connect(
  state => ({
    currentEmployee: state.auth.currentEmployee,
    employeeProductionDateRange: state.stats.employeeProductionDateRange,
  }),
  dispatch => bindActionCreators({
    clearCurrentEmployeeProductionDateRange,
    setCurrentEmployeeProductionDateRange,
  }, dispatch),
)(Production))
