import axiosClient from '../utils/axios'
import { authHeaders } from '../utils/localStorage'

export const CLEAR_CURRENT_EMPLOYEE_PRODUCTION_DATE_RANGE = 'CLEAR_CURRENT_EMPLOYEE_PRODUCTION_DATE_RANGE'
export function clearCurrentEmployeeProductionDateRange() {
  return {
    type: CLEAR_CURRENT_EMPLOYEE_PRODUCTION_DATE_RANGE,
  }
}

export const SET_CURRENT_EMPLOYEE_PRODUCTION_DATE_RANGE = 'SET_CURRENT_EMPLOYEE_PRODUCTION_DATE_RANGE'
export function setCurrentEmployeeProductionDateRange(dateRange) {
  return {
    type: SET_CURRENT_EMPLOYEE_PRODUCTION_DATE_RANGE,
    payload: dateRange,
  }
}

export const CLEAR_INITIAL_VALUES_FOR_EMPLOYEE_PRODUCTION_FORM = 'CLEAR_INITIAL_VALUES_FOR_EMPLOYEE_PRODUCTION_FORM'
export function clearInitialValuesForEmployeeProductionForm() {
  return {
    type: CLEAR_INITIAL_VALUES_FOR_EMPLOYEE_PRODUCTION_FORM,
  }
}

export const SET_INITIAL_VALUES_FOR_EMPLOYEE_PRODUCTION_FORM = 'SET_INITIAL_VALUES_FOR_EMPLOYEE_PRODUCTION_FORM'
export function setInitialValuesForEmployeeProductionForm(initialValues) {
  return {
    type: SET_INITIAL_VALUES_FOR_EMPLOYEE_PRODUCTION_FORM,
    payload: initialValues,
  }
}

export const CLEAR_CURRENT_EMPLOYEE_PRODUCTION_IDS = 'CLEAR_CURRENT_EMPLOYEE_PRODUCTION_IDS'
export function clearCurrentEmployeeProductionIds() {
  return {
    type: CLEAR_CURRENT_EMPLOYEE_PRODUCTION_IDS,
  }
}

export const SET_CURRENT_EMPLOYEE_PRODUCTION_IDS = 'SET_CURRENT_EMPLOYEE_PRODUCTION_IDS'
export function setCurrentEmployeeProductionIds(productionIds) {
  return {
    type: SET_CURRENT_EMPLOYEE_PRODUCTION_IDS,
    payload: productionIds,
  }
}

export const CLEAR_EMPLOYEE_PRODUCTION_STATS = 'CLEAR_EMPLOYEE_PRODUCTION_STATS'
export function clearEmployeeProductionStats() {
  return {
    type: CLEAR_EMPLOYEE_PRODUCTION_STATS,
  }
}

export const GET_EMPLOYEE_PRODUCTION_STATS_REQUEST = 'GET_EMPLOYEE_PRODUCTION_STATS_REQUEST'
export function getEmployeeProductionStatsRequest() {
  return {
    type: GET_EMPLOYEE_PRODUCTION_STATS_REQUEST,
  }
}

export const GET_EMPLOYEE_PRODUCTION_STATS_SUCCESS = 'GET_EMPLOYEE_PRODUCTION_STATS_SUCCESS'
export function getEmployeeProductionStatsSuccess(stats) {
  return {
    type: GET_EMPLOYEE_PRODUCTION_STATS_SUCCESS,
    payload: stats,
  }
}

export const GET_EMPLOYEE_PRODUCTION_STATS_FAILURE = 'GET_EMPLOYEE_PRODUCTION_STATS_FAILURE'
export function getEmployeeProductionStatsFailure(error) {
  return {
    type: GET_EMPLOYEE_PRODUCTION_STATS_FAILURE,
    payload: error,
  }
}

export function getEmployeeProductionStats(employeeId, employeeIds, startDate, endDate) {
  return (dispatch) => {
    dispatch(getEmployeeProductionStatsRequest())
    return axiosClient
      .get(`${process.env.REACT_APP_API_URL}/v1/employees/${employeeId}/stats/employee_production`, { params: { employee_ids: employeeIds, start_date: startDate, end_date: endDate },  headers: authHeaders() })
      .then((response) => {
        dispatch(getEmployeeProductionStatsSuccess(response.data))
      })
      .catch((error) => {
        dispatch(getEmployeeProductionStatsFailure(error))
      })
  }
}

export const CLEAR_INITIAL_VALUES_FOR_PRACTICE_PRODUCTION_FORM = 'CLEAR_INITIAL_VALUES_FOR_PRACTICE_PRODUCTION_FORM'
export function clearInitialValuesForPracticeProductionForm() {
  return {
    type: CLEAR_INITIAL_VALUES_FOR_PRACTICE_PRODUCTION_FORM,
  }
}

export const SET_INITIAL_VALUES_FOR_PRACTICE_PRODUCTION_FORM = 'SET_INITIAL_VALUES_FOR_PRACTICE_PRODUCTION_FORM'
export function setInitialValuesForPracticeProductionForm(initialValues) {
  return {
    type: SET_INITIAL_VALUES_FOR_PRACTICE_PRODUCTION_FORM,
    payload: initialValues,
  }
}

export const CLEAR_CURRENT_PRACTICE_PRODUCTION_IDS = 'CLEAR_CURRENT_PRACTICE_PRODUCTION_IDS'
export function clearCurrentPracticeProductionIds() {
  return {
    type: CLEAR_CURRENT_PRACTICE_PRODUCTION_IDS,
  }
}

export const SET_CURRENT_PRACTICE_PRODUCTION_IDS = 'SET_CURRENT_PRACTICE_PRODUCTION_IDS'
export function setCurrentPracticeProductionIds(productionIds) {
  return {
    type: SET_CURRENT_PRACTICE_PRODUCTION_IDS,
    payload: productionIds,
  }
}


export const CLEAR_PRACTICE_PRODUCTION_STATS = 'CLEAR_PRACTICE_PRODUCTION_STATS'
export function clearPracticeProductionStats() {
  return {
    type: CLEAR_PRACTICE_PRODUCTION_STATS,
  }
}

export const GET_PRACTICE_PRODUCTION_STATS_REQUEST = 'GET_PRACTICE_PRODUCTION_STATS_REQUEST'
export function getPracticeProductionStatsRequest() {
  return {
    type: GET_PRACTICE_PRODUCTION_STATS_REQUEST,
  }
}

export const GET_PRACTICE_PRODUCTION_STATS_SUCCESS = 'GET_PRACTICE_PRODUCTION_STATS_SUCCESS'
export function getPracticeProductionStatsSuccess(stats) {
  return {
    type: GET_PRACTICE_PRODUCTION_STATS_SUCCESS,
    payload: stats,
  }
}

export const GET_PRACTICE_PRODUCTION_STATS_FAILURE = 'GET_PRACTICE_PRODUCTION_STATS_FAILURE'
export function getPracticeProductionStatsFailure(error) {
  return {
    type: GET_PRACTICE_PRODUCTION_STATS_FAILURE,
    payload: error,
  }
}

export function getPracticeProductionStats(employeeId, practiceIds, startDate, endDate) {
  return (dispatch) => {
    dispatch(getPracticeProductionStatsRequest())
    return axiosClient
      .get(`${process.env.REACT_APP_API_URL}/v1/employees/${employeeId}/stats/practice_production`, { params: { practice_ids: practiceIds, start_date: startDate, end_date: endDate },  headers: authHeaders() })
      .then((response) => {
        dispatch(getPracticeProductionStatsSuccess(response.data))
      })
      .catch((error) => {
        dispatch(getPracticeProductionStatsFailure(error))
      })
  }
}